import SeeBoard from '../component/customizedPage/seeBoard.vue';
import payConsole from '../component/customizedPage/payConsole.vue';

export default {
  SeeBoard: {
    component: SeeBoard
  },
  HomePage: {
    component: payConsole,
    labelName: '用户中心'
  },
};
