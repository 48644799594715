var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { attrs: { id: "customTable" } }, [
    _c("thead", [
      _c(
        "tr",
        _vm._l(_vm.tabHead, function (item) {
          return _c("td", [
            _vm._v(
              "\n                " + _vm._s(item.title) + "\n            "
            ),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.tabBody, function (item, index) {
        return _c(
          "tr",
          _vm._l(_vm.tabHead, function (cur) {
            return _c(
              "td",
              [
                cur.render
                  ? _c("Cell", {
                      attrs: {
                        column: cur,
                        row: item,
                        index: index,
                        render: cur.render,
                      },
                    })
                  : _c("div", [_vm._v(_vm._s(item[cur.key]))]),
              ],
              1
            )
          }),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }