var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "smsPrice" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "contentTitle" }, [_vm._v("按量付费")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("Table", {
          staticStyle: { "margin-top": "10px", "font-size": "14px" },
          attrs: { columns: _vm.columns, data: _vm.data, border: "" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "tab" }),
      _vm._v(" "),
      _c("div", { staticClass: "visitText" }, [_vm._v("国内短信定价")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "billingInstructions" }, [
      _c("div", [
        _vm._v(
          "\n        根据短信的发送条数进行实时梯度计费。短信模板的类型不同，短信的单价也不同。实时计费，自动跨档，跨档后当月所有发送量按新阶梯价重新计算。\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }