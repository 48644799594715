<template>
  <div class="smsPrice">
    <div class="title">
      <div class="tab"></div>
      <div class="visitText">国内短信定价</div>
    </div>
    <div class="content">
      <!-- <div class="contentTitle">计费说明</div>
      <div class="billingInstructions">
        <div v-for="(item, index) in instructionsList">{{ item.spanText }}</div>
      </div> -->
      <div class="contentTitle">按量付费</div>
      <div class="billingInstructions">
        <div>
          根据短信的发送条数进行实时梯度计费。短信模板的类型不同，短信的单价也不同。实时计费，自动跨档，跨档后当月所有发送量按新阶梯价重新计算。
        </div>
      </div>
      <Table
        :columns="columns"
        :data="data"
        border
        style="margin-top: 10px; font-size: 14px"
      ></Table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      instructionsList: [
        { spanText: "购买套餐包后产生的短信发送量，按照实际用量抵扣额度。" },
        {
          spanText:
            "购买套餐包之前产生的短信发送量、超出套餐包额度的短信发送量均按照短信模板类型梯度计费",
        },
        {
          spanText:
            "短信套餐包中的短信发送额度适用于所有国内文本短信的短信模板。但个人用户仅可使用短信验证码和短信通知，不能使用推广短信及群发助手功能，建议您升级为企业用户体验相关功能。",
        },
        {
          spanText:
            "套餐包在账户欠费时不可使用，已使用套餐包剩余资源不支持退订。",
        },
      ],
      columns: [
        {
          title: "短信类型",
          key: "type",
          align: "center",
          className: "democolumn",
          render: (h, params) =>
            h(
              "div",
              {
                style: {
                  padding: "10px 0",
                },
              },
              [h("p", params.row.type1), h("p", params.row.type2)]
            ),
        },
        {
          title: "短信使用量阶梯（条/月）",
          align: "center",
          children: [
            {
              title: "量≤10万",
              key: "num1",
              align: "center",
            },
            {
              title: "10万＜量≤30万",
              key: "num2",
              align: "center",
            },
            {
              title: "30万＜量≤50万",
              key: "num3",
              align: "center",
            },
            {
              title: "50万＜量≤100万",
              key: "num4",
              align: "center",
            },
            {
              title: "100万＜量≤300万",
              key: "num5",
              align: "center",
            },
            {
              title: "量＞300万",
              key: "num6",
              align: "center",
            },
          ],
        },
      ],
      data: [
        {
          type1: "验证码短信",
          type2: "（元/条）",
          num1: "￥0.05",
          num2: "￥0.045",
          num3: "￥0.04",
          num4: "￥0.039",
          num5: "￥0.038",
          num6: "￥0.037",
        },
        {
          type1: "短信通知",
          type2: "（元/条）",
          num1: "￥0.05",
          num2: "￥0.045",
          num3: "￥0.04",
          num4: "￥0.039",
          num5: "￥0.038",
          num6: "￥0.037",
        },
        {
          type1: "推广短信",
          type2: "（元/条）",
          num1: "￥0.06",
          num2: "￥0.055",
          num3: "￥0.05",
          num4: "￥0.049",
          num5: "￥0.048",
          num6: "￥0.047",
        },
      ],
    };
  },
};
</script>
<style lang="less">
body {
  background-color: #eee !important;
}
.smsPrice .content .ark-table {
  font-size: 14px;
}
</style>

<style scoped lang="less">
.smsPrice {
  width: 65%;
  min-width: 800px;
  /*height: 681px;*/
  background: #ffffff;
  box-shadow: 6px 5px 16px 0 rgba(150, 195, 198, 0.13);
  border-radius: 3px;
  margin: 80px auto;
  padding-bottom: 80px;
}
.title {
  // overflow: hidden;
  display: flex;
  padding-bottom: 12px;
  padding-top: 30px;
}
.tab {
  width: 4px;
  height: 15px;
  background: #fd8368;
  margin: 0 0 0 20px;
}
.visitText {
  margin: 0 0 0 6px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 15px;
}
.content {
  margin: 0 20px 0 28px;
}
.contentTitle {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 25px;
  margin-top: 20px;
}
.billingInstructions {
  margin-top: 10px;
}
.billingInstructions div {
  font-size: 14px;
  font-weight: 300;
  color: #333333;
  line-height: 20px;
}
.democolumn {
  color: #96c3c6;
}
</style>
