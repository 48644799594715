var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "payCloud" } }, [
    _c("div", { staticClass: "payCloudBanner" }, [
      _c("div", { staticClass: "introduce" }, [
        _c(
          "div",
          { staticClass: "info-title" },
          [
            _c("p", { staticClass: "main-title" }, [_vm._v("支付服务")]),
            _vm._v(" "),
            _c("p", { staticClass: "sub-title" }, [
              _vm._v(
                "轻量化、低成本、无门槛、可扩展、面向多渠道的聚合支付产品"
              ),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "theme-main-btn-big",
                attrs: { to: "/login", tag: "span" },
              },
              [_vm._v("免费接入")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "superiorityContent" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "superiorityList unifiedList" },
        _vm._l(_vm.superiorityList, function (item) {
          return _c("li", { staticClass: "unifiedLi" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/home/exhibition-pay/" +
                  item.img +
                  ".png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "mainTitle" }, [
              _vm._v(_vm._s(item.mainTitle)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(item.subTitle)),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "payDifficulty" }, [
      _vm._m(1),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "unifiedList" },
        _vm._l(_vm.difficultyList, function (item) {
          return _c("li", { staticClass: "unifiedLi" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/home/exhibition-pay/" +
                  item.img +
                  ".png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "mainTitle" }, [
              _vm._v(_vm._s(item.mainTitle)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(item.subTitle)),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "paySolve" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "solveScene" }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "sceneList" },
          _vm._l(_vm.solveList, function (item, index) {
            return _c("li", [
              _c("div", { staticClass: "listNum" }, [
                _vm._v(_vm._s("0" + (index + 1))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "listProblem" }, [_vm._v(_vm._s(item))]),
            ])
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "payChannel" }, [
      _vm._m(4),
      _vm._v(" "),
      _c("p", { staticClass: "channelSubWord" }, [
        _vm._v(
          "一次接入所有主流支付渠道和分期渠道，99.99% 系统可用性，满足你丰富的交易场景需求,为你的用户提供完美支付体验。"
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "channelRotation" },
        [
          _c(
            "Carousel",
            {
              staticClass: "rotationContent",
              attrs: { autoplay: "", loop: "", arrow: "always" },
            },
            _vm._l(16 / 4, function (item, index) {
              return _c("CarouselItem", { key: index }, [
                _c(
                  "div",
                  { staticClass: "rotation" },
                  _vm._l(4, function (imgIndex) {
                    return _c("img", {
                      attrs: {
                        src: require("../../assets/home/exhibition-pay/channel/logo" +
                          ((item - 1) * 4 + imgIndex) +
                          ".png"),
                        alt: "",
                      },
                    })
                  }),
                  0
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedHeadline-blue" }, [
      _c("span", { staticClass: "midShadow left" }),
      _vm._v(" "),
      _c("span", { staticClass: "midText" }, [_vm._v("产品优势")]),
      _vm._v(" "),
      _c("span", { staticClass: "midShadow right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedCloudLine" }, [
      _c("div", { staticClass: "midShadow left" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "midText" }, [_vm._v("企业接入支付的难点")]),
      _vm._v(" "),
      _c("div", { staticClass: "midShadow right" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedCloudLine" }, [
      _c("div", { staticClass: "midShadow left" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "midText" }, [_vm._v("解决方案场景")]),
      _vm._v(" "),
      _c("div", { staticClass: "midShadow right" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgContent" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/home/exhibition-pay/solve.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedHeadline-blue" }, [
      _c("span", { staticClass: "midShadow left" }),
      _vm._v(" "),
      _c("span", { staticClass: "midText" }, [_vm._v("丰富的支付渠道")]),
      _vm._v(" "),
      _c("span", { staticClass: "midShadow right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }