<template>
    <div id="textMsg">
        <Tabs value="name1">
            <TabPane label="签名管理" name="name1" class="signManage">
                <div class="signTitle">
                    <div class="findSign">
                        <input type="text" placeholder="请输入签名名称搜索">
                        <button>查询</button>
                    </div>
                    <div class="addSign">
                        <button>添加签名</button>
                    </div>
                </div>
                <div class="signTabContent">
                    <CustomTable
                            :tab-head="tabHead"
                            :tab-body="tabBody"
                            class="tableSelf">
                    </CustomTable>
                    <!--<table class="tableSelf">-->
                    <!--    <thead>-->
                    <!--        <tr>-->
                    <!--            <td v-for="item in tabHead">-->
                    <!--                {{item.title}}-->
                    <!--            </td>-->
                    <!--            &lt;!&ndash;<td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;    <input type="checkbox"/>&ndash;&gt;-->
                    <!--            &lt;!&ndash;</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>签名名称</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>适用场景</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>审核状态</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>创建时间</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>操作</td>&ndash;&gt;-->
                    <!--        </tr>-->
                    <!--    </thead>-->
                    <!--    <tbody>-->
                    <!--        <tr v-for="(item, index) in tabBody">-->
                    <!--            <td v-for="(cur, i) in tabHead.length">-->
                    <!--                <Cell v-if="tabHead[i].render"-->
                    <!--                        :row="item"-->
                    <!--                        :index="index"-->
                    <!--                        :render="tabHead[i].render"></Cell>-->
                    <!--                <div v-else>{{ tabBody[index][tabHead[i].key] }}</div>-->
                    <!--            </td>-->
                    <!--            &lt;!&ndash;<td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;    <input type="checkbox"/>&ndash;&gt;-->
                    <!--            &lt;!&ndash;</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>伯俊软件</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>通用</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>通过</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td>2020-11-02 09:46:59</td>&ndash;&gt;-->
                    <!--            &lt;!&ndash;<td class="operation">&ndash;&gt;-->
                    <!--            &lt;!&ndash;    <span>群发</span>&ndash;&gt;-->
                    <!--            &lt;!&ndash;    <span>修改</span>&ndash;&gt;-->
                    <!--            &lt;!&ndash;    <span>删除</span>&ndash;&gt;-->
                    <!--            &lt;!&ndash;</td>&ndash;&gt;-->
                    <!--        </tr>-->
                    <!--    </tbody>-->
                    <!--</table>-->
                    <div class="tabFootPage">
                        <div class="delAll">
                            <input type="checkbox">
                            删除
                        </div>
                    </div>
                </div>
            </TabPane>
            <TabPane label="模板管理" name="name2" class="temManage">
                <div class="titleCondition">
                    <div class="oneLine">
                        <div>
                            <span>发送日期:</span>
                            <DatePicker size="large" type="date" placeholder="选择日期" style="width: 175px"></DatePicker>
                        </div>
                        <div>
                            <span>手机号:</span>
                            <label>
                                <Input v-model="queryPhone" size="large" style="width: 175px" />
                            </label>
                        </div>
                        <div>
                            <span>签名:</span>
                            <label>
                                <Select v-model="signChange" size="large" style="width:175px">
                                    <Option value="0">全部</Option>
                                    <Option value="ali">阿里</Option>
                                    <Option value="wx">微信</Option>
                                </Select>
                            </label>
                        </div>
                        <div>
                            <span>模板:</span>
                            <label>
                                <Select v-model="temSurname" size="large" style="width:175px">
                                    <Option value="0">全部</Option>
                                    <Option value="1">模板一</Option>
                                    <Option value="2">模板二</Option>
                                    <Option value="3">模板三</Option>
                                </Select>
                                <Select v-model="temName" size="large" style="width:175px">
                                    <Option value="0">全部</Option>
                                    <Option value="1">内容一</Option>
                                    <Option value="2">内容二</Option>
                                    <Option value="3">内容三</Option>
                                    <Option value="4">内容四</Option>
                                </Select>
                            </label>
                        </div>
                    </div>
                    <div class="twoLine">
                        <div>
                            <span>发送状态:</span>
                            <label>
                                <Select v-model="signChange" size="large" style="width:175px">
                                    <Option value="0">全部</Option>
                                    <Option value="ali">阿里</Option>
                                    <Option value="wx">微信</Option>
                                </Select>
                            </label>
                        </div>
                        <div>
                            <span>错误码:</span>
                            <label>
                                <Input v-model="queryPhone" size="large" style="width: 175px" />
                            </label>
                        </div>
                        <div class="btnGroup">
                            <span>查询</span>
                            <span>申请导出</span>
                            <span>查看导出记录</span>
                            <span>查看全部错误码详情</span>
                        </div>
                    </div>
                </div>
            </TabPane>
            <TabPane label="群发助手" name="name3">标签三的内容</TabPane>
        </Tabs>
    </div>
</template>

<script>
  // import Cell from '../customPlug/cell';
  // eslint-disable-next-line import/extensions
  import CustomTable from '../customPlug/customTable';

  export default {
    name: 'TextMsg',
    components: {
      CustomTable
    },
    data() {
      return {
        tabHead: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            title: '签名名称',
            key: 'sign'
          },
          {
            title: '适用场景',
            key: 'scene',
            render: (h, params) => h('div', {
              style: {
                display: 'flex',
                'align-items': 'center',
              },
              domProps: {
                innerHTML: params.row.test ? params.row.other : params.row.scene
              }
            })
          },
          {
            title: '审核状态',
            key: 'state',
            render: (h, params) => h('div', {
              style: {
                display: 'flex',
                'align-items': 'center',
              },
              domProps: {
                // eslint-disable-next-line no-nested-ternary
                innerHTML: params.row.state === 1
                  ? '<span style="width: 16px;height: 16px;border-radius: 50%;background: #00ccff"></span> 等待中'
                  // eslint-disable-next-line no-nested-ternary
                  : params.row.state === 2
                    ? '<span style="width: 16px;height: 16px;border-radius: 50%;background: green"></span> 通过'
                    // eslint-disable-next-line no-nested-ternary
                    : params.row.state === 3
                      ? '<span style="width: 16px;height: 16px;border-radius: 50%;background: yellow"></span> 警告'
                      // eslint-disable-next-line no-nested-ternary
                      : params.row.state === 4
                        ? '<span style="width: 16px;height: 16px;border-radius: 50%;background: red"></span> 报警' : ''
              }
            })
          },
          {
            title: '创建时间',
            key: 'creatTime',
            render: (h, params) => h('div', {
              style: {
                display: 'flex',
                'align-items': 'center',
              },
              domProps: {
                innerHTML: (function () {
                  let now = new Date(params.row[params.column.key]);
                  let y = now.getFullYear();
                  let m = now.getMonth() + 1;
                  let d = now.getDate();
                  return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d} ${now.toTimeString().substr(0, 8)}`;
                }())
              }
            })
          },
          {
            title: '操作',
            key: 'operate',
            // render: (h, params) => h('div', {
            //   domProps: {
            //     innerHTML: this.operateArr(params)
            //   }
            // }),
            render: (h, params) => h('div', this.operateArr(h, params))
          },
        ],
        tabBody: [
          {
            sign: '上',
            scene: '支',
            state: 1,
            creatTime: 1604369825401,
            operate: [1, 2, 3]
          },
          {
            sign: '海',
            scene: '付',
            state: 2,
            creatTime: 1604369825401,
            operate: [1, 2, 3],
            test: true,
            other: '其他的',
          },
          {
            sign: '伯',
            scene: '产',
            state: 3,
            creatTime: 1604369825401,
            operate: [1, 2, 3]
          },
          {
            sign: '俊',
            scene: '品',
            state: 4,
            creatTime: 1604369825401,
            operate: [1, 2, 3]
          },
        ],
        queryPhone: '',
        signChange: 'ali',
        temSurname: 0,
        temName: 0,
      };
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
      operateArr(h, params) {
        let word = ['增', '删', '改', '查'];
        let exportArr = [];
        let forEachArr = params.row[params.column.key];
        for (let i = 0; i < forEachArr.length; i++) {
          exportArr.push(
            h('span', {
              style: {
                color: '#70d8f5',
                'margin-right': '10px',
                cursor: 'pointer'
              },
              domProps: {
                innerHTML: word[forEachArr[i] - 1]
              },
              on: {
                click() {
                  console.log(i);
                }
              }
            })
          );
        }
        return exportArr;
      }
    },
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    #textMsg {
        padding: 16px;
        .signManage {
            .signTitle {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                .findSign {
                    >input {
                        width: 200px;
                        height: 34px;
                        border: 1px solid #DBDCDD;
                        margin-right: 20px;
                        text-indent: 4px;
                    }
                    >button {
                        height: 36px;
                        line-height: 36px;
                        padding: 0 15px;
                        border: none;
                        background: #55BFDB;
                        color: #fff;
                    }
                }
                .addSign {
                    >button {
                        height: 36px;
                        line-height: 36px;
                        padding: 0 15px;
                        border: none;
                        background: #55BFDB;
                        color: #fff;
                    }
                }
            }
            .signTabContent {
                width: 100%;
                font-size: 13px;
                .tableSelf {
                    width: 100%;
                    tr {
                        height: 48px;
                        line-height: 48px;
                        border-bottom: 1px solid #ECEDED;
                    }
                    td {
                        padding: 0 16px;
                        input {
                            cursor: pointer;
                        }
                    }

                    thead {
                        background: #F5F5F6;
                    }
                    tbody {
                        background: #FFFFFF;
                        .operation {
                            span {
                                margin-right: 8px;
                                cursor: pointer;
                                color: #62C4DE;
                            }
                        }
                    }
                }
                .tabFootPage {
                    width: 100%;
                    height: 64px;
                    line-height: 64px;
                    background: #F5F6FA;
                    .delAll {
                        padding: 0 16px;
                        input {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
        .temManage {
            .titleCondition {
                .oneLine {
                    display: flex;

                    margin-bottom: 16px;
                    >div {
                        margin-right: 16px;
                        >span {
                            color: #999
                        }
                    }
                }
                .twoLine {
                    display: flex;
                    >div {
                        margin-right: 16px;
                        >span {
                            color: #999
                        }
                    }
                    .btnGroup {
                        span {
                            display: inline-block;
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }
    }
</style>
