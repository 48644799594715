<template>
    <div id="payCloud">
        <div class="payCloudBanner">
            <div class="introduce">
                <div class="info-title">
                    <p class="main-title">支付服务</p>
                    <p class="sub-title">轻量化、低成本、无门槛、可扩展、面向多渠道的聚合支付产品</p>
                    <!--<router-link class="theme-main-btn-big" to="/cloudContent/payCloudInfo" tag="span">了解详情</router-link>-->
                    <!--<span class="theme-main-btn">免费接入</span>-->
                    <router-link class="theme-main-btn-big" to="/login" tag="span">免费接入</router-link>
                </div>
            </div>
        </div>
        <div class="superiorityContent">
            <div class="unifiedHeadline-blue">
                <span class="midShadow left"></span>
                <span class="midText">产品优势</span>
                <span class="midShadow right"></span>
            </div>
            <ul class="superiorityList unifiedList">
                <li v-for="item in superiorityList" class="unifiedLi">
                    <img :src="require('../../assets/home/exhibition-pay/' + item.img + '.png')" alt="">
                    <p class="mainTitle">{{ item.mainTitle }}</p>
                    <div class="subTitle">{{ item.subTitle }}</div>
                </li>
            </ul>
        </div>
        <div class="payDifficulty">
            <div class="unifiedCloudLine">
                <div class="midShadow left">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="midText">企业接入支付的难点</div>
                <div class="midShadow right">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <ul class="unifiedList">
                <li v-for="item in difficultyList" class="unifiedLi">
                    <img :src="require('../../assets/home/exhibition-pay/'+ item.img +'.png')" alt="">
                    <p class="mainTitle">{{ item.mainTitle }}</p>
                    <p class="subTitle">{{ item.subTitle }}</p>
                </li>
            </ul>
        </div>
        <div class="paySolve">
            <div class="unifiedCloudLine">
                <div class="midShadow left">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="midText">解决方案场景</div>
                <div class="midShadow right">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="solveScene">
                <div class="imgContent">
                    <img src="../../assets/home/exhibition-pay/solve.png" alt="">
                </div>
                <ul class="sceneList">
                    <li v-for="(item, index) in solveList">
                        <div class="listNum">{{ '0' + (index +1) }}</div>
                        <div class="listProblem">{{ item }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="payChannel">
            <div class="unifiedHeadline-blue">
                <span class="midShadow left"></span>
                <span class="midText">丰富的支付渠道</span>
                <span class="midShadow right"></span>
            </div>
            <p class="channelSubWord">一次接入所有主流支付渠道和分期渠道，99.99% 系统可用性，满足你丰富的交易场景需求,为你的用户提供完美支付体验。</p>
            <div class="channelRotation">
                <Carousel autoplay loop arrow="always" class="rotationContent">
                    <CarouselItem v-for="(item, index) in 16/4" :key="index">
                        <div class="rotation">
                            <img v-for="imgIndex in 4" :src="require('../../assets/home/exhibition-pay/channel/logo' + ((item - 1) * 4 + imgIndex) + '.png')" alt="">
                        </div>
                    </CarouselItem>
                </Carousel>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'PayCloud',
    data() {
      return {
        superiorityList: [
          {
            img: 'paySuperiorityOne',
            mainTitle: '强大的技术能力',
            subTitle: '一次接入等于全部主流支付渠道，方便快捷，无需自己维护繁琐的聚合支付，百家客户认证，支付平台支撑着伯俊二百多家客户的支付业务',
          },
          {
            img: 'paySuperiorityTwo',
            mainTitle: '专业的全流程服务',
            subTitle: '支持个性化定制，支付团队全程跟进，并提供7*10小时服务。',
          },
          {
            img: 'paySuperiorityThree',
            mainTitle: '私有化部署方案',
            subTitle: '除Saas部署模式外，伯俊支付也提供支付私有化部署方案，以更好的满足客户对数据安全，监管合规、内部业务系统集成的需求。',
          },
        ],
        difficultyList: [
          {
            img: 'difficultyListOne',
            mainTitle: '支付研发成本高',
            subTitle: '由于市面上支付渠道众多，并需要不断升级维护，导致支付研发成本升高，不断增加企业研发成本。',
          },
          {
            img: 'difficultyListTwo',
            mainTitle: '支付业务设计难',
            subTitle: '支付系统的严谨性，安全性，企业需要具备更高效的支付交易系统的设计能力。',
          },
          {
            img: 'difficultyListThree',
            mainTitle: '支付申请流程复杂',
            subTitle: '不同的支付渠道申请流程不一，导致支付申请流程复杂，增加企业申请成本。',
          },
        ],
        solveList: [
          '全方位沉淀会员交易数据 可通过余额实现线上充值，转账，消费，提现'
            + '提供会员画像、会员忠诚度、流失风险监测、会员客群管理、动态补贴、数据监测等服务，高效管理会员生命周期',
          
          '营销管理 联合支付渠道展开满减、充返等节日营销活动；利用支付即会员等方式采集消费者数据并在线上营销付款后，可以主动/定期给会员推'
            + '送优惠券，提高会员留存率； 使用微信公众号/小程序支付后，企业可以发放红包/会员卡/优惠券功能，刺激会员二次消费，提升会员粘性',
          
          '商户管理 管理多层级业务关系，商户之间可共享会员数据，打破营销壁垒； 可独立管理各个商户交易数据，也可以汇总管理； 商户和平台之间可灵活分润、结算',
        ]
      };
    },
    components: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {},
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    /*@import "../../../static/theme/theme";*/
    #payCloud {
        .payCloudBanner {
            width: 100%;
            height: 450px;
            background: #fff;
            .introduce {
                max-width: 1100px;
                height: 100%;
                margin: auto;
                background: url("../../assets/home/exhibition-pay/banner1.png") center top no-repeat;
                background-size: cover;
                display: flex;
                align-items: center;
                >.info-title {
                    text-align: left;
                }
            }
        }
        .superiorityContent {
            padding: 80px 0;
            background: #F0F2F5;
            .superiorityList {
                justify-content: center;
                >li {
                    width: 275px;
                    /*height: 225px;*/
                    padding: 16px;
                    background: #F7F9FB;
                    border: 1px solid transparent;
                    >img {
                        width: 60px;
                        height: 60px;
                    }
                }
                >li:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                    transition: transform 0.3s;
                    background: #fff;
                    border-image: linear-gradient(180deg, rgba(206, 217, 255, 1), rgba(159, 178, 255, 1)) 2 2;
                }
            }
        }
        .payDifficulty {
            padding: 80px 0;
            background: #F6F8FB;
            .unifiedCloudLine {
                margin-bottom: 35px;
            }
            .unifiedList {
                >li.unifiedLi {
                    width: 320px;
                    padding: 0 50px;
                    >img {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
        .paySolve {
            padding: 80px 0;
            background: #fff;
            .unifiedCloudLine {
                margin-bottom: 45px;
            }
            .solveScene {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .imgContent {
                    >img {
                        width: 330px;
                        height: 315px;
                    }
                }
                .sceneList {
                    width: 520px;
                    >li {
                        height: 100px;
                        padding: 20px 60px;
                        margin-bottom: 8px;
                        box-shadow: 6px 3px 6px 0 rgba(150, 195, 198, 0.24);
                        border-radius: 10px;
                        position: relative;
                        .listNum {
                            font-size: 66px;
                            color: rgba(253, 100, 66, 0.2);
                            position: absolute;
                            left: 20px;
                            top: -10px;
                        }
                        .listProblem {
                            font-size: 12px;
                            color: #686A6A;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
        .payChannel {
            padding: 80px 0 95px;
            background: #F0F2F5;
            .channelSubWord {
                font-size: 14px;
                color: #1B1B1B;
                text-align: center;
                margin-top: -20px;
                margin-bottom: 30px;
            }
            .channelRotation {
                width: 100%;
                background: #F6F7FB;
                .rotationContent {
                    max-width: 1100px;
                    margin: auto;
                    .rotation {
                        padding: 45px 0;
                        display: flex;
                        justify-content: space-evenly;
                        img {
                            width: 120px;
                            height: 120px;
                        }
                    }
                    .ark-carousel-dots-inside {
                        bottom: -25px;
                    }
                }
            }

        }
    }
</style>
