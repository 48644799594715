import R3 from '@syman/burgeon-r3';
import Navigator from '../component/contentFlie/Navigator.vue';
import HeadBar from '../component/contentFlie/HeadBar.vue';

export default {
  direction: 'column',
  style: {
    overflow: 'auto'
  },
  items: [
    {
      component: HeadBar,
      name: 'HeadBar',
    },
    {
      weight: 1,
      style: {
        overflow: 'hidden'
      },
      layout: {
        direction: 'row',
        items: [
          {
            component: Navigator,
            name: 'Navigator',
          },
          {
            weight: 1,
            component: R3.components.ContentDisplayArea,
            style: {
              backgroundColor: '#fff',
              overflow: 'hidden',
              margin: '10px'
            },
          },
        ]
      }
    },

  ],
};
