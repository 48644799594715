<template>
  <div class="sdkContent">
    <div class="downloadContent">
      <div
        v-for="(item, index) in sdkDownloadData"
        :key="index"
        class="sdkItemContent"
      >
        <h2 class="sdkItemTitle">
          {{ item.title }}
        </h2>
        <div class="downloadItemContent">
          <div v-for="(tem, idx) in item.downloadInfo"
               :key="idx"
               class="downloadItem">
            <a
                    :href="tem.url"
                    @mouseenter="downloadItemEnter"
                    @mouseleave="downloadItemLeave"
            >
              <div class="sdkName">{{ tem.name }}</div>
              <div class="sdkDesc">{{ tem.desc }}</div>
              <i
                      v-show="downloadIcon"
                      class="iconfont iconmd-download downloadFile"
              />
            </a>
            <a target="_blank" :href="tem.githubUrl" class="github">
              <i
                      class="iconfont iconlogo-github"
              />
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        downloadIcon: false,
        sdkDownloadData: [
          {
            title: 'Client SDK',
            downloadInfo: [
              {
                name: 'raincloud-pay-1.4.0',
                url: 'https://syman-pay.oss-cn-shanghai.aliyuncs.com/PAY-SDK/raincloud-pay-1.4.0-SNAPSHOT.jar',
                desc: '支付接入SDK',
                githubUrl: 'https://github.com/hahajingnt/pi-pay.git'
              },
            ]
          },
        ]
      };
    },
    name: 'SdkDownload',
    components: {},
    methods: {
      downloadItemEnter() {
        this.downloadIcon = true;
      }, // 鼠标进入
      downloadItemLeave() {
        this.downloadIcon = false;
      }, // 鼠标离开
    },
    watch: {},
    computed: {}
  };
</script>

<style lang="less">
    .sdkContent {
        display: flex;
        justify-content: center;
        height: 100%;
        overflow-y: auto;
        .downloadContent {
            width: 65%;
            .sdkItemContent {
                margin: 30px 0;
                padding: 30px 0 0 30px;
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #d8d8d8;
                .sdkItemTitle {
                    padding-bottom: 15px;
                }
                .downloadItemContent {
                    display: flex;
                    flex-wrap: wrap;
                    .downloadItem {
                        /*width: calc((100% - 120px) / 4);*/
                        min-width: 200px;
                        min-height: 120px;
                        margin-right: 30px;
                        margin-bottom: 30px;
                        background-color: #fafafa;
                        padding: 30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        position: relative;
                        .downloadFile {
                            position: absolute;
                            right: 32px;
                            bottom: 56px;
                            font-size: 16px;
                        }
                        .sdkName {
                            color: #111419;
                            font-size: 20px;
                        }
                        .sdkDesc {
                            color: #111419;
                        }
                        .github {
                            width: 40px;
                            color: #111419;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            i {
                                font-size: 20px;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 750px) {
            .downloadContent {
                width: 80%;
            }
        }
    }
</style>
