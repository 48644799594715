<template>
    <div id="invCloud">
        <div class="invCloudBanner">
            <div class="introduce">
                <div class="info-title">
                    <p class="main-title">发票服务</p>
                    <p class="sub-title">网页开票、手机开票、批量开票、扫码开票、审核开票等适配多</p>
                    <p class="sub-title">场景的开票方式，提供接口，支持企业打通自有业务系统进行开票及发票管理</p>
                    <!--<router-link class="theme-main-btn-big" to="/cloudContent/payCloudInfo" tag="span">了解详情</router-link>-->
                    <!--<span class="theme-main-btn">免费接入</span>-->
                    <router-link class="theme-main-btn-big" to="/login" tag="span">免费接入</router-link>
                </div>
            </div>
        </div>
        <div class="superiorityContent">
            <div class="unifiedHeadline-blue">
                <span class="midShadow left"></span>
                <span class="midText">产品优势</span>
                <span class="midShadow right"></span>
            </div>
            <ul class="superiorityList unifiedList">
                <li v-for="item in superiorityList" class="unifiedLi">
                    <img :src="require('../../assets/home/exhibition-inv/' + item.img + '.png')" alt="">
                    <div class="normalTitle">{{ item.normalTitle }}</div>
                </li>
            </ul>
        </div>
        <div class="invDifficulty">
            <div class="unifiedCloudLine">
                <div class="midShadow left">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="midText">企业接入支付的难点</div>
                <div class="midShadow right">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <ul class="unifiedList">
                <li v-for="item in difficultyList" class="unifiedLi">
                    <img :src="require('../../assets/home/exhibition-inv/'+ item.img +'.png')" alt="">
                    <p class="mainTitle">{{ item.mainTitle }}</p>
                    <p class="subTitle">{{ item.subTitle }}</p>
                </li>
            </ul>
        </div>
        <div class="invSolve">
            <div class="unifiedCloudLine">
                <div class="midShadow left">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="midText">行业解决方案</div>
                <div class="midShadow right">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <ul class="superiorityList unifiedList">
                <li v-for="item in industrySolutionsList" class="unifiedLi">
                    <img :src="require('../../assets/home/exhibition-inv/' + item.img + '.png')" alt="">
                    <p class="mainTitle">{{ item.mainTitle }}</p>
                    <div class="subTitle">{{ item.subTitle }}</div>
                </li>
            </ul>
        </div>
        <div class="channelContent">
            <div class="unifiedHeadline-blue">
                <span class="midShadow left"></span>
                <span class="midText">发票渠道</span>
                <span class="midShadow right"></span>
            </div>
            <ul class="invoiceList unifiedList">
                <li v-for="item in invoiceList" class="unifiedLi">
                    <img :src="require('../../assets/home/exhibition-inv/' + item.img + '.png')" alt="">
                    <div class="normalTitle">{{ item.normalTitle }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'InvCloud',
    data() {
      return {
        superiorityList: [
          {
            img: 'invSuperiorityOne',
            normalTitle: '一站式解决电子专票软硬件部署与升级',
          },
          {
            img: 'invSuperiorityTwo',
            normalTitle: '一次性实现发票系统与业务、财务、税务系统的对接',
          },
          {
            img: 'invSuperiorityThree',
            normalTitle: '一体化构建票、单、证全面数字化的能力',
          },
        ],
        difficultyList: [
          {
            img: 'difficultyListOne',
            mainTitle: '全场景自动化发票开具服务',
            subTitle: '强大的设备及接口服务，支持各类设备和业务系统',
          },
          {
            img: 'difficultyListTwo',
            mainTitle: '发票数据采集、查验- -体化',
            subTitle: '多种自动化采集方式+自动去重',
          },
          {
            img: 'difficultyListThree',
            mainTitle: '全票种管理',
            subTitle: '专、普、电、卷、机动车、二手车',
          },
        ],
        industrySolutionsList: [
          {
            img: 'invIndustryOne',
            mainTitle: '鞋服行业 ',
            subTitle: '聚焦服务、场景、营销、供应链、反馈机制等进行变革、创新。',
          },
          {
            img: 'invIndustryTwo',
            mainTitle: '饰品行业',
            subTitle: '集中式部署模式满足饰品业务系统对接需求',
          },
          {
            img: 'invIndustryThree',
            mainTitle: '餐饮行业',
            subTitle: '全面集成POS消费者自助开票、多渠道获票',
          },
          {
            img: 'invIndustryFour',
            mainTitle: '物流行业',
            subTitle: '税务集中管控关联交易及供应链协同',
          },
        ],
        invoiceList: [
          {
            img: 'cloud',
          },
          {
            img: 'spaceFlight',
          },
        ],
      };
    },
    components: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {},
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    /*@import "../../../static/theme/theme";*/
    #invCloud {
        .invCloudBanner {
            width: 100%;
            height: 450px;
            background: #fff;
            .introduce {
                max-width: 1100px;
                height: 100%;
                margin: auto;
                background: url("../../assets/home/exhibition-inv/banner1.png") center top no-repeat;
                background-size: cover;
                display: flex;
                align-items: center;
                >.info-title {
                    text-align: left;
                }
            }
        }
        .superiorityContent {
            padding: 80px 0;
            background: #F0F2F5;
            .superiorityList {
                >li {
                    height: 225px;
                    padding: 16px 60px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .invDifficulty {
            padding: 80px 0;
            background: #F6F8FB;
            .unifiedCloudLine {
                margin-bottom: 35px;
            }
            .unifiedList {
                >li.unifiedLi {
                    width: 320px;
                    padding: 0 50px;
                    >img {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
        .invSolve {
            padding: 80px 0;
            background: #F0F2F5;
            .unifiedCloudLine {
                margin-bottom: 45px;
            }
            .superiorityList {
                >li {
                    padding: 16px 40px;
                }
            }
        }
        .channelContent {
            padding: 80px 0;
            background: #F0F2F5;
            .invoiceList {
                img {
                    width: 383px;
                    height: 182px;
                    margin: 0 50px;
                }
            }
        }

        .superiorityList {
            justify-content: center;
            >li {
                width: 275px;
                background: #F7F9FB;
                border: 1px solid transparent;
                >img {
                    width: 60px;
                    height: 60px;
                }
            }
            >li:hover {
                cursor: pointer;
                transform: scale(1.1);
                transition: transform 0.3s;
                background: #fff;
                border-image: linear-gradient(180deg, rgba(206, 217, 255, 1), rgba(159, 178, 255, 1)) 2 2;
            }
        }
    }
</style>
