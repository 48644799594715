<template>
  <div class="welcome">

  </div>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
    methods: {
    },
    created() {

    }
  };
</script>
<style scoped lang="less">
    .welcome {
        width: 100%;
        height: 100%;
        background: url("../assets/image/welcomeBg.png") #F1F1F1 no-repeat 95% 95%;
        background-size: 200px;
    }
</style>
