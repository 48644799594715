var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "leftNavigator" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("VerticalMenu", { staticClass: "verticalMenu" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navImgLogo" }, [
      _c("img", {
        attrs: { src: require("../../assets/image/leftDropLogo.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("通用服务")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }