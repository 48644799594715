var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sdkContent" }, [
    _c(
      "div",
      { staticClass: "downloadContent" },
      _vm._l(_vm.sdkDownloadData, function (item, index) {
        return _c("div", { key: index, staticClass: "sdkItemContent" }, [
          _c("h2", { staticClass: "sdkItemTitle" }, [
            _vm._v("\n        " + _vm._s(item.title) + "\n      "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "downloadItemContent" },
            _vm._l(item.downloadInfo, function (tem, idx) {
              return _c("div", { key: idx, staticClass: "downloadItem" }, [
                _c(
                  "a",
                  {
                    attrs: { href: tem.url },
                    on: {
                      mouseenter: _vm.downloadItemEnter,
                      mouseleave: _vm.downloadItemLeave,
                    },
                  },
                  [
                    _c("div", { staticClass: "sdkName" }, [
                      _vm._v(_vm._s(tem.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "sdkDesc" }, [
                      _vm._v(_vm._s(tem.desc)),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.downloadIcon,
                          expression: "downloadIcon",
                        },
                      ],
                      staticClass: "iconfont iconmd-download downloadFile",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "github",
                    attrs: { target: "_blank", href: tem.githubUrl },
                  },
                  [
                    _c("i", { staticClass: "iconfont iconlogo-github" }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Github")]),
                  ]
                ),
              ])
            }),
            0
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }