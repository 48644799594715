<template>
    <div id="allowAgreement">
        <div class="allow-content">
            <div class="cont-line">
                <div>产品名称：</div>
                <div class="opening">短信服务</div>
            </div>
            <div class="cont-line">
                <div>开通说明：</div>
                <div class="opening">
                    <p>产品免费开通，按量实际计费。<span class="lookInfo">查看计费说明</span></p>
                    <p class="check-open">
                        <label>
                            <input type="checkbox" v-model="agree">
                            <span :class="agree ? 'agree' : ''">我已阅读并同意</span>
                        </label>
                    </p>
                    <p class="btn-group">
                        <span @click="openService" :class="agree ? 'theme-main-btn' : 'disableAgree'">立即开通</span>
                        <!--<Button type="primary" :disabled="agree ? false : 'disabled'" @click="openService">成为商户</Button>-->
                        <!--<Button type="primary" :disabled="agree ? false : 'disabled'" @click="openService">成为服务商</Button>-->
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import R3 from '@syman/burgeon-r3';

  const { network } = R3;
  
  export default {
    name: 'AllowAgreement',
    data() {
      return {
        agree: false,
      };
    },
    components: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
      openService() {
        if (this.agree) {
          this.$Modal.fcWarning({
            title: '谨慎操作！',
            content: '商户和服务商身份只能存在一个，请再次确认后，注册！',
            mask: true,
            onOk: () => {
              console.log(1231);
            }
          });
        }
      }
    },
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    #allowAgreement {
        width: 100%;
        height: 100%;
        padding: 16px;
        font-size: 14px;
        .allow-content {
            width: 100%;
            border: 1px solid #E7EBEF;
            padding: 35px 80px;
            .cont-line {
                display: flex;
                padding: 5px 0;
                margin-bottom: 15px;
                >div:first-child {
                    width: 80px;
                    color: #8F9195;
                }
                >div.opening {
                    color: #454A4E;
                    >p {
                        margin-bottom: 20px;
                        .lookInfo {
                            color: #61C4DE;
                        }
                    }
                    p.check-open {
                        color: #888A8E;
                        cursor: pointer;
                        >label {
                            cursor: pointer;
                            input {
                                vertical-align: text-top;
                            }
                            span {
                                vertical-align: top;
                            }
                            span.agree {
                                color: #454A4E;
                            }
                        }
                    }
                    .disableAgree {
                        font-size: 14px;
                        padding: 6px 20px;
                        border-radius: 4px;
                        color: #E4E5E6;
                        background: #F5F5F6;
                        cursor: pointer;
                        display: inline-block;
                    }
                    >p.btn-group {
                        >span {
                            margin-right: 20px;
                        }
                        >button {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
</style>
