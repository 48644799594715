<template>
    <Menu v-if="menList.length > 0" accordion style="width: 260px;overflow: auto" :active-name="activeName"
          :open-names="openNames" @on-select="sideMenuSelect">
        <template v-for="(item, index) in menList">
            <Submenu v-if="item.children" :key="index" :name="item.name">
                <template slot="title">{{ item.title }}</template>
                <template v-for="(item1, index1) in item.children">
                    <Submenu v-if="item1.children" :key="index1" :name="item1.name">
                        <template slot="title">{{ item1.title }}</template>
                        <template v-for="(item2, index2) in item1.children">
                            <Submenu v-if="item2.children" :key="index2" :name="item2.name">
                                <template slot="title">{{ item2.title }}</template>
                                <MenuItem v-for="(item3, index3) in item2.children" :key="index3" :name="item3.name">{{ item3.title }}</MenuItem>
                            </Submenu>
                            <MenuItem v-else :key="index2" :name="item2.name">{{ item2.title }}</MenuItem>
                        </template>
                    </Submenu>
                    <MenuItem v-else :key="index1" :name="item1.name">{{ item1.title }}</MenuItem>
                </template>
            </Submenu>
            <MenuItem v-else :key="index" :name="item.name">{{ item.title }}</MenuItem>
        </template>
    </Menu>
</template>

<script>
  export default {
    props: {
      menList: {
        type: Array,
        default: () => [],
      },
      activeName: {
        type: String,
        default: () => ''
      },
      openNames: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
      };
    },
    components: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
      sideMenuSelect(name) {
        this.$emit('sideMenuSelect', name);
      }
    },
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style scoped lang="less" type="text/less">

</style>
