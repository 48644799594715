<template>
    <div id="footers" style="background: #F6F8FB;">
        <slot name="imgSlot">
            <div class="company-partner">
                <div class="title">
                    <p class="main-title">企业信赖的商业合作伙伴</p>
                    <p class="sub-title">5年积累，39次版本迭代升级，服务于200多个企业客户，处理超过5亿笔交易订单</p>
                </div>
                <div class="partnerImg-Cont">
                    <img src="../../assets/home/exhibition/partner.png" alt="">
                </div>
            </div>
        </slot>
        <div class="footer-container">
            <div class="footer-body">
                <ul class="serverList">
                    <li v-for="(item, index) in bottomFooterList" class="serverTip">
                        <p class="server-title">{{ item.title }}</p>
                        <ul class="link-list">
                            <li v-for="(child, childIndex) in item.list" @click="useClick(child)" class="linkTip">{{ child.name }}</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Footers',
    data() {
      return {
        bottomFooterList: [
          {
            title: '产品',
            list: [
              {
                name: '支付服务',
                clickMethod: () => {
                  this.$router.push({ path: '/cloudContent/payCloud' });
                }
              },
              {
                name: '短信服务',
                clickMethod: () => {
                  this.$router.push({ path: '/cloudContent/smsCloud' });
                }
              },
              {
                name: '发票服务',
                clickMethod: () => {
                  this.$router.push({ path: '/cloudContent/invCloud' });
                }
              }
            ]
          },
          {
            title: '公司信息',
            list: [
              // {
              //   name: '希曼软件',
              //   clickMethod: () => {
              //     window.open('http://www.burgeon.cn/');
              //   }
              // },
              {
                name: '©南京希曼软件技术有限公司'
              },
              {
                name: '备案号：苏ICP备14038201号-5',
                clickMethod: () => {
                  window.open('http://beian.miit.gov.cn');
                }
              }
            ]
          },
          {
            title: '联系我们',
            list: [
              {
                name: '地址：江苏省南京市雨花台区安德门大街58号'
              },
              {
                name: '电话：025-8673-8728'
              },
              // {
              //   name: '微信公众号:伯俊支付'
              // },
              {
                name: '合作邮箱：zhou.c@syman.cn'
              }
            ]
          },
        ]
      };
    },
    props: {
      isShowDoc: {
        type: Boolean,
        default: false
      }
    },
    components: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
      useClick(item) {
        if (typeof item.clickMethod === 'function') {
          item.clickMethod();
        }
      }
    },
    watch: {
      isShowDoc: function(val) {
        if (val) {
          this.bottomFooterList = [
            {
              title: '产品',
              list: [
                {
                  name: '支付服务',
                  clickMethod: () => {
                    this.$router.push({ path: '/cloudContent/payCloud' });
                  }
                },
                {
                  name: '短信服务',
                  clickMethod: () => {
                    this.$router.push({ path: '/cloudContent/smsCloud' });
                  }
                },
                {
                  name: '发票服务',
                  clickMethod: () => {
                    this.$router.push({ path: '/cloudContent/invCloud' });
                  }
                }
              ]
            },
            {
              title: '公司信息',
              list: [
                // {
                //   name: '希曼软件',
                //   clickMethod: () => {
                //     window.open('http://www.burgeon.cn/');
                //   }
                // },
                {
                  name: '©南京希曼软件技术有限公司'
                },
                {
                  name: '备案号：苏ICP备14038201号-5',
                  clickMethod: () => {
                    window.open('http://beian.miit.gov.cn');
                  }
                }
              ]
            },
            {
              title: '开发文档',
              list: [
                {
                  name: 'API文档',
                  clickMethod: () => {
                    this.$router.push({ path: '/cloudContent/interfaceDoc' });
                  }
                },
                {
                  name: 'SDK下载',
                  clickMethod: () => {
                    this.$router.push({ path: '/cloudContent/paymentAccess' });
                  }
                }
              ]
            },
            {
              title: '联系我们',
              list: [
                {
                  name: '地址：江苏省南京市雨花台区安德门大街58号'
                },
                {
                  name: '电话：025-8673-8728'
                },
                // {
                //   name: '微信公众号:伯俊支付'
                // },
                {
                  name: '合作邮箱：zhou.c@syman.cn'
                }
              ]
            },
          ]
        } else {
          this.bottomFooterList = [
            {
              title: '产品',
              list: [
                {
                  name: '支付服务',
                  clickMethod: () => {
                    this.$router.push({ path: '/cloudContent/payCloud' });
                  }
                },
                {
                  name: '短信服务',
                  clickMethod: () => {
                    this.$router.push({ path: '/cloudContent/smsCloud' });
                  }
                },
                {
                  name: '发票服务',
                  clickMethod: () => {
                    this.$router.push({ path: '/cloudContent/invCloud' });
                  }
                }
              ]
            },
            {
              title: '公司信息',
              list: [
                // {
                //   name: '希曼软件',
                //   clickMethod: () => {
                //     window.open('http://www.burgeon.cn/');
                //   }
                // },
                {
                  name: '©南京希曼软件技术有限公司'
                },
                {
                  name: '备案号：苏ICP备14038201号-5',
                  clickMethod: () => {
                    window.open('http://beian.miit.gov.cn');
                  }
                }
              ]
            },
            {
              title: '联系我们',
              list: [
                {
                  name: '地址：江苏省南京市雨花台区安德门大街58号'
                },
                {
                  name: '电话：025-8673-8728'
                },
                // {
                //   name: '微信公众号:伯俊支付'
                // },
                {
                  name: '合作邮箱：zhou.c@syman.cn'
                }
              ]
            },
          ]
        }
      }
    },
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    @import "../../../static/theme/theme";
    @media screen and (min-width: 768px) {
        #footers {
            min-width: 1100px;
        }
        .company-partner {
            width: 1100px;
            margin: auto;
            padding: 80px;
            .main-title {
                margin-bottom: 15px;
            }
            .partnerImg-Cont {
                margin-top: 40px;
                >img {
                    width: 936px;
                    height: 382px;
                }
            }
        }
        .footer-container {
            padding: 80px 0;
            background: #031422;
            color: #fff;
            .footer-body {
                width: 1100px;
                margin: auto;
                .serverList {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    >li.serverTip {
                        width: 250px;
                        margin-bottom: 16px;
                        padding: 0 10px;
                        .server-title {
                            font-size: 16px;
                            margin-bottom: 8px;
                        }
                        >ul.link-list {
                            font-size: 14px;
                            >li.linkTip {
                                padding: 3px 0;
                                cursor: pointer;
                            }
                            >li.linkTip:hover {
                                color: #7a9eff;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .company-partner {
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px;
            .main-title {
                margin-bottom: 15px;
            }
            .partnerImg-Cont {
                margin-top: 40px;
                >img {
                    width: 100%;
                    /*height: 382px;*/
                }
            }
        }
        .footer-container {
            width: 100%;
            background: #031422;
            color: #fff;
            padding: 20px;
            .footer-body {
                margin: auto;
                .serverList {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    >li.serverTip {
                        width: 210px;
                        margin-bottom: 24px;
                        padding: 0 10px;
                        .server-title {
                            font-size: 16px;
                            margin-bottom: 8px;
                        }
                        >ul.link-list {
                            font-size: 14px;
                            >li.linkTip {
                                padding: 2px 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
