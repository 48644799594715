var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "forgetPassword" } },
    [
      _c("div", { staticClass: "titleContent" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "logo" }, [
            _c("img", {
              attrs: { src: require("../assets/home/exhibition/logo.png") },
              on: { click: _vm.logoClick },
            }),
            _vm._v(" "),
            _vm.type == 2
              ? _c("p", { staticClass: "changeTitle" }, [
                  _vm._v("\n              修改手机号\n          "),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loginBGM" }, [
        _vm.type != 2
          ? _c("div", { staticClass: "typeStatus" }, [
              _c(
                "span",
                {
                  class: _vm.type == 1 ? "typeAct" : "",
                  on: {
                    click: function ($event) {
                      return _vm.changeType(1)
                    },
                  },
                },
                [_vm._v("找回密码")]
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { margin: "0 10px" } }, [_vm._v("|")]),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: _vm.type == 3 ? "typeAct" : "",
                  on: {
                    click: function ($event) {
                      return _vm.changeType(3)
                    },
                  },
                },
                [_vm._v("找回用户名")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              width: "774px",
              margin: "50px 0 0 200px",
              "font-size": "14px",
            },
          },
          [
            _c("Icon", { attrs: { type: "md-pricetags", color: "#ff6a00" } }),
            _vm._v(" "),
            _vm.type == 1
              ? _c("span", [_vm._v("通过账户找回登录密码")])
              : _vm._e(),
            _vm._v(" "),
            _vm.type == 2
              ? _c("span", [_vm._v("修改账户绑定的手机号")])
              : _vm._e(),
            _vm._v(" "),
            _vm.type == 3
              ? _c("span", [_vm._v("通过手机号找回用户名")])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { ref: "container", staticClass: "container" }, [
          _c("div", { staticClass: "forget" }, [
            _c("div", { staticClass: "inputCont" }, [
              _vm.type == 1
                ? _c("p", { staticClass: "titleTip" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(
                          _vm.status == "checkUser" ||
                            _vm.status == "phoneByUsername"
                            ? "登录用户名"
                            : _vm.status == "checkYzm"
                            ? "登录手机号码"
                            : _vm.status == "confirmPassword"
                            ? "确认密码"
                            : _vm.status == "changePhone"
                            ? "修改手机号"
                            : ""
                        ) +
                        "\n                  "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.type == 3
                ? _c("p", { staticClass: "titleTip" }, [_vm._v("登录手机号码")])
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "checkUser"
                ? _c("div", { staticClass: "contentInput" }, [
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/account.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userName,
                            expression: "userName",
                          },
                        ],
                        attrs: { type: "text", placeholder: "请输入用户名" },
                        domProps: { value: _vm.userName },
                        on: {
                          keydown: _vm.userNameKeydown,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.userName = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.userNameError,
                              expression: "userNameError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v("请输入用户名")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "phoneByUsername"
                ? _c("div", { staticClass: "contentInput" }, [
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/phone.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.phoneRetrieve,
                            expression: "phoneRetrieve",
                          },
                        ],
                        attrs: { type: "text", placeholder: "请输入手机号" },
                        domProps: { value: _vm.phoneRetrieve },
                        on: {
                          blur: _vm.checkPhoneformat,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.phoneRetrieve = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.phoneRetError,
                              expression: "phoneRetError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v(_vm._s(_vm.phoneRetErrorTip))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/verification.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.imgCode,
                            expression: "imgCode",
                          },
                        ],
                        attrs: { type: "text", placeholder: "请输入验证码" },
                        domProps: { value: _vm.imgCode },
                        on: {
                          blur: _vm.checkImgCode,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.imgCode = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.codeUrl
                        ? _c("img", {
                            staticClass: "yzmImg",
                            attrs: {
                              src: "data:image/png;base64," + _vm.codeUrl,
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getCode()
                              },
                            },
                          })
                        : _c("img", {
                            staticClass: "yzmImg",
                            attrs: { src: "static/img/defeated.png", alt: "" },
                            on: {
                              click: function ($event) {
                                return _vm.getCode()
                              },
                            },
                          }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.imgCodeError,
                              expression: "imgCodeError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v("验证码不能为空！")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/message.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.messageCode,
                            expression: "messageCode",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "请输入短信验证码",
                        },
                        domProps: { value: _vm.messageCode },
                        on: {
                          keydown: _vm.userNameKeydown,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.messageCode = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "changeText" }, [
                        !_vm.loadingCode
                          ? _c(
                              "span",
                              {
                                staticClass: "verification",
                                on: { click: _vm.getVerifyCode },
                              },
                              [_vm._v("点击获取")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loadingCode
                          ? _c("span", { staticClass: "countDown" }, [
                              _vm._v(_vm._s(_vm.countDown) + "秒重新获取"),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.messageCodeError,
                              expression: "messageCodeError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v("短信验证码不能为空！")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "checkYzm"
                ? _c("div", { staticClass: "contentInput" }, [
                    _c("div", { staticClass: "item-module item-phone" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/phone.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { attrs: { type: "text" } }, [
                        _vm._v(_vm._s(_vm.phone)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.type == 1
                      ? _c("div", { staticClass: "item-module" }, [
                          _c("img", {
                            staticClass: "imgIcon",
                            attrs: {
                              src: require("../assets/image/verification.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.imgCode,
                                expression: "imgCode",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "请输入验证码",
                            },
                            domProps: { value: _vm.imgCode },
                            on: {
                              blur: _vm.checkImgCode,
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.imgCode = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.codeUrl
                            ? _c("img", {
                                staticClass: "yzmImg",
                                attrs: {
                                  src: "data:image/png;base64," + _vm.codeUrl,
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getCode()
                                  },
                                },
                              })
                            : _c("img", {
                                staticClass: "yzmImg",
                                attrs: {
                                  src: "static/img/defeated.png",
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getCode()
                                  },
                                },
                              }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.imgCodeError,
                                  expression: "imgCodeError",
                                },
                              ],
                              staticClass: "error-tip",
                            },
                            [_vm._v("验证码不能为空！")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/message.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.messageCode,
                            expression: "messageCode",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "请输入短信验证码",
                        },
                        domProps: { value: _vm.messageCode },
                        on: {
                          keydown: _vm.userNameKeydown,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.messageCode = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "changeText" }, [
                        !_vm.loadingCode
                          ? _c(
                              "span",
                              {
                                staticClass: "verification",
                                on: { click: _vm.getVerifyCode },
                              },
                              [_vm._v("点击获取")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loadingCode
                          ? _c("span", { staticClass: "countDown" }, [
                              _vm._v(_vm._s(_vm.countDown) + "秒重新获取"),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.messageCodeError,
                              expression: "messageCodeError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v("短信验证码不能为空！")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "confirmPassword"
                ? _c("div", { staticClass: "contentInput" }, [
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/password.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      (_vm.seePassword ? "text" : "password") === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                            ],
                            attrs: {
                              placeholder: "请输入新密码",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.password)
                                ? _vm._i(_vm.password, null) > -1
                                : _vm.password,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.password,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.password = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.password = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.password = $$c
                                }
                              },
                            },
                          })
                        : (_vm.seePassword ? "text" : "password") === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                            ],
                            attrs: {
                              placeholder: "请输入新密码",
                              type: "radio",
                            },
                            domProps: { checked: _vm._q(_vm.password, null) },
                            on: {
                              change: function ($event) {
                                _vm.password = null
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                            ],
                            attrs: {
                              placeholder: "请输入新密码",
                              type: _vm.seePassword ? "text" : "password",
                            },
                            domProps: { value: _vm.password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.password = $event.target.value
                              },
                            },
                          }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.seePassword,
                            expression: "!seePassword",
                          },
                        ],
                        staticClass: "iconfont iconbj_see",
                        on: {
                          click: function ($event) {
                            _vm.seePassword = true
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.seePassword,
                            expression: "seePassword",
                          },
                        ],
                        staticClass: "iconfont iconbj_nosee",
                        on: {
                          click: function ($event) {
                            _vm.seePassword = false
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.passwordError,
                              expression: "passwordError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v("必须包含字母、数字、特殊字符且不小于8位！")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/passwordTwo.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      (_vm.seeConPassword ? "text" : "password") === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confirmPassword,
                                expression: "confirmPassword",
                              },
                            ],
                            attrs: {
                              onpaste: "return false",
                              placeholder: "确认新密码",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.confirmPassword)
                                ? _vm._i(_vm.confirmPassword, null) > -1
                                : _vm.confirmPassword,
                            },
                            on: {
                              keydown: _vm.userNameKeydown,
                              change: function ($event) {
                                var $$a = _vm.confirmPassword,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.confirmPassword = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.confirmPassword = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.confirmPassword = $$c
                                }
                              },
                            },
                          })
                        : (_vm.seeConPassword ? "text" : "password") === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confirmPassword,
                                expression: "confirmPassword",
                              },
                            ],
                            attrs: {
                              onpaste: "return false",
                              placeholder: "确认新密码",
                              type: "radio",
                            },
                            domProps: {
                              checked: _vm._q(_vm.confirmPassword, null),
                            },
                            on: {
                              keydown: _vm.userNameKeydown,
                              change: function ($event) {
                                _vm.confirmPassword = null
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confirmPassword,
                                expression: "confirmPassword",
                              },
                            ],
                            attrs: {
                              onpaste: "return false",
                              placeholder: "确认新密码",
                              type: _vm.seeConPassword ? "text" : "password",
                            },
                            domProps: { value: _vm.confirmPassword },
                            on: {
                              keydown: _vm.userNameKeydown,
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.confirmPassword = $event.target.value
                              },
                            },
                          }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.seeConPassword,
                            expression: "!seeConPassword",
                          },
                        ],
                        staticClass: "iconfont iconbj_see",
                        on: {
                          click: function ($event) {
                            _vm.seeConPassword = true
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.seeConPassword,
                            expression: "seeConPassword",
                          },
                        ],
                        staticClass: "iconfont iconbj_nosee",
                        on: {
                          click: function ($event) {
                            _vm.seeConPassword = false
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.confirmPasswordError,
                              expression: "confirmPasswordError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v("两次密码不一致！")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "changePhone"
                ? _c("div", { staticClass: "contentInput" }, [
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/phone.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newPhone,
                            expression: "newPhone",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "请输入新绑定的手机号",
                        },
                        domProps: { value: _vm.newPhone },
                        on: {
                          blur: _vm.checkNewPhone,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.newPhone = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.newPhoneError,
                              expression: "newPhoneError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v(_vm._s(_vm.newPhoneErrorTip))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/verification.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.imgCode,
                            expression: "imgCode",
                          },
                        ],
                        attrs: { type: "text", placeholder: "请输入验证码" },
                        domProps: { value: _vm.imgCode },
                        on: {
                          blur: _vm.checkImgCode,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.imgCode = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.codeUrl
                        ? _c("img", {
                            staticClass: "yzmImg",
                            attrs: {
                              src: "data:image/png;base64," + _vm.codeUrl,
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getCode()
                              },
                            },
                          })
                        : _c("img", {
                            staticClass: "yzmImg",
                            attrs: { src: "static/img/defeated.png", alt: "" },
                            on: {
                              click: function ($event) {
                                return _vm.getCode()
                              },
                            },
                          }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.imgCodeError,
                              expression: "imgCodeError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v("验证码不能为空！")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-module" }, [
                      _c("img", {
                        staticClass: "imgIcon",
                        attrs: {
                          src: require("../assets/image/message.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newMessageCode,
                            expression: "newMessageCode",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "请输入短信验证码",
                        },
                        domProps: { value: _vm.newMessageCode },
                        on: {
                          keydown: _vm.userNameKeydown,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.newMessageCode = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "changeText" }, [
                        !_vm.loadingCode
                          ? _c(
                              "span",
                              {
                                staticClass: "verification",
                                on: { click: _vm.getVerifyCodeTwo },
                              },
                              [_vm._v("点击获取")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loadingCode
                          ? _c("span", { staticClass: "countDown" }, [
                              _vm._v(_vm._s(_vm.countDown) + "秒重新获取"),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.newMessageCodeError,
                              expression: "newMessageCodeError",
                            },
                          ],
                          staticClass: "error-tip",
                        },
                        [_vm._v("短信验证码不能为空！")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "userList"
                ? _c("div", { staticClass: "contentInput" }, [
                    _c("p", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v("当前手机号绑定的用户名："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "usernameList" },
                      [
                        _vm._l(_vm.usernameList, function (item, index) {
                          return _c("li", [
                            _vm._v(
                              _vm._s("(" + (index + 1) + ") ") + _vm._s(item)
                            ),
                          ])
                        }),
                        _vm._v(" "),
                        _vm.usernameList.length === 0
                          ? _c("li", [_vm._v("当前手机号暂无绑定用户！！")])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "btnGroup" }, [
              _vm.status === "checkUser" ||
              _vm.status === "checkYzm" ||
              _vm.status === "phoneByUsername"
                ? _c(
                    "div",
                    {
                      staticClass: "btnConfirm",
                      on: { click: _vm.checkIdentity },
                    },
                    [_vm._v("下一步")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "confirmPassword"
                ? _c(
                    "div",
                    {
                      staticClass: "btnConfirm",
                      on: { click: _vm.checkIdentity },
                    },
                    [_vm._v("修改密码")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "changePhone"
                ? _c(
                    "div",
                    {
                      staticClass: "btnConfirm",
                      on: { click: _vm.checkIdentity },
                    },
                    [_vm._v("修改手机号")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.status === "userList"
                ? _c(
                    "div",
                    {
                      staticClass: "btnConfirm",
                      on: { click: _vm.checkIdentity },
                    },
                    [_vm._v("返回登录")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottomTip" }, [
        _vm._v("移动支付软件 V1.0.0   南京希曼软件技术有限公司"),
      ]),
      _vm._v(" "),
      _vm.showLoading
        ? _c("Spin", { attrs: { size: "large", fix: "" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }