import { render, staticRenderFns } from "./sdkModule.vue?vue&type=template&id=2f8f2477&scoped=true"
import script from "./sdkModule.vue?vue&type=script&lang=js"
export * from "./sdkModule.vue?vue&type=script&lang=js"
import style0 from "./sdkModule.vue?vue&type=style&index=0&id=2f8f2477&prod&lang=less"
import style1 from "./sdkModule.vue?vue&type=style&index=1&id=2f8f2477&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8f2477",
  null
  
)

export default component.exports