<template>
  <div class="paymentAccess">
    <div v-for="(item, index) in dataList" class="data">
      <div class="title">
        <div class="tab"></div>
        <div class="visitText">{{ item.title }}</div>
      </div>
      <div v-if="item.list.length > 0" class="block">
        <div v-for="(i, c) in item.list" class="blockmes">
          <div class="blockTitle">{{ item.blockTitle }}</div>
          <div style="display: flex; align-items: center">
            <div class="date">{{i.date}}</div>
            <a :href="i.url" class="downloadSDK">
              <div class="edition">{{ i.edition }}</div>
              <div class="paymes">{{ i.paymes }}</div>
              <i class="iconfont iconmd-download downloadFile" />
            </a>
            <a target="_blank" :href="i.githubUrl" class="gitblock">
              <img src="../../assets/image/gitIcon.png" class="gitIcon" />
              <div class="iconText">{{ i.iconText }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dataList: [
          {
            title: '支付服务',
            blockTitle: 'Client SDK',
            list: [
              // {
              //   date: '2020.05.06',
              //   edition: 'raincloud-pay-1.4.0',
              //   paymes: '支付接入SDK',
              //   iconText: 'GitHub',
              //   url: 'https://syman-pay.oss-cn-shanghai.aliyuncs.com/PAY-SDK/raincloud-pay-1.4.0-SNAPSHOT.jar',
              //   githubUrl: 'https://github.com/hahajingnt/pi-pay.git',
              // },
              {
                date: '2020.12.31',
                edition: 'raincloud-pay-1.4.0.1',
                paymes: '支付接入SDK',
                iconText: 'GitHub',
                url: 'https://syman-pay.oss-cn-shanghai.aliyuncs.com/PAY-SDK/raincloud-pay-1.4.0.1.jar',
                githubUrl: 'https://github.com/hahajingnt/pi-pay.git',
              },
              {
                date: '2021.03.10',
                edition: 'raincloud-pay-1.4.0.2',
                paymes: '支付接入SDK',
                iconText: 'GitHub',
                url: 'https://syman-pay.oss-cn-shanghai.aliyuncs.com/PAY-SDK/raincloud-pay-1.4.0.2.jar',
                githubUrl: 'https://github.com/hahajingnt/pi-pay.git',
              },
            ],
          },
          {
            title: '短信服务',
            blockTitle: 'Client SDK',
            list: [
              {
                date: '2021.01.08',
                edition: 'burgeon-sms-0.0.1',
                paymes: '短信接入SDK',
                iconText: 'GitHub',
                url: 'https://syman-sms.oss-cn-shanghai.aliyuncs.com/SDK/burgeon-sms-0.0.1.jar',
                githubUrl: 'https://github.com/hahajingnt/burgeon-sms.git',
              },
            // {
            //   edition: 'raincloud-pay-1.4.0',
            //   paymes: '支付接入SDK',
            //   iconText: 'GitHub',
            // },
            ],
          },
        ],
      };
    },
  };
</script>
<style lang="less">
body {
  background-color: #eee !important;
}
</style>

<style scoped lang="less">
.paymentAccess {
  width: 831px;
  //   height: 100%;
  padding-bottom: 80px;
  margin: auto;
  // padding: 30px 30px 30px 28px;
  min-width: 1024px;
}
.title {
  // overflow: hidden;
  display: flex;
  padding-top: 30px;
}
.tab {
  width: 4px;
  height: 15px;
  background: #fd8368;
  // margin:0 0 0 20px;
}
.visitText {
  margin: 0 0 0 6px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 15px;
}
.platform {
  width: 88px;
  height: 32px;
  background: #f6f8fb;
}
.platformText {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.data {
}
.firstDateTitle {
  font-size: 20px;
  font-weight: 400;
  color: #fd6442;
  /*line-height: 28px;*/
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.secondDateTitle {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  /*line-height: 28px;*/
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.block {
  margin-top: 30px;
  padding: 30px;
  background: #f9f9f9;
  box-shadow: 6px 3px 6px 0px rgba(150, 195, 198, 0.24);
  border-radius: 3px;
}
.blockTitle {
  /*width: 114px;*/
  height: 28px;
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  /*line-height: 28px;*/
  //   margin: 30px 0 10px 83px;
}
.blockmes {
  padding: 0 30px;
  height: 72px;
  background: #ffffff;
  box-shadow: 6px 5px 16px 0px rgba(150, 195, 198, 0.13);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.blockmes:last-child {
  margin-bottom: 0;
}
.date {
  font-size: 18px;
  margin-right: 25px;
  color: #999;
}

.downloadSDK {
  display: flex;
  align-items: center;
  margin-right: 35px;
  .downloadFile,
  .edition,
  .paymes {
    color: #333;
  }
}
.downloadSDK:hover {
  .downloadFile,
  .edition,
  .paymes {
    color: inherit;
  }
}
.edition {
  font-size: 20px;
  font-weight: 400;
  /*line-height: 28px;*/
}
.paymes {
  font-size: 14px;
  font-weight: 400;
  /*line-height: 20px;*/
  margin: 0 15px;
}
.gitblock {
  display: flex;
  margin-left: 10px;
  align-items: center;
}
.gitIcon {
  width: 36px;
  height: 36px;
}
.iconText {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
  //   line-height: 17px;
  //   margin: 22px 0 0 5px;
}
</style>
