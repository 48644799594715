var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loginContent" }, [
    _c("div", { staticClass: "titleContent" }, [_c("headerComponent")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "loginBG" }, [
      _c("div", { staticClass: "loginMiddle" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "containerNew" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("p", { staticClass: "titleTOP" }, [_vm._v("登录")]),
          _vm._v(" "),
          _c("div", { staticClass: "inputInfo" }, [
            _c("img", {
              staticClass: "icon",
              attrs: { src: require("../assets/image/account.png") },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.uname,
                  expression: "uname",
                },
              ],
              ref: "username",
              staticClass: "username",
              attrs: {
                type: "text",
                value: "",
                placeholder: "请输入用户名",
                autofocus: "",
              },
              domProps: { value: _vm.uname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.uname = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputInfo" }, [
            _c("img", {
              staticClass: "icon",
              attrs: { src: require("../assets/image/password.png") },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pwd,
                  expression: "pwd",
                },
              ],
              ref: "password",
              staticClass: "pwd",
              attrs: { type: "password", value: "", placeholder: "请输入密码" },
              domProps: { value: _vm.pwd },
              on: {
                keydown: _vm.passwordKeydown,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.pwd = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btnNew",
              on: {
                click: function ($event) {
                  return _vm.login()
                },
              },
            },
            [_vm._v("\n          登录\n        ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "forgetPasswordNew" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.forgetPassword(1)
                  },
                },
              },
              [_vm._v("忘记密码?")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.forgetPassword(3)
                  },
                },
              },
              [_vm._v("忘记用户名?")]
            ),
            _vm._v(" "),
            _c("span", { on: { click: _vm.register } }, [_vm._v("新公司注册")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgCont" }, [
      _c("img", {
        attrs: {
          src: "http://ark-static.oss-cn-hangzhou.aliyuncs.com/pay-platform/image/authenticationCenter/loginBanner.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgTitle" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../assets/image/logo.png"), alt: "logo" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }