<template>
  <div id="forgetPassword">
    <div class="titleContent">
      <div class="title">
        <div class="logo">
          <img src="../assets/home/exhibition/logo.png" @click="logoClick">
            <p v-if="type == 2" class="changeTitle">
                修改手机号
            </p>
        </div>
      </div>
    </div>
    <div class="loginBGM">
        <div v-if="type != 2" class="typeStatus">
            <span @click="changeType(1)" :class="type == 1 ? 'typeAct' : ''">找回密码</span>
            <span style="margin:0 10px;">|</span>
            <span @click="changeType(3)" :class="type == 3 ? 'typeAct' : ''">找回用户名</span>
        </div>
        <p style="width: 774px;margin: 50px 0 0 200px;font-size: 14px">
            <Icon type="md-pricetags" color="#ff6a00"/>
            <span v-if="type == 1">通过账户找回登录密码</span>
            <span v-if="type == 2">修改账户绑定的手机号</span>
            <span v-if="type == 3">通过手机号找回用户名</span>
        </p>
        <div ref="container" class="container">
            <div class="forget">
                <div class="inputCont">
                    <p v-if="type == 1" class="titleTip">
                        {{(status == 'checkUser' || status == 'phoneByUsername') ? '登录用户名' : status == 'checkYzm' ? '登录手机号码' :
                        status == 'confirmPassword' ? '确认密码' : status == 'changePhone' ? '修改手机号' : ''}}
                    </p>
                    <p v-if="type == 3" class="titleTip">登录手机号码</p>
                    <div v-if="status === 'checkUser'" class="contentInput">
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/account.png" alt="">
                            <input type="text" v-model="userName" @keydown="userNameKeydown" placeholder="请输入用户名">
                            <p class="error-tip" v-show="userNameError">请输入用户名</p>
                        </div>
                    </div>
                    <div v-if="status === 'phoneByUsername'" class="contentInput">
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/phone.png" alt="">
                            <input type="text"  v-model="phoneRetrieve" @blur="checkPhoneformat" placeholder="请输入手机号"/>
                            <p class="error-tip" v-show="phoneRetError">{{phoneRetErrorTip}}</p>
                        </div>
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/verification.png" alt="">
                            <input type="text" v-model="imgCode" @blur="checkImgCode" placeholder="请输入验证码"/>
                            <img v-if="codeUrl" class="yzmImg" @click="getCode()" :src="'data:image/png;base64,'+codeUrl" alt="">
                            <img v-else class="yzmImg" src="static/img/defeated.png" @click="getCode()" alt="">
                            <p class="error-tip" v-show="imgCodeError">验证码不能为空！</p>
                        </div>
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/message.png" alt="">
                            <input type="text" v-model="messageCode" @keydown="userNameKeydown" placeholder="请输入短信验证码"/>
                            <div class="changeText">
                                <span v-if="!loadingCode" class="verification" @click="getVerifyCode">点击获取</span>
                                <span v-if="loadingCode"  class="countDown">{{ countDown }}秒重新获取</span>
                            </div>
                            <p class="error-tip" v-show="messageCodeError">短信验证码不能为空！</p>
                        </div>
                    </div>
                    <div v-if="status === 'checkYzm'" class="contentInput">
                        <div class="item-module item-phone">
                            <img class="imgIcon" src="../assets/image/phone.png" alt="">
                            <span type="text">{{phone}}</span>
                        </div>
                        <div class="item-module" v-if='type == 1'>
                            <img class="imgIcon" src="../assets/image/verification.png" alt="">
                            <input type="text" v-model="imgCode" @blur="checkImgCode" placeholder="请输入验证码"/>
                            <!--<Input type="text" placeholder="请输入验证码" v-model="inputCode">-->
                            <img v-if="codeUrl" class="yzmImg" @click="getCode()" :src="'data:image/png;base64,'+codeUrl" alt="">
                            <img v-else class="yzmImg" src="static/img/defeated.png" @click="getCode()" alt="">
                            <p class="error-tip" v-show="imgCodeError">验证码不能为空！</p>
                        </div>
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/message.png" alt="">
                            <input type="text" v-model="messageCode" @keydown="userNameKeydown" placeholder="请输入短信验证码"/>
                            <div class="changeText">
                                <span v-if="!loadingCode" class="verification" @click="getVerifyCode">点击获取</span>
                                <span v-if="loadingCode"  class="countDown">{{ countDown }}秒重新获取</span>
                            </div>
                            <p class="error-tip" v-show="messageCodeError">短信验证码不能为空！</p>
                        </div>
                    </div>
                    <div v-if="status === 'confirmPassword'" class="contentInput">
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/password.png" alt="">
                            <input :type="seePassword ? 'text' : 'password'" v-model="password" placeholder="请输入新密码">
                            <i v-show="!seePassword" class="iconfont iconbj_see" @click="seePassword = true"></i>
                            <i v-show="seePassword" class="iconfont iconbj_nosee" @click="seePassword = false"></i>
                            <p class="error-tip" v-show="passwordError">必须包含字母、数字、特殊字符且不小于8位！</p>
                        </div>
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/passwordTwo.png" alt="">
                            <input :type="seeConPassword ? 'text' : 'password'" v-model="confirmPassword"
                                   @keydown="userNameKeydown" onpaste="return false" placeholder="确认新密码">
                            <i v-show="!seeConPassword" class="iconfont iconbj_see" @click="seeConPassword = true"></i>
                            <i v-show="seeConPassword" class="iconfont iconbj_nosee" @click="seeConPassword = false"></i>
                            <p class="error-tip" v-show="confirmPasswordError">两次密码不一致！</p>
                        </div>
                    </div>
                    <div v-if="status === 'changePhone'" class="contentInput">
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/phone.png" alt="">
                            <input type="text" v-model="newPhone" @blur="checkNewPhone" placeholder="请输入新绑定的手机号"/>
                            <p class="error-tip" v-show="newPhoneError">{{newPhoneErrorTip}}</p>
                        </div>
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/verification.png" alt="">
                            <input type="text" v-model="imgCode" @blur="checkImgCode" placeholder="请输入验证码"/>
                            <img v-if="codeUrl" class="yzmImg" @click="getCode()" :src="'data:image/png;base64,'+codeUrl" alt="">
                            <img v-else class="yzmImg" src="static/img/defeated.png" @click="getCode()" alt="">
                            <p class="error-tip" v-show="imgCodeError">验证码不能为空！</p>
                        </div>
                        <div class="item-module">
                            <img class="imgIcon" src="../assets/image/message.png" alt="">
                            <input type="text" v-model="newMessageCode" @keydown="userNameKeydown" placeholder="请输入短信验证码"/>
                            <div class="changeText">
                                <span v-if="!loadingCode" class="verification" @click="getVerifyCodeTwo">点击获取</span>
                                <span v-if="loadingCode"  class="countDown">{{ countDown }}秒重新获取</span>
                            </div>
                            <p class="error-tip" v-show="newMessageCodeError">短信验证码不能为空！</p>
                        </div>
                    </div>
                    <div v-if="status === 'userList'" class="contentInput">
                        <p style="font-size: 16px">当前手机号绑定的用户名：</p>
                        <ul class="usernameList">
                            <li v-for="(item, index) in usernameList">{{'(' + (index+1) + ') '}}{{item}}</li>
                            <li v-if="usernameList.length === 0">当前手机号暂无绑定用户！！</li>
                        </ul>
                    </div>
                </div>
                <!--<div class="line"></div>-->
                <div class="btnGroup">
                    <div v-if="status === 'checkUser' || status === 'checkYzm' || status === 'phoneByUsername'" @click="checkIdentity" class="btnConfirm">下一步</div>
                    <div v-if="status === 'confirmPassword'" @click="checkIdentity" class="btnConfirm">修改密码</div>
                    <div v-if="status === 'changePhone'" @click="checkIdentity" class="btnConfirm">修改手机号</div>
                    <div v-if="status === 'userList'" @click="checkIdentity" class="btnConfirm">返回登录</div>
                </div>
            </div>
        <!--<div id="btn" class="btn" @click="login"></div>-->
        </div>
    </div>
    <div class="bottomTip">移动支付软件 V1.0.0 &nbsp; 南京希曼软件技术有限公司</div>
      <Spin v-if="showLoading" size="large" fix></Spin>
  </div>
</template>

<script>
  import R3 from '@syman/burgeon-r3';

  const { network } = R3;
  
  export default {
    name: 'ForgetPassword',
    data() {
      return {
        serviceId: window.sessionStorage.getItem('serviceId'),
        type: 1,
        userName: '',
        phone: '',
        password: '',
        phoneRetrieve: '', // 通过手就号招呼用户名
        phoneRetError: '', // 通过手机号错误提示
        phoneRetErrorTip: '', // 通过手机号错误提示信息
        usernameList: [], // 查找用户列表
        confirmPassword: '', // 确认密码
        userNameError: false, // 用户名报错信息
        status: 'checkUser', // 当前步骤状态
        codeImageName: '', // 验证码的名称 对应检验的
        imgCode: '', // 验证码字符
        imgCodeError: false, // 验证码报错信息显示
        messageCode: '', // 短信验证码
        messageCodeError: false, // 短信验证码报错显示
        codeUrl: '',
        loadingCode: false, // 显示计数
        countDown: 120, // 定时计数
        countDownInterval: null, // 定时器
        passwordError: false, // 密码报错信息显示
        confirmPasswordError: false, // 确认密码报错信息显示
        seePassword: false, // 显示密码框type
        seeConPassword: false, // 显示确认密码框type
        newPhone: '', // 新手机号
        newPhoneError: false, // 新手机号报错提示
        newPhoneErrorTip: '手机号不能为空！', // 新手机号报错提示
        newMessageCode: '', // 新手机短信验证码
        newMessageCodeError: false, // 新手机短信验证码提示
        showLoading: true,
      };
    },
    components: {
    },
    beforeCreate() {
    },
    created() {
      let { type, name } = this.$route.query;
      this.type = type || 1;
      this.userName = name;
      this.baseData();
    },
    beforeMount() {
      this.serviceId = window.sessionStorage.getItem('serviceId');
    },
    mounted() {
    },
    methods: {
      baseData() {
        if (this.type == 1) {
          this.status = 'checkUser';
          this.showLoading = false;
        } else if (this.type == 2) {
          this.status = 'checkYzm';
          this.getCode();
          network.post(this.serviceId + '/p/cs/get_phone').then((res) => {
            this.showLoading = false;
            if (res.data.code === 0) {
              this.phone = res.data.data.phone;
            } else if (res.data.code === -1) {
              this.$Modal.fcError({
                title: '提示',
                mask: true,
                content: res.data.message,
                onOk: () => {
                }
              });
            }
          }).catch((err) => {
            throw err;
          });
        } else if (this.type == 3) {
          this.showLoading = false;
          this.status = 'phoneByUsername';
          this.getCode();
        } else {
          this.showLoading = false;
        }
      },
      changeType(type) {
        if (this.type == type) {
          return;
        }
        this.gotoForget();
        this.type = type;
        this.baseData();
      },
      checkImgCode() {
        this.imgCodeError = !this.imgCode;
      }, // 验证码错误提示
      checkNewPhone() {
        if (this.newPhone) {
          if (/^1(3|4|5|7|8)\d{9}$/.test(this.newPhone)) {
            this.newPhoneError = false;
          } else {
            this.newPhoneError = true;
            this.newPhoneErrorTip = '请输入正确手机号格式！';
          }
        } else {
          this.newPhoneError = true;
          this.newPhoneErrorTip = '手机号不能为空！';
        }
      }, // 新输入手机号错误提示
      checkPhoneformat() {
        if (this.phoneRetrieve) {
          if (/^1(3|4|5|7|8)\d{9}$/.test(this.phoneRetrieve)) {
            this.phoneRetError = false;
          } else {
            this.phoneRetError = true;
            this.phoneRetErrorTip = '请输入正确手机号格式！';
          }
        } else {
          this.phoneRetError = true;
          this.phoneRetErrorTip = '手机号不能为空！';
        }
      }, // 手机号错误提示
      getCode() {
        network.post(this.serviceId + '/p/c/code_image').then((res) => {
          this.codeUrl = res.data.codeImage;
          this.codeImageName = res.data.codeImageName;
        });
      }, // 图片验证码
      setIntervalCode() { // 验证码定时器
        this.loadingCode = true;
        this.countDownInterval = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            this.clearSetIntervalCode();
          }
        }, 1000);
      }, // 短信定时器
      clearSetIntervalCode() { // 验证码失败清除定时器
        this.loadingCode = false;
        this.countDown = 120;
        clearInterval(this.countDownInterval);
      },
      getVerifyCode() {
        if (this.type == 1) {
          if (this.imgCode) {
            this.setIntervalCode();
            this.phoneCodeByname();
          } else {
            this.imgCodeError = true;
          }
        } else if (this.type == 2) {
          this.setIntervalCode();
          this.phoneCodeByLogin();
        } else if (this.type == 3) {
          this.checkPhoneformat();
          if (this.imgCode && !this.phoneRetError) {
            this.setIntervalCode();
            this.phoneCodeByPhone();
          } else {
            this.imgCodeError = !this.imgCode;
          }
        }
      }, // 短信验证码
      getVerifyCodeTwo() {
        if (this.imgCode) {
          this.setIntervalCode();
          this.phoneCodeByPhone();
        } else {
          this.imgCodeError = true;
        }
      }, // 更换手机号时短信验证码
      phoneCodeByname() {
        let params = {
          name: this.userName,
          codeImageName: this.codeImageName,
          verifyCode: this.imgCode
        };
        network.post(this.serviceId + '/p/c/send_sms_byname', params).then((res) => {
          if (res.data.code === 0) {
            this.$Message.info({
              content: res.data.message,
              duration: 5
            });
          } else if (res.data.code === -1) {
            this.getCode();
            this.clearSetIntervalCode();
            this.$Modal.fcError({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
              }
            });
          }
        }).catch((err) => {
          this.getCode();
          this.clearSetIntervalCode();
          throw err;
        });
      }, // 通过用户名获取手机验证码
      phoneCodeByLogin() {
        network.post(this.serviceId + '/p/cs/send_sms').then((res) => {
          if (res.data.code === 0) {
            this.$Message.info({
              content: res.data.message,
              duration: 5
            });
          } else if (res.data.code === -1) {
            this.getCode();
            this.clearSetIntervalCode();
            this.$Modal.fcError({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
              }
            });
          }
        }).catch((err) => {
          this.getCode();
          this.clearSetIntervalCode();
          throw err;
        });
      }, // 通过登录信息获取手机验证码
      phoneCodeByPhone() {
        let params = {
          phone: this.newPhone || this.phoneRetrieve,
          codeImageName: this.codeImageName,
          verifyCode: this.imgCode
        };
        network.post(this.serviceId + '/p/c/send_sms_byphone', params).then((res) => {
          if (res.data.code === 0) {
            this.$Message.info({
              content: res.data.message,
              duration: 5
            });
          } else if (res.data.code === -1) {
            this.getCode();
            this.clearSetIntervalCode();
            this.$Modal.fcError({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
              }
            });
          }
        }).catch((err) => {
          this.getCode();
          this.clearSetIntervalCode();
          throw err;
        });
      }, // 通过手机号获取手机验证码
      logoClick() {
        this.$router.push({ path: '/cloudContent' });
      }, // logo点击触发
      gotoForget() {
        Object.assign(this.$data, this.$options.data());
        this.serviceId = window.sessionStorage.getItem('serviceId');
      },
      userNameKeydown(e) {
        if (e.keyCode === 13) {
          this.checkIdentity();
        }
      },
      backLogin() {
        // this.$router.go(-1);
        this.$router.push({ path: '/login' });
      }, // 返回登录
      userGetPhone() {
        this.getCode();
        let params = {
          name: this.userName
        };
        network.post(this.serviceId + '/p/c/get_phone_byname', params).then((res) => {
          if (res.data.code === 0) {
            this.phone = res.data.data.phone;
            this.status = 'checkYzm';
          } else if (res.data.code === -1) {
            this.$Modal.fcError({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
              }
            });
          }
        }).catch((err) => {
          throw err;
        });
      }, // 用户名获取手机号
      checkPhoneCode() {
        let params = {
          name: this.userName,
          verifyCode: this.messageCode
        };
        network.post(this.serviceId + '/p/c/verify_phonecode_byname', params).then((res) => {
          if (res.data.code === 0) {
            this.clearSetIntervalCode();
            if (this.type == 1) {
              this.status = 'confirmPassword';
            } else if (this.type == 2) {
              this.getCode();
              this.status = 'changePhone';
            }
          } else if (res.data.code === -1) {
            this.$Modal.fcError({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
              }
            });
          }
        }).catch((err) => {
          throw err;
        });
      }, // 验证手机验证码
      changePassword() {
        let params = {
          name: this.userName,
          verifyCode: this.messageCode,
          password: this.password
        };
        network.post(this.serviceId + '/p/c/user/modifyPasswordByName', params).then((res) => {
          if (res.data.code === 0) {
            this.$Modal.fcSuccess({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
                this.backLogin();
              },
              onCancel: () => {
                this.backLogin();
              },
            });
          } else if (res.data.code === -1) {
            this.$Modal.fcError({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
              }
            });
            // this.gotoForget();
          }
        }).catch((err) => {
          throw err;
        });
      }, // 确认修改密码
      checkChangePhone() {
        let params = {
          phone: this.newPhone,
          verifyCode: this.messageCode,
          verifyCodeNew: this.newMessageCode
        };
        network.post(this.serviceId + '/p/cs/user/modifyPhone', params).then((res) => {
          if (res.data.code === 0) {
            network.post(this.serviceId + '/p/cs/logout').then(() => {});
            this.$Modal.fcSuccess({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
                window.close();
              },
              onCancel: () => {
                window.close();
              },
            });
          } else if (res.data.code === -1) {
            this.$Modal.fcError({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
              }
            });
            // this.gotoForget();
          }
        }).catch((err) => {
          throw err;
        });
      }, // 确认修改手机号
      checkIdentity() {
        if (this.status === 'checkUser') {
          // 用户名验证
          if (this.userName) {
            this.userGetPhone();
          } else {
            this.userNameError = true;
          }
        } else if (this.status === 'phoneByUsername') {
          this.checkPhoneformat();
          if (!this.phoneRetError && this.imgCode && this.messageCode) {
            // 通过手机号查所有用户名
            this.findAllUsername();
          } else {
            this.imgCodeError = !this.imgCode;
            this.messageCodeError = !this.messageCode;
          }
        } else if (this.status === 'checkYzm') {
          // 验证码验证
          if (this.type == 1) {
            if (this.imgCode && this.messageCode) {
              this.checkPhoneCode();
            }
            this.imgCodeError = !this.imgCode;
          } else if (this.type == 2) {
            if (this.messageCode) {
              this.checkPhoneCode();
            }
          }
          this.messageCodeError = !this.messageCode;
        } else if (this.status === 'confirmPassword') {
          this.confirmInfo();
        } else if (this.status === 'changePhone') {
          this.changePhoneInfo();
        } else if (this.status === 'userList') {
          this.backLogin();
        }
      }, // 下一步验证信息
      confirmInfo() {
        let pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}');
        this.passwordError = !pwdRegex.test(this.password);
        this.confirmPasswordError = !this.confirmPassword;
        if (this.password && this.password !== this.confirmPassword) {
          this.confirmPasswordError = true;
          // this.$Modal.fcWarning({
          //   title: '提示',
          //   mask: true,
          //   content: '两次输入密码不一致！',
          //   onOk: () => {}
          // });
        }
        if (pwdRegex.test(this.password) && (this.password === this.confirmPassword)) {
          this.changePassword();
        }
        // this.status = 'checkUser';
      }, // 确认修改密码
      changePhoneInfo() {
        if (this.newPhone && this.imgCode && this.newMessageCode) {
          this.checkChangePhone();
        }
        this.checkNewPhone();
        this.imgCodeError = !this.imgCode;
        this.newMessageCodeError = !this.newMessageCode;
      }, // 确认修改手机号
      findAllUsername() {
        let params = {
          phone: this.phoneRetrieve,
          verifyCode: this.messageCode
        };
        network.post(this.serviceId + '/p/c/get_name_byphone', params).then((res) => {
          if (res.data.code === 0) {
            this.status = 'userList';
            this.usernameList = res.data.data.names;
          } else if (res.data.code === -1) {
            this.$Modal.fcError({
              title: '提示',
              mask: true,
              content: res.data.message,
              onOk: () => {
              }
            });
          }
        }).catch((err) => {
          throw err;
        });
      }, // 手机号查找所有用户名
    },
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style scoped lang="less" type="text/less">
    #forgetPassword {
        width: 100%;
        height: 100%;
        .titleContent {
            height: 60px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            .title {
                width: 67%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .logo {
                    height: 38px;
                    cursor: pointer;
                    img {
                        margin-left: 25px;
                        height: 28px;
                        align-items: center;
                        // width: 242px;
                        // height: 38px;
                        // vertical-align: middle;
                    }
                    .changeTitle {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 20px;
                        color: #333;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
        }
        .loginBGM {
            /*background:#fff url(../assets/image/loginBg.png) no-repeat;*/
            background:#f8f8f8;
            background-size: cover;
            width: 100%;
            height: calc(100% - 97px);
            position: relative;
            min-width: 660px;
            display: flex;
            flex-direction: column;
            /*justify-content: center;*/
            align-items: center;
            .logo {
                position: absolute;
                top: -80px;
                left: 10%;
                width: 80%;
            }
            .typeStatus {
                padding: 20px;
                font-size: 20px;
                cursor: pointer;
                .typeAct {
                    color: #ff6a00;
                    font-weight: bold;
                }
            }
            /*整块内容*/
            .container {
                margin-top: 60px;
                width: 574px;
                max-height: 500px;
                position: relative;
                background-color: #fff;
                /*box-shadow:0 0 10px 0 rgba(172,172,172,0.3);*/
                .containerTitle {
                    margin-top: 10px;
                    text-align: center;
                    color: #000;
                    font-size: 24px;
                    font-weight: bold;
                }
                .forget {
                    height: 100%;
                    padding: 60px 130px;
                    .inputCont {
                        height: 95%;
                        overflow: auto;
                        margin-bottom: 15px;e
                        .titleTip {
                            font-size: 18px;
                            font-weight: bold;
                            color: #666;
                            letter-spacing: 2px;
                            margin-bottom: 8px;
                        }
                        .contentInput {
                            .item-module {
                                height: 38px;
                                display: flex;
                                align-items: center;
                                margin-bottom: 27px;
                                /*border-radius: 4px;*/
                                padding: 0 13px;
                                border: 1px solid #dadada;
                                background: #fff;
                                color: #575757;
                                font-size: 14px;
                                position: relative;
                                .imgIcon {
                                    width: 18px;
                                    margin-right: 10px;
                                }
                                input {
                                    height: 36px;
                                    width: 100%;
                                    border: none;
                                    background: #fff;
                                }
                                .yzmImg {
                                    width: 80px;
                                    margin-left: 5px;
                                }
                                .changeText {
                                    flex: none;
                                    width: 86px;
                                    margin-left: 5px;
                                    text-align: center;
                                    border-left: 1px solid #dadada;
                                    .verification {
                                        cursor: pointer;
                                    }
                                    .countDown {
                                        cursor: no-drop;
                                        font-size: 12px;
                                    }
                                }
                                .error-tip {
                                    position: absolute;
                                    left: 0;
                                    bottom: -23px;
                                    color: red;
                                    font-size: 12px;
                                }
                            }
                            .item-module.item-phone {
                                margin-bottom: 20px;
                                border: none;
                                background: transparent;
                            }
                            .usernameList {
                                list-style: none;
                                font-size: 14px;
                                margin-bottom: 27px;
                                padding-left: 24px;
                                li {
                                    margin: 5px 0;
                                }
                            }
                        }
                    }
                    .line {
                        width: 460px;
                        height: 1px;
                        background: #dadada;
                        margin: 36px 0 20px -60px;
                    }
                    .btnGroup {
                        display: flex;
                        align-items: center;
                        .backLogin {
                            cursor: pointer;
                        }
                        .btnBack {
                            cursor: pointer;
                        }
                        .btnConfirm {
                            /*background: url(../assets/image/login.png) no-repeat;*/
                            background-image: linear-gradient(to right, #ff6a00 , #ff6a00);
                            background-size: cover;
                            cursor: pointer;
                            width: 100%;
                            height: 38px;
                            line-height: 38px;
                            text-align: center;
                            margin-left: auto;
                            font-size: 14px;
                            color: #fff;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
        .bottomTip {
            width: 100%;
            height: 37px;
            position: fixed;
            bottom: 0;
            color: #797979;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            background-color: #fff;
            letter-spacing:1px;
            font-weight:400;
            color:rgba(154,154,154,1);
            line-height:17px;
        }
    }
    ::-webkit-input-placeholder { /* WebKit browsers */
        color: #dadada;
        font-size: 14px;

    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        font-size: 14px;
        color: #dadada;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        font-size: 14px;
        color: #dadada;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
        font-size: 14px;
        color: #dadada;
    }
</style>
