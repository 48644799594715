var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { background: "#F6F8FB" }, attrs: { id: "footers" } },
    [
      _vm._t("imgSlot", function () {
        return [_vm._m(0)]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "footer-container" }, [
        _c("div", { staticClass: "footer-body" }, [
          _c(
            "ul",
            { staticClass: "serverList" },
            _vm._l(_vm.bottomFooterList, function (item, index) {
              return _c("li", { staticClass: "serverTip" }, [
                _c("p", { staticClass: "server-title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "link-list" },
                  _vm._l(item.list, function (child, childIndex) {
                    return _c(
                      "li",
                      {
                        staticClass: "linkTip",
                        on: {
                          click: function ($event) {
                            return _vm.useClick(child)
                          },
                        },
                      },
                      [_vm._v(_vm._s(child.name))]
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "company-partner" }, [
      _c("div", { staticClass: "title" }, [
        _c("p", { staticClass: "main-title" }, [
          _vm._v("企业信赖的商业合作伙伴"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "sub-title" }, [
          _vm._v(
            "5年积累，39次版本迭代升级，服务于200多个企业客户，处理超过5亿笔交易订单"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "partnerImg-Cont" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/home/exhibition/partner.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }