<template>
  <div class="apiDocContainer">
    <menuCont :men-list="menList" :active-name="activeName" :open-names="openNames" @sideMenuSelect="sideMenuSelect"></menuCont>
    <!--<SideBarMenu class="docSideBar" :menuLists="menList" @on-menu-select="sideMenuSelect" />-->
    <!--<MobileSideBarMenu class="mobileDocSideBar" :menuLists="menList" ref="mobileDocSideBar" @on-menu-select="sideMenuSelect" />-->
    <paymentAccess v-if="isSDK" :sdkTitle="sdkTitle"></paymentAccess>
    <div v-show="!isSDK" class="markdownContainer" id="markdown">
      <vue-markdown v-if="markdownShow">{{ msg }}</vue-markdown>
    </div>
    <div v-show="!isSDK" class="anchorContainer">
      <Anchor v-if="anchorShow" show-ink :container="scrollCon">
        <!--<AnchorLink v-for="(item, index) in anchorLinkList" :key="index" :href="item.href" :title="item.title">-->
        <!--  <AnchorLink v-show="item.children && item.children.length > 0" v-for="(item1, index1) in item.children" :key="index1" :href="item1.href" :title="item1.title">-->
        <!--    <AnchorLink v-show="item1.children && item1.children.length > 0" v-for="(item2, index2) in item1.children" :key="index2" :href="item2.href" :title="item2.title">-->
        <!--      <AnchorLink v-show="item2.children && item2.children.length > 0" v-for="(item3, index3) in item2.children" :key="index3" :href="item3.href" :title="item3.title">-->
        <!--        <AnchorLink v-show="item3.children && item3.children.length > 0" v-for="(item4, index4) in item4.children" :key="index4" :href="item4.href" :title="item4.title">-->
        <!--        </AnchorLink>-->
        <!--      </AnchorLink>-->
        <!--    </AnchorLink>-->
        <!--  </AnchorLink>-->
        <!--</AnchorLink>-->
        <LoopAnchorlink :list="anchorLinkList"></LoopAnchorlink>
      </Anchor>
    </div>
  </div>
</template>

<script>
  import VueMarkdown from 'vue-markdown';
  import R3 from '@syman/burgeon-r3';
  // import SideBarMenu from './DocSideBar.vue';
  // import MobileSideBarMenu from './MobileDocSideBar.vue';
  import LoopAnchorlink from './loopAnchorlink.vue';
  import menuCont from './menuCont.vue';
  import paymentAccess from './sdkModule.vue';

  const { network } = R3;

  export default {
    data() {
      return {
        isSDK: false,
        sdkTitle: '',
        openNames: ['paymentDocuments', 'aggregatePayment'],
        msg: '',
        markdownShow: false, // 是否显示markdown文档内容
        anchorShow: false, // 是否显示锚点
        scrollCon: null, // 滚动容器
        anchorLinkList: [], // 锚点数组
        menList: [], // 菜单数组
        activeName: '',
        activeParent: '',
      };
    },
    name: 'ApiDocHome',
    components: {
      VueMarkdown,
      paymentAccess,
      // SideBarMenu,
      // MobileSideBarMenu,
      LoopAnchorlink,
      menuCont
    },
    watch: {},
    computed: {},
    created() {
      this.getDefaultMsg();
    },
    mounted() {
      const dom = document.getElementById('markdown');
      this.scrollCon = dom;
    },
    methods: {
      getDefaultMsg() {
        // network.get('/file/markdown.config.json').then((res) => {
        //   if (res.status === 200) {
        //     const data = res.data;
        //     this.menList = res.data;
        //     const url = `/file/${data[0].submenu[0].fileName}.md`;
        //     this.getDocAndAnchor(url, data[0].submenu[0].fileName);
        //   }
        // });
        network.get('/file/markdown.configCopy.json').then((res) => {
          if (res.status === 200) {
            const data = res.data;
            this.menList = res.data[0].submenu;
            let inZero = data[0].submenu[0];
            while (inZero.children) {
              inZero = inZero.children[0];
            }
            this.activeName = inZero.name;
            this.activeParent = inZero.parentName;
            const url = `/file/payFile/${inZero.name}.md`;
            this.getDocAndAnchor(url, inZero.name);
          }
        });
      }, // 获取默认的文档
      getDocAndAnchor(url, name) {
        const promise1 = new Promise((resolve, reject) => {
          this.getMarkdownInfo(url, resolve, reject);
        });
        const promise2 = new Promise((resolve, reject) => {
          this.getAnchorLinkInfo(name, resolve, reject);
        });
        Promise.all([promise1, promise2]).then(() => {
          this.markdownShow = true;
          this.$nextTick(() => {
            this.anchorShow = true;
          });
        });
      }, // 获取文档和锚点信息
      getMarkdownInfo(url, resolve, reject) {
        network.get(url).then((res) => {
          if (res.status === 200) {
            this.msg = '';
            this.msg = res.data;
            resolve();
          } else {
            reject();
          }
        });
      }, // 获取markdown文件
      getAnchorLinkInfo(name, resolve, reject) {
        const url = `/file/payFile/${name}.json`;
        network.get(url).then((res) => {
          if (res.status === 200) {
            this.anchorLinkList = res.data;
            resolve();
          } else {
            reject();
          }
        });
      }, // 获取锚点文件
      sideMenuSelect(name) {
        document.getElementById('cloudContent').scrollTop = 0;
        this.isSDK = false;
        this.markdownShow = false;
        this.anchorShow = false;
        if (name === 'paySDK') {
          this.sdkTitle = '支付服务';
          this.isSDK = true;
          return;
        }
        const url = `/file/payFile/${name}.md`;
        this.getDocAndAnchor(url, name);
        if (this.$refs.mobileDocSideBar) {
          this.$refs.mobileDocSideBar.closeMenu();
        }
      }, // 点击左侧切换文档时触发
    }
  };
</script>

<style lang="less">
  .apiDocContainer {
    height: calc(100% - 60px);
    display: flex;
    overflow: hidden;
    padding: 20px 8%;
    background: #fff;
    .ark-menu {
      z-index: 9;
    }
    .mobileDocSideBar {
      display: none;
    }
    .markdownContainer {
      height: 100%;
      overflow-y: auto;
      flex: 1;
      padding: 0 10px 0 20px;
      /*h3 {*/
      /*  padding: 10px 0;*/
      /*}*/
      /*pre {*/
      /*  display: block;*/
      /*  margin-bottom: 15px;*/
      /*  code {*/
      /*    display: block;*/
      /*    background: #F0F0F0;*/
      /*    padding: 1.25rem 1.5rem;*/
      /*    border-right: 4px;*/
      /*    overflow-x: auto;*/
      /*  }*/
      /*}*/
      /*blockquote {*/
      /*    p {*/
      /*      margin: 1.25rem 0;*/
      /*      padding: 0 0 0 1rem;*/
      /*      border-left: 4px solid #D2D2D2;*/
      /*    }*/
      /*}*/
      h1, h2, h3, h4, h5 {
        color: #323232;
        font-weight: 400;
      }

      h1, h2, h3, h4, h5 {
        /*font-family: Georgia, Palatino, serif;*/
      }
      h1, h2, h3, h4, h5, p , dl{
        margin-bottom: 6px;
        padding: 0;
      }
      h1 {
        font-size: 48px;
        line-height: 54px;
      }
      h2 {
        font-size: 36px;
        line-height: 42px;
      }
      h1, h2 {
        border-bottom: 1px solid #EFEAEA;
        padding-bottom: 10px;
      }
      h3 {
        font-size: 24px;
        line-height: 30px;
      }
      h4 {
        font-size: 21px;
        line-height: 26px;
      }
      h5 {
        font-size: 18px;
        line-height: 23px;
      }
      a {
        color: #0099ff;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
      }
      a:hover {
        text-decoration: none;
        color: #ff6600;
      }
      a:visited {
        /*color: purple;*/
      }
      ul, ol {
        padding: 0px;
        padding-left: 24px;
        margin: 0 0 6px 0;
      }
      li {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 24px;
        width: 100%;
        overflow-x: auto;
      }
      li p {
        width: 100%;
        overflow-x: auto;
        margin-bottom: 0;
      }
      p, ul, ol {
        font-size: 16px;
        line-height: 24px;
      }

      ol ol, ul ol {
        list-style-type: lower-roman;
      }
      ul li ul li {
        font-size: 12px;
      }

      img {
        margin: 10px 0;
      }
      /*pre {
          padding: 0px 24px;
          max-width: 800px;
          white-space: pre-wrap;
      }
      code {
          font-family: Consolas, Monaco, Andale Mono, monospace;
          line-height: 1.5;
          font-size: 13px;
      }*/

      code, pre {
        border-radius: 3px;
        background-color:#f7f7f7;
        color: inherit;
      }

      code {
        font-family: Consolas, Monaco, Andale Mono, monospace;
        margin: 0 2px;
      }

      pre {
        line-height: 1.7em;
        overflow: auto;
        padding: 6px 10px;
        border-left: 5px solid #FFD263;
        margin-bottom: 6px;
      }
      pre:last-child {
        margin-bottom: 0;
      }

      pre > code {
        border: 0;
        display: inline;
        max-width: initial;
        padding: 0;
        margin: 0;
        overflow: initial;
        line-height: inherit;
        font-size: .85em;
        white-space: pre;
        background: 0 0;

      }

      code {
        color: #666555;
      }


      /** markdown preview plus 对于代码块的处理有些问题, 所以使用统一的颜色 */
      /*code .keyword {
        color: #8959a8;
      }

      code .number {
        color: #f5871f;
      }

      code .comment {
        color: #998
      }*/

      aside {
        display: block;
        float: right;
        width: 390px;
      }
      blockquote {
        border-left:.5em solid #eee;
        padding: 0 0 0 2em;
        margin-left:0;
      }
      blockquote  cite {
        font-size:14px;
        line-height:20px;
        color:#bfbfbf;
      }
      blockquote cite:before {
        content: '\2014 \00A0';
      }

      blockquote p {
        color: #666;
        letter-spacing: 1px;
        font-size: 12px;
        margin-bottom: 10px;
      }
      hr {
        text-align: left;
        color: #999;
        height: 2px;
        padding: 0;
        margin: 16px 0;
        background-color: #e7e7e7;
        border: 0 none;
      }

      dl {
        padding: 0;
      }

      dl dt {
        padding: 10px 0;
        margin-top: 16px;
        font-size: 1em;
        font-style: italic;
        font-weight: bold;
      }

      dl dd {
        padding: 0 16px;
        margin-bottom: 16px;
      }

      dd {
        margin-left: 0;
      }

      /* Code below this line is copyright Twitter Inc. */

      button,
      input,
      select,
      textarea {
        font-size: 100%;
        margin: 0;
        vertical-align: baseline;
        *vertical-align: middle;
      }
      button, input {
        line-height: normal;
        *overflow: visible;
      }
      button::-moz-focus-inner, input::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
      button,
      input[type="button"],
      input[type="reset"],
      input[type="submit"] {
        cursor: pointer;
        -webkit-appearance: button;
      }
      input[type=checkbox], input[type=radio] {
        cursor: pointer;
      }
      /* override default chrome & firefox settings */
      input:not([type="image"]), textarea {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
      }

      input[type="search"] {
        -webkit-appearance: textfield;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
      }
      input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
      }
      label,
      input,
      select,
      textarea {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        margin-bottom: 18px;
      }
      input[type=checkbox], input[type=radio] {
        cursor: pointer;
        margin-bottom: 0;
      }
      input[type=text],
      input[type=password],
      textarea,
      select {
        display: inline-block;
        width: 210px;
        padding: 4px;
        font-size: 13px;
        font-weight: normal;
        line-height: 18px;
        height: 18px;
        color: #808080;
        border: 1px solid #ccc;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
      select, input[type=file] {
        height: 27px;
        line-height: 27px;
      }
      textarea {
        height: auto;
      }
      /* grey out placeholders */
      :-moz-placeholder {
        color: #bfbfbf;
      }
      ::-webkit-input-placeholder {
        color: #bfbfbf;
      }
      input[type=text],
      input[type=password],
      select,
      textarea {
        -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
        -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
        transition: border linear 0.2s, box-shadow linear 0.2s;
        -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      }
      input[type=text]:focus, input[type=password]:focus, textarea:focus {
        outline: none;
        border-color: rgba(82, 168, 236, 0.8);
        -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
        -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
      }
      /* buttons */
      button {
        display: inline-block;
        padding: 4px 14px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 13px;
        line-height: 18px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        background-color: #0064cd;
        background-repeat: repeat-x;
        background-image: -khtml-gradient(linear, left top, left bottom, from(#049cdb), to(#0064cd));
        background-image: -moz-linear-gradient(top, #049cdb, #0064cd);
        background-image: -ms-linear-gradient(top, #049cdb, #0064cd);
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #049cdb), color-stop(100%, #0064cd));
        background-image: -webkit-linear-gradient(top, #049cdb, #0064cd);
        background-image: -o-linear-gradient(top, #049cdb, #0064cd);
        background-image: linear-gradient(top, #049cdb, #0064cd);
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        border: 1px solid #004b9a;
        border-bottom-color: #003f81;
        -webkit-transition: 0.1s linear all;
        -moz-transition: 0.1s linear all;
        transition: 0.1s linear all;
        border-color: #0064cd #0064cd #003f81;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
      }
      button:hover {
        color: #fff;
        background-position: 0 -15px;
        text-decoration: none;
      }
      button:active {
        -webkit-box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
      }
      button::-moz-focus-inner {
        padding: 0;
        border: 0;
      }
      .table {
        width: 100%;
        overflow-x: auto;
      }
      ul .table {
        /*width: calc(100% - 24px);*/
      }
      table {
        *border-collapse: collapse; /* IE7 and lower */
        border-spacing: 0;
        width: 100%;
      }
      table {
        border: solid #ccc 1px;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        margin-bottom: 6px;
        font-size: 12px;
        letter-spacing: 0;
        /*-webkit-box-shadow: 0 1px 1px #ccc;
        -moz-box-shadow: 0 1px 1px #ccc;
        box-shadow: 0 1px 1px #ccc;   */
      }
      table tr:hover {
        background: #fbf8e9;
        -o-transition: all 0.1s ease-in-out;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -ms-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
      }
      table td, .table th {
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        padding: 6px;
        text-align: left;
      }

      table th {
        background-color: #e8edf7;
        /*background-image: -webkit-gradient(linear, left top, left bottom, from(#ebf3fc), to(#dce9f9));*/
        /*background-image: -webkit-linear-gradient(top, #ebf3fc, #dce9f9);*/
        /*background-image:    -moz-linear-gradient(top, #ebf3fc, #dce9f9);*/
        /*background-image:     -ms-linear-gradient(top, #ebf3fc, #dce9f9);*/
        /*background-image:      -o-linear-gradient(top, #ebf3fc, #dce9f9);*/
        /*background-image:         linear-gradient(top, #ebf3fc, #dce9f9);*/
        /*-webkit-box-shadow: 0 1px 0 rgba(255,255,255,.8) inset;
        -moz-box-shadow:0 1px 0 rgba(255,255,255,.8) inset;
        box-shadow: 0 1px 0 rgba(255,255,255,.8) inset;*/
        border-top: none;
        text-shadow: 0 1px 0 rgba(255,255,255,.5);
        padding: 5px;
      }

      table td:first-child, table th:first-child {
        border-left: none;
      }

      table th:first-child {
        -moz-border-radius: 6px 0 0 0;
        -webkit-border-radius: 6px 0 0 0;
        border-radius: 6px 0 0 0;
      }
      table th:last-child {
        -moz-border-radius: 0 6px 0 0;
        -webkit-border-radius: 0 6px 0 0;
        border-radius: 0 6px 0 0;
      }
      table th:only-child{
        -moz-border-radius: 6px 6px 0 0;
        -webkit-border-radius: 6px 6px 0 0;
        border-radius: 6px 6px 0 0;
      }
      table tr:last-child td:first-child {
        -moz-border-radius: 0 0 0 6px;
        -webkit-border-radius: 0 0 0 6px;
        border-radius: 0 0 0 6px;
      }
      table tr:last-child td:last-child {
        -moz-border-radius: 0 0 6px 0;
        -webkit-border-radius: 0 0 6px 0;
        border-radius: 0 0 6px 0;
      }
      table tr:nth-child(even) {
        background: #f2f5f7
      }
      table tr:nth-child(odd) {
        background: #FFF
      }
    }
    .anchorContainer {
      width: 200px;
      padding: 30px 30px 30px 10px;
    }
    @media screen and (max-width: 750px) {
      .docSideBar {
        display: none;
      }
      .anchorContainer {
        display: none;
      }
      .markdownContainer {
        margin-top: 20px;
      }
      .mobileDocSideBar {
        position: fixed;
        display: block;
        height: 40px;
        width: 100%;
        z-index: 100;
        top: 60px;
        .ark-menu {
          border-bottom: 1px solid #d8d8d8;
        }
        ul, li {
          width: 100% !important;
          background-color: #fff;
        }
      }
    }
  }
</style>
