var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "registerContent" }, [
    _c("div", { staticClass: "titleContent" }, [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "logo", on: { click: _vm.logoClick } }, [
          _c("img", {
            attrs: { src: require("../assets/home/exhibition/logo.png") },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [
          _c("span", [_vm._v("已有账户，")]),
          _vm._v(" "),
          _c("span", { staticClass: "toLogin", on: { click: _vm.toLogin } }, [
            _vm._v("直接登录"),
          ]),
          _vm._v(" "),
          _c("i", {
            staticClass: "iconfont iconbj_right",
            on: { click: _vm.toLogin },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "registerCompanyInfo" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "registerForm" },
        [
          _c(
            "Form",
            {
              ref: "formValidate",
              staticClass: "contentForm",
              attrs: {
                "label-position": "left",
                model: _vm.formValidate,
                rules: _vm.ruleValidate,
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "公司名称:", prop: "companyName" } },
                [
                  _c(
                    "div",
                    { staticClass: "formRightFlex" },
                    [
                      _c("Input", {
                        attrs: { clearable: "", placeholder: "请输入名称" },
                        model: {
                          value: _vm.formValidate.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "companyName", $$v)
                          },
                          expression: "formValidate.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "FormItem",
                { attrs: { label: "登录用户名:", prop: "userName" } },
                [
                  _c(
                    "div",
                    { staticClass: "formRightFlex" },
                    [
                      _c("Input", {
                        attrs: { clearable: "", placeholder: "请输入用户名" },
                        model: {
                          value: _vm.formValidate.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "userName", $$v)
                          },
                          expression: "formValidate.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "FormItem",
                { attrs: { label: "登录密码:", prop: "password" } },
                [
                  _c(
                    "div",
                    { staticClass: "password" },
                    [
                      _c("Input", {
                        attrs: {
                          autocomplete: "new-password",
                          type: _vm.isPasswordShow ? "text" : "password",
                          placeholder: "请输入密码",
                        },
                        model: {
                          value: _vm.formValidate.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "password", $$v)
                          },
                          expression: "formValidate.password",
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isPasswordShow,
                            expression: "!isPasswordShow",
                          },
                        ],
                        staticClass: "iconfont iconbj_see",
                        on: { click: _vm.editPasswordSee },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPasswordShow,
                            expression: "isPasswordShow",
                          },
                        ],
                        staticClass: "iconfont iconbj_nosee",
                        on: { click: _vm.editPasswordSee },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "FormItem",
                { attrs: { label: "真实姓名:", prop: "realName" } },
                [
                  _c(
                    "div",
                    { staticClass: "formRightFlex" },
                    [
                      _c("Input", {
                        attrs: { clearable: "", placeholder: "请输入真实姓名" },
                        model: {
                          value: _vm.formValidate.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "realName", $$v)
                          },
                          expression: "formValidate.realName",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("FormItem", { attrs: { label: "联系手机:", prop: "phone" } }, [
                _c(
                  "div",
                  { staticClass: "formRightFlex" },
                  [
                    _c("Input", {
                      attrs: { clearable: "", placeholder: "请输入手机号" },
                      model: {
                        value: _vm.formValidate.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "phone", $$v)
                        },
                        expression: "formValidate.phone",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "FormItem",
                {
                  staticClass: "noteCode",
                  attrs: { label: "短信验证:", prop: "inputCode" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "formRightFlex noteCodeRight" },
                    [
                      _c("Input", {
                        attrs: { placeholder: "请输入短信验证码" },
                        model: {
                          value: _vm.formValidate.inputCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "inputCode", $$v)
                          },
                          expression: "formValidate.inputCode",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "changeText" }, [
                        !_vm.loadingCode
                          ? _c(
                              "button",
                              {
                                staticClass: "verification",
                                attrs: { type: "button" },
                                on: { click: _vm.getVerifyCode },
                              },
                              [
                                _vm._v(
                                  "\n                点击获取\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loadingCode
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.countDown) + "秒重新获取"),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("FormItem", { attrs: { label: "邮箱:", prop: "email" } }, [
                _c(
                  "div",
                  {
                    staticClass: "formRightFlex",
                    staticStyle: { display: "flex" },
                  },
                  [
                    _c("Input", {
                      attrs: { clearable: "", placeholder: "请输入邮箱" },
                      model: {
                        value: _vm.formValidate.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "email", $$v)
                        },
                        expression: "formValidate.email",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "FormItem",
                { attrs: { label: "短信签名:", prop: "signName" } },
                [
                  _c(
                    "div",
                    { staticClass: "formRightFlex" },
                    [
                      _c("Input", {
                        attrs: {
                          clearable: "",
                          wordLimit: "",
                          placeholder: "请输入短信签名",
                          maxlength: 20,
                        },
                        model: {
                          value: _vm.formValidate.signName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "signName", $$v)
                          },
                          expression: "formValidate.signName",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "FormItem",
                {
                  staticStyle: {
                    "margin-bottom": "16px",
                    "margin-top": "-6px",
                  },
                  attrs: { label: "", prop: "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "formRightFlex",
                      staticStyle: { width: "350px" },
                    },
                    [
                      _c("div", { staticClass: "FormItem-tip" }, [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "1、建议使用简短品牌名，不支持带“测试”字样的签名；"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "2、无须添加【】、0、·符号，签名发送会自带【】符号"
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Button",
            {
              staticClass: "bottomButton",
              attrs: { type: "primary" },
              on: { click: _vm.nextStep },
            },
            [_vm._v("用户注册")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.showModel
      ? _c("div", { staticClass: "modalVerify" }, [
          _c("div", { staticClass: "modalContent" }, [
            _c("div", { staticClass: "hearTitle" }, [_vm._v("输入验证码")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "verifyCodeVontent" },
              [
                _c("span", { staticClass: "name" }, [_vm._v("验证码")]),
                _vm._v(" "),
                _c("Input", {
                  staticClass: "Arkinput",
                  attrs: { placeholder: "请输入验证码" },
                  model: {
                    value: _vm.imgCode,
                    callback: function ($$v) {
                      _vm.imgCode = $$v
                    },
                    expression: "imgCode",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "footBtn" }, [
              _c(
                "span",
                {
                  staticClass: "theme-sub-btn",
                  on: { click: _vm.cancelCheck },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "theme-main-btn", on: { click: _vm.checkCode } },
                [_vm._v("确认")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "registerCompanyTitle" }, [
      _c("span", { staticClass: "companyTitle act" }, [_vm._v("公司注册")]),
      _vm._v(" "),
      _c("b", { staticClass: "sj" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }