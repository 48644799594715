<template>
    <div id="leftNavigator">
        <div class="navImgLogo">
            <img src="../../assets/image/leftDropLogo.png" alt="">
            <span>通用服务</span>
        </div>
        <VerticalMenu class="verticalMenu"></VerticalMenu>
    </div>
</template>

<script>
  import R3 from '@syman/burgeon-r3';

  const { VerticalMenu } = R3.components;

  export default {
    name: 'NavigatorPanel',
    components: {
      VerticalMenu
    },
    data() {
      return {
      };
    },
    computed: {
    },
    methods: {
    },
    watch: {
    },
    mounted() {
    },
    created() {

    },
  };
</script>

<style lang="less">
    #leftNavigator {
        height: 100%;
        background-color: #3D3C49;
        transition: width .5s ease-in-out;
        width: 190px;
        overflow-y: auto;
        overflow-x: hidden;
        .navImgLogo {
            width: 190px;
            padding: 20px 0;
            text-align: center;
            >img {
                vertical-align: middle;
                /*width: 76px;*/
                height: 30px;
            }
            >span {
                vertical-align: middle;
                font-size: 14px;
                color: #fff;
                margin-left: 10px;
            }
        }
        .verticalMenu {
            width: 190px;
        }
    }
    #leftNavigator.close {
        width: 0;
        transition: width .5s ease-in-out;
    }
</style>
