<template>
    <div id="burgeonCloud" class="base-color">
        <div class="website-banner" @click="getRoute($event)" @mouseenter="bannerMouseenter" @mouseleave="bannerMouseleave">
        <!--<div class="website-banner" @mouseenter="bannerMouseenter" @mouseleave="bannerMouseleave">-->
            <Carousel :autoplay="autoplay" :autoplay-speed="4000" loop arrow="never" v-model="carIndex" class="auto-adv">
                <CarouselItem>
                    <div class="demo-carousel">
                        <img src="../../assets/home/exhibition/payBanner.png" alt="">
                        <div class="proIntroduce">
                            <div class="titleAdv">让支付变得更简单</div>
                            <div class="infoAdv">
                                <p>支付是每一家企业内部的虚拟支付团队</p>
                                <p>帮助企业快速高效的搭建自己的支付系统</p>
                            </div>
                            <div class="freeUse">
                                <!--<router-link class="theme-main-btn" to="/cloudContent/payCloud" tag="span">了解详情</router-link>-->
                                <span class="theme-main-btn-big">了解详情</span>
                            </div>
                        </div>
                    </div>
                </CarouselItem>
                <CarouselItem>
                    <div class="demo-carousel">
                        <img src="../../assets/home/exhibition/smsBanner.png" alt="">
                        <div class="proIntroduce">
                            <div class="titleAdv">安全调用  秒级触达</div>
                            <div class="infoAdv">
                                <p>调用API或用群发助手，即可发送验证码、通知类和营销短信</p>
                                <p>国际/港澳台短信覆盖200多个国家和地区，安全稳定，广受出海企业选用。</p>
                            </div>
                            <div class="freeUse">
                                <!--<router-link class="theme-main-btn" to="/cloudContent/smsCloud" tag="span">了解详情</router-link>-->
                                <span class="theme-main-btn-big">了解详情</span>
                            </div>
                        </div>
                    </div>
                </CarouselItem>
                <CarouselItem>
                    <div class="demo-carousel">
                        <img src="../../assets/home/exhibition/invBanner.png" alt="">
                        <div class="proIntroduce">
                            <div class="titleAdv">领先的多场景发票服务</div>
                            <div class="infoAdv">
                                <p>多场景发票服务系统，让销方企业开票更便捷，让购方企业管理发票更高效</p>
                                <p>支持企业打通自有业务系统进行开票及发票管理</p>
                            </div>
                            <div class="freeUse">
                            <!--    &lt;!&ndash;<router-link class="theme-main-btn" to="/cloudContent/invCloud" tag="span">了解详情</router-link>&ndash;&gt;-->
                                <span class="theme-main-btn-big">了解详情</span>
                            </div>
                        </div>
                    </div>
                </CarouselItem>
            </Carousel>
        </div>
        <div class="produce-adv">
            <div v-for="(item, index) in advList" :key="index" class="adv-content pay-adv">
                <div class="adv-title">
                    <div class="nominate">
                        <p class="main">{{ item.title }}</p>
                        <!--<p class="sub">{{ item.sub }}</p>-->
                    </div>
                    <div class="line"></div>
                    <!--<div class="unifiedHeadline">-->
                    <!--    <span class="midShadow left"></span>-->
                    <!--    <span class="midText">产品优势</span>-->
                    <!--    <span class="midShadow right"></span>-->
                    <!--</div>-->
                </div>
                <ul class="adv-list">
                    <li v-for="(child, childIndex) in item.product" :key="childIndex"
                        class="item" :class="'list' + item.product.length"
                        @mouseover="bgImgAct(child)" @mouseout="bgImgRemove(child)">
                        <img class="item-img" :src="require('../../assets/home/exhibition/' + child.img + '.png')" alt="">
                        <div class="item-introduce">
                            <p class="main-introduce">{{ child.mainInfo }}</p>
                            <p class="sub-introduce">{{ child.subInfo }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'BurgeonCloud',
    components: {

    },
    data() {
      return {
        autoplay: true,
        carIndex: 0,
        advList: [
          {
            title: '支付服务',
            sub: 'Payment Services',
            product: [
              {
                img: 'payProductOne',
                mainInfo: '强大的技术能力',
                subInfo: '一次接入等于全部主流支付渠道，方便快捷',
              },
              {
                img: 'payProductTwo',
                mainInfo: '专业的全流程服务',
                subInfo: '支持个性化定制，支付团队全程跟进',
              },
              {
                img: 'payProductThree',
                mainInfo: '私有化部署方案',
                subInfo: '支付提供支付私有化部署方案',
              },
            ]
          },
          {
            title: '短信服务',
            sub: 'SMS Service',
            product: [
              {
                img: 'smsProductOne',
                mainInfo: '强大的高并发处理',
                subInfo: '设置多个发送队列，可确保短信发送请求被实时接收及处理',
              },
              {
                img: 'smsProductTwo',
                mainInfo: 'API高安全调用',
                subInfo: '基于阿里云账户及RAM授权体系对发送API进行鉴权',
              },
              {
                img: 'smsProductThree',
                mainInfo: '免运维简单集成',
                subInfo: '无需自行搭建消息服务，免运维',
              },
              {
                img: 'smsProductFour',
                mainInfo: '设阈值可控发送量',
                subInfo: '可设置每日发送量阈值防止意外损耗大量资源',
              },
            ]
          },
          {
            title: '发票服务',
            sub: 'Invoice Service',
            product: [
              {
                img: 'invProductOne',
                mainInfo: '多种开票方式',
                subInfo: '网页开票、手机开票、批量开票、扫码开票、审核开票等适配多场景的开票方式。',
              },
              {
                img: 'invProductTwo',
                mainInfo: '多行业覆盖',
                subInfo: '已对接多行业主流系统服务商，可为不同行业提供个性化开票解决方案。',
              },
              {
                img: 'invProductThree',
                mainInfo: '能力开放',
                subInfo: '提供接口，支持企业打通自有业务系统进行开票及发票管理。',
              },
            ]
          },
        ]
      };
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
      bannerMouseenter() {
        this.autoplay = false;
      },
      bannerMouseleave() {
        this.autoplay = true;
      },
      bgImgAct(item) {
        item.img += 'Act';
      },
      bgImgRemove(item) {
        item.img = item.img.slice(0, -3);
      },
      getRoute(e) {
        if (e.target.tagName === 'SPAN'
          && e.target.className === 'theme-main-btn-big'
          && e.target.innerHTML === '了解详情'
        ) {
          let routeList = ['/cloudContent/payCloud', '/cloudContent/smsCloud', '/cloudContent/invCloud'];
          this.$router.push(routeList[this.carIndex]);
        }
      }
    },
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less">
    @import "../../../static/theme/theme";

    #burgeonCloud {
        background: #fff;
    }

    @media screen and (min-width: 768px) {
        #burgeonCloud {
            width: 100%;
            min-width: 1100px;
            .website-banner {
                /*width: 1100px;*/
                .auto-adv {
                    width: 100%;
                    font-size: 0;
                    .demo-carousel {
                        width: 100%;
                        position: relative;
                        text-align: right;
                        img {
                            width: 100%;
                            max-height: 400px;
                            min-height: 320px;
                        }
                        .proIntroduce {
                            text-align: left;
                            position: absolute;
                            width: 1100px;
                            margin: auto;
                            top: 100px;
                            left: 0;
                            right: 0;
                            .titleAdv {
                                font-size: 36px;
                                font-weight: bold;
                                margin-bottom: 15px;
                                color: #444040;
                            }
                            .infoAdv {
                                font-size: 14px;
                                color: #949191;
                                >p {
                                    max-width: 480px;
                                }
                            }
                            .freeUse {
                                margin-top: 24px;
                            }
                        }
                    }
                }
                .auto-adv>.ark-carousel-dots-inside {
                    width: 1100px;
                    left: 0;
                    right: 0;
                    /*top: 290px;*/
                    margin: 0 auto;
                    bottom: 8%;
                    text-align: left;
                }
                .auto-adv>.ark-carousel-dots li {
                    margin: 0 5px;
                }
                .auto-adv>.ark-carousel-dots li button {
                    width: 45px;
                    height: 4px;
                    /*background: #FD6442;*/
                }
            }

            .produce-adv {
                width: 1100px;
                margin: auto;
                padding: 40px 0;
                .adv-content {
                    padding: 40px 0;
                    .adv-title {
                        text-align: center;
                        .nominate {
                            .main {
                                color: @theme-word-main-color;
                                font-size: 30px;
                            }
                            .sub {
                                color: @theme-word-sub-color;
                                font-size: 16px;
                            }
                        }
                        .line {
                            margin: 10px auto;
                            width: 102px;
                            height: 4px;
                            background-color: @theme-color;
                        }
                    }
                    .adv-list {
                        display: flex;
                        justify-content: space-between;
                        >.item {
                            text-align: center;
                            .item-img {
                                margin-bottom: 16px;
                            }
                            .item-introduce {
                                padding: 0 30px;
                                .main-introduce {
                                    color: @theme-word-main-color;
                                    font-size: 18px;
                                    margin-bottom: 5px;
                                }
                                .main-introduce.act {
                                    color: @theme-color;
                                }
                                .sub-introduce {
                                    color: @theme-word-sub-color;
                                    font-size: 14px;
                                }
                            }
                        }
                        >.item.list3 {
                            .item-img {
                                width: 190px;
                                /*height: 230px;*/
                            }
                            .item-introduce {
                                max-width: 300px;
                            }
                        }
                        >.item.list4 {
                            .item-img {
                                width: 190px;
                                height: 190px;
                            }
                            .item-introduce {
                                max-width: 250px;
                            }
                        }
                        >.item:hover {
                            .main-introduce {
                                color: #FD6442;
                            }
                        }
                    }
                }
            }

        }
    }

    @media screen and (max-width: 767px) {
        #burgeonCloud {
            width: 100%;
            .website-banner {
                .auto-adv {
                    width: 100%;
                    font-size: 0;
                    .demo-carousel {
                        width: 100%;
                        position: relative;
                        text-align: right;
                        img {
                            width: 100%;
                            max-height: 550px;
                            min-height: 300px;
                        }
                        .proIntroduce {
                            text-align: left;
                            position: absolute;
                            width: 1100px;
                            margin: auto;
                            top: 33%;
                            left: 0;
                            right: 0;
                            .titleAdv {
                                font-size: 36px;
                                font-weight: bold;
                                margin-bottom: 15px;
                                color: #444040;
                            }
                            .infoAdv {
                                font-size: 14px;
                                color: #949191;
                            }
                            .freeUse {
                                margin-top: 24px;
                            }
                        }
                    }
                }
                .auto-adv>.ark-carousel-dots-inside {
                    /*width: 1100px;*/
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: left;
                }
                .auto-adv>.ark-carousel-dots li {
                    margin: 0 5px;
                }
                .auto-adv>.ark-carousel-dots li button {
                    width: 45px;
                    height: 4px;
                    background: #FD6442;
                }
            }

            .produce-adv {
                margin: auto;
                padding: 40px 0;
                .adv-content {
                    padding: 40px 0;
                    .adv-title {
                        text-align: center;
                        .nominate {
                            .main {
                                color: @theme-word-main-color;
                                font-size: 30px;
                            }
                            .sub {
                                color: @theme-word-sub-color;
                                font-size: 16px;
                            }
                        }
                        .line {
                            margin: 10px auto;
                            width: 102px;
                            height: 4px;
                            background-color: @theme-color;
                        }
                    }
                    .adv-list {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: column;
                        >.item {
                            width: 300px;
                            margin-bottom: 20px;
                            text-align: center;
                            .item-img {
                                margin-bottom: 16px;
                            }
                            .item-introduce {
                                .main-introduce {
                                    color: @theme-word-main-color;
                                    font-size: 18px;
                                }
                                .main-introduce.act {
                                    color: @theme-color;
                                }
                                .sub-introduce {
                                    color: @theme-word-sub-color;
                                    font-size: 14px;
                                }
                            }
                        }
                        >.item.list3 {
                            .item-img {
                                width: 190px;
                                /*height: 230px;*/
                            }
                            .item-introduce {
                                max-width: 300px;
                            }
                        }
                        >.item.list4 {
                            .item-img {
                                width: 190px;
                                height: 190px;
                            }
                            .item-introduce {
                                max-width: 250px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
