var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paymentAccess" },
    _vm._l(_vm.dataList, function (item, index) {
      return item.title === _vm.sdkTitle
        ? _c("div", { staticClass: "data" }, [
            item.list.length > 0
              ? _c(
                  "div",
                  { staticClass: "block" },
                  _vm._l(item.list, function (i, c) {
                    return _c("div", { staticClass: "blockmes" }, [
                      _c("div", { staticClass: "blockTitle" }, [
                        _vm._v(_vm._s(item.blockTitle)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("div", { staticClass: "date" }, [
                            _vm._v(_vm._s(i.date)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "downloadSDK",
                              attrs: { href: i.url },
                            },
                            [
                              _c("div", { staticClass: "edition" }, [
                                _vm._v(_vm._s(i.edition)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "paymes" }, [
                                _vm._v(_vm._s(i.paymes)),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "iconfont iconmd-download downloadFile",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "gitblock",
                              attrs: { target: "_blank", href: i.githubUrl },
                            },
                            [
                              _c("img", {
                                staticClass: "gitIcon",
                                attrs: {
                                  src:
                                    i.iconText === "GitHub"
                                      ? require("../../../assets/image/gitIcon.png")
                                      : require("../../../assets/image/giteeIcon.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "iconText" }, [
                                _vm._v(_vm._s(i.iconText)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }