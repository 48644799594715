<template>
  <div class="interfaceDoc">
    <div v-for="(item, index) in dataList" :key="index" class="data">
      <div class="title">
        <div class="tab"></div>
        <div class="visitText">{{ item.title }}</div>
      </div>
      <div
        v-for="(i, cindex) in dataList[index].list"
        :key="cindex"
        class="block"
        @click="tolink(i.type)"
      >
        <div class="dateTitle">{{ i.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dataList: [
          {
            title: '支付服务',
            list: [
              { text: '支付服务文档', type: 1 }
            ],
          },
          {
            title: '短信服务',
            list: [
              // { text: '短信定价说明', type: 3 },
              { text: '短信接口文档', type: 4 },
            ],
          },
          {
            title: '发票服务',
            list: [
              { text: '发票服务文档', type: 5 },
            ],
          },
        ],
      };
    },
    methods: {
      tolink(type) {
        if (type == 1) {
          this.$router.push('/cloudContent/apiDocHome');
        } else if (type == 2) {
          // window.open(
          //   'http://syman-pay.oss-cn-shanghai.aliyuncs.com/file/%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf'
          // );
          // window.open(
          //   'http://knowledge.ark.burgeononline.com/repository#/entryComponents/17/282347/2/1796?isShowHeader=true'
          // );
        } else if (type == 3) {
          this.$router.push('/cloudContent/smsPrice');
        } else if (type == 4) {
          this.$router.push('/cloudContent/smsApiDocHome');
        } else if (type == 5) {
          this.$router.push('/cloudContent/invApiDocHome');
        }
      },
    },
  };
</script>
<style lang="less">
body {
  background-color: #eee !important;
}
</style>

<style scoped lang="less">
.interfaceDoc {
  /*width: 831px;*/
  padding-bottom: 80px;
  //   height: 100%;
  margin: auto;
  // padding: 30px 30px 30px 28px;
  /*min-width: 1024px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f2f5;
}
.title {
  // overflow: hidden;
  display: flex;
  padding-bottom: 12px;
  padding-top: 30px;
}
.tab {
  width: 4px;
  height: 15px;
  background: #fd8368;
  // margin:0 0 0 20px;
}
.visitText {
  margin: 0 0 0 6px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 15px;
}
.platform {
  width: 88px;
  height: 32px;
  background: #f6f8fb;
}
.platformText {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.data {
  cursor: pointer;
  width: 65%;
}
.data :hover {
  color: #fd6442;
}
.dateTitle {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.block {
  margin-top: 15px;
  height: 109px;
  background: #f9f9f9;
  box-shadow: 6px 3px 6px 0px rgba(150, 195, 198, 0.24);
  border-radius: 3px;
}
</style>
