import Vue from 'vue';
import arkUI from '@syman/ark-ui';
import R3 from '@syman/burgeon-r3';
// 企业Logo、Banner配置信息
import axios from 'axios';
import filterUrlForNetworkScript from './static/js/filterUrlForNetwork';
import enterpriseLogo from './src/assets/image/logo.png';
import enterpriseBanner from './src/assets/image/banner.png';
import './static/theme/theme.less';
import Login from './src/component/Login.vue';
import WelcomePage from './src/component/WelcomePage.vue';
import projectRouterConfig from './src/config/router.config';
import customizedPageConfig from './src/config/customized.page.config';
import customizedModalConfig from './src/config/customized.modal.config';
import './static/theme/custom.less';// 主题文件
import '@syman/ark-ui/dist/styles/ark-ui.css';
import '@syman/burgeon-r3/r3.publish/r3.min.css';
import '@syman/ark-ui/dist/styles/bjIconfonts/iconfont.css';
import appLayout from './src/config/appLayout';

Vue.config.devtools = true;
Vue.use(arkUI);
// if (process.env.NODE_ENV === 'development') {
//   axios.interceptors.request.use(
//     (config) => {
//       // if (window.ProjectConfig.enableGateWay && !config.url.includes('get_service_id') && !config.url.includes('pay-app-admin') && !config.url.includes('file')) {
//       //   config.url = `/pay-app-admin${config.url}`;
//       // }
//       if (window.ProjectConfig.enableGateWay && !config.url.includes('get_service_id') && !config.url.includes('ad-app') && !config.url.includes('file')) {
//         config.url = `/ad-app${config.url}`;
//       }
//       return config;
//     }
//   );
// }
// axios.interceptors.response.use(
//   (response) => {
//     if (response.config.url.includes('hello') && response.data.code == 0) {
//
//     }
//     return response;
//   }
// );
window.addEventListener('gatewayReady', () => {
  if (window.location.pathname.length < 2) {
    R3.router.push({ path: '/CUSTOMIZED/HOMEPAGE/2287' });
  }
});


// const R3 = window.R3.default;
R3.launchApplication({
  image: {
    enterpriseLogo,
    enterpriseBanner,
  },
  globalComponent: {
    Login,
    WelcomePage
  },
  appLayout,
  externalModules: customizedPageConfig, // 自定义界面
  externalModals: customizedModalConfig, // 自定义弹框
  Version: '1.4', // 版本号
  layoutDirection: false, // 默认是false ,水平排版 ，true 是垂直排版
  enableGateWay: true, // 网关是否打开,
  specifiedGlobalGateWay: 'auth-app-admin',
  // ignoreGateWayPattern: [/\/jflow\/*/g, /\/api\/*/g], // 框架默认禁用的网关逻辑的正则模式匹配
  enableJflow: false, // 默认禁用JFlow插件功能
  // jflowRequestDomain: 'http://jflow-shangfei.dev.burgeononline.com/', // jflow后台地址（商飞）
  customizeWaterMark: {
    // submit: { text: '测试水印', color: '#e80000' },
  }, // 启用外部自定义水印配置,
  filterUrlForNetwork: {// 过滤不需要用框架报错提示信息的接口请求
    configPage: {},
    customizePage: ['REGISTER'], // 自定义界面(Array),数组内包含的为不需要框架报错提示框的自定义界面(FUNCTIONPERMISSION：自定义界面标识),此配置针对的为自定义界面内的所有接口
  },
  filterUrlForNetworkScript, // 导入的filterUrlForNetworkScript脚本文件
  isItemTableNewValidation: true, // 是否允许子表新增有必填项时，输入值才触发子表必填项校验，不输入值则只校验主表
  isCommonTable: false, // 是否开启普通表格，默认关闭
  projectRoutes: projectRouterConfig, // 项目自定义路由，一般情况下用不到此配置
  touristRoute: '/cloudContent', // 项目未登录默认启动的页面
  quietRoutes: ['/Register', '/password', '/ForgetPassword', '/SeeBoard',
    '/cloudContent', '/cloudContent/burgeonCloud', '/headBar', '/pictureVerCode',
    '/cloudContent/payCloud', '/cloudContent/smsCloud', '/cloudContent/invCloud',
    '/cloudContent/apiDocHome', '/cloudContent/smsApiDocHome', '/cloudContent/invApiDocHome',
    '/cloudContent/interfaceDoc', '/cloudContent/paymentAccess',
    '/cloudContent/smsPrice', '/allowAgreement'], // 外置路由
});
