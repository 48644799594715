<template>
  <div class="head-bar">
    <div class="logo-operation">
      <div ref="titleDrop" class="show-leftMenu" @mouseenter="changeLeftMenu">
        <Icon type="md-menu" />
      </div>
      <img class="title-logo" @click="jumpToCloud" src="../../assets/image/titleLogo.png" alt="">
      <div id="noOpenAnimation" class="noOpen">你还没有开通服呦，赶快去开通吧！</div>
    </div>
    <ul class="title-rightList">
      <li>
        <!--<router-link to="/cloudContent/burgeonCloud" tag="span">官网</router-link>-->
        <a v-if="SELF_ENV === 'pro'" href="https://burgeonyun.com/cloudContent/burgeonCloud">官网</a>
        <a v-else href="http://console.account.oneretail.cn/cloudContent/burgeonCloud">官网</a>
      </li>
      <!--<li>-->
      <!--  云产品-->
      <!--</li>-->
      <li class="userContent" >
        <Dropdown @on-click="uesrDrop">
          <!--<i class="iconfont iconmd-person"></i>-->
          <span class="ename">{{ ename }}</span>
          <DropdownMenu slot="list">
            <DropdownItem name="changePass">修改密码</DropdownItem>
            <DropdownItem name="changePhone">修改手机号</DropdownItem>
            <DropdownItem name="out">退出登录</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <!--<ul class="dropdowncontent" v-show='showDrop'>-->
        <!--      <li @click="quit"><a>退出登录</a></li>-->
        <!--</ul>-->
      </li>
    </ul>
    <div v-show="leftMenuShow" class="leftFixedMenu" :class="leftMenuShowWidth ? 'act' : ''" @click="leftMenuShow = false, leftMenuShowWidth = false">
      <ul class="menuList" :class="leftMenuShowWidth ? 'act' : ''" @click.stop="">
        <li v-for="item in burgeonCloudList">
          <!--<span>{{ item.title }}</span>-->
          <a :href="item.url" target="_blank">
            <Icon type="ios-apps" />
            <span>
              {{ item.title }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
  import R3 from '@syman/burgeon-r3';
  const { network, urlSearchParams, components } = R3;
  export default {
    data() {
      return {
        SELF_ENV: process.env.SELF_ENV,
        ename: '',
        userReady: '',
        timer: null,
        timeNum: 0,
        leftMenuShow: false,
        leftMenuShowWidth: false,
        burgeonCloudList: [
          {
            title: '支付平台',
            url: process.env.SELF_ENV === 'pro' ? 'https://console.pay.burgeonyun.com' : 'http://console.pay.oneretail.cn',
          },
          {
            title: '短信平台',
            url: process.env.SELF_ENV === 'pro' ? 'https://console.sms.burgeonyun.com' : 'http://console.sms.oneretail.cn',
          },
          {
            title: '发票平台',
            url: process.env.SELF_ENV === 'pro' ? 'https://console.invoice.burgeonyun.com' : 'http://console.invoice.oneretail.cn',
          },
        ],
        showDrop: false
      };
    },
    components: {
    },
    methods: {
      setIntervalName() {
        if (this.timeNum > 15) {
          clearInterval(this.timer);
          return;
        }
        this.timer = setInterval(() => {
          let userInfo = JSON.parse(localStorage.getItem('userInfo'));
          if (userInfo) {
            if (userInfo.ename) {
              clearInterval(this.timer);
              this.ename = userInfo.ename ? userInfo.ename : '';
            } else {
              this.setIntervalName();
            }
          } else {
            this.setIntervalName();
          }
        }, 1000);
        this.timeNum++;
      },
      jumpToCloud() {
        // this.$router.push('/cloudContent');
        if (this.SELF_ENV === 'pro') {
          window.location.href = 'https://burgeonyun.com/cloudContent/burgeonCloud';
        } else {
          window.location.href = 'http://console.account.oneretail.cn/cloudContent/burgeonCloud';
        }
      },
      uesrDrop(name) {
        if (name === 'changePass' || name === 'changePhone') {
          let userInfo = localStorage.getItem('userInfo');
          let userName = JSON.parse(userInfo).name;
          if (this.SELF_ENV === 'pro') {
            // eslint-disable-next-line no-nested-ternary
            window.open(`https://burgeonyun.com/ForgetPassword?type=${name === 'changePass' ? '1' : name === 'changePhone' ? `2&name=${userName}` : '1'}`);
          } else {
            // eslint-disable-next-line no-nested-ternary
            window.open(`http://console.account.oneretail.cn/ForgetPassword?type=${name === 'changePass' ? '1' : name === 'changePhone' ? `2&name=${userName}` : '1'}`);
          }
        } else if (name === 'out') {
          network.post('/p/cs/logout').then(() => {
            sessionStorage.setItem('loginStatus', 'false');
            localStorage.setItem('loginStatus', 'false');
            localStorage.setItem('userInfo', '');
            // if (window.location.origin.includes('burgeonyun') && window.location.origin.indexOf('burgeonyun') < 9) {
            //   this.$router.push('/cloudContent');
            // } else {
            //   window.location.href = 'https://burgeonyun.com';
            // }
            window.location.href = window.location.origin;
          }).catch(() => {
            // window.location.href = window.location.origin;
          });
        }
      },
      changeLeftMenu() {
        let noOpen = document.getElementById('noOpenAnimation');
        noOpen.style.display = 'none';
        this.$refs.titleDrop.click();
        this.leftMenuShow = true;
        setTimeout(() => {
          this.leftMenuShowWidth = true;
        });
      },
      jumpSonProduct(item) {
        // window.open(item.url);
      },
    },
    created() {
      // this.setIntervalName();
      let userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo) {
        if (userInfo.ename) {
          this.ename = userInfo.ename ? userInfo.ename : '';
        }
      }
    },
    mounted() {
      window.addEventListener('userReady', (res) => {
        this.ename = res.detail.userInfo.ename;
      });
    },
    beforeDestroy() {
      // window.removeEventListener('userReady', () => {}, false);
    }
  };
</script>

<style lang="less" >
  ul, li {
    list-style: none;
  }
  .head-bar{
    position: relative;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0 3px 4px 0 rgba(160, 162, 162, 0.14);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #121212;
    .logo-operation {
      display: flex;
      align-items: center;
      .show-leftMenu {
        display: flex;
        text-align: center;
        background: #FD6442;
        >i {
          width: 50px;
          height: 50px;
          line-height: 50px;
          font-size: 32px;
          color: #fff;
        }
      }
      .title-logo {
        margin-left: 25px;
        height: 28px;
        cursor: pointer;
      }
      .noOpen {
        display: none;
        font-size: 14px;
        position: absolute;
        left: 80px;
        background: #FD6442;
        color: #fff;
        height: 26px;
        line-height: 26px;
        animation:twinkle 1.5s infinite linear;
      }
      .noOpen:before {
        content: '';
        display: block;
        position: absolute;
        left: -20px;
        top: 0;
        border-right: 20px solid #FD6442;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
      }
      @keyframes twinkle {
        0% {
          /*opacity: 1;*/
          transform: translateX(10px);
        }
        50% {
          /*opacity: 0.8;*/
          transform: translateX(0px);
        }
        100% {
          /*opacity: 1;*/
          transform: translateX(10px);
        }
      }
    }
    .title-rightList {
      display: flex;
      align-items: center;
      font-size: 12px;
      >li {
        margin-left: 15px;
        cursor: pointer;
        a {
          color: #333;
        }
      }
      .userContent {
        /*width: 30px;*/
        /*height: 30px;*/
        /*line-height: 30px;*/
        text-align: center;
        margin: 0 40px 0 25px;
        /*color: #fff;*/
        color: #FD6442;
        /*background: #FD6442;*/
        border-radius: 50%;
        >i {
          font-size: 23px;
        }
      }

      .ark-select-dropdown {
        z-index: 99999;
      }
    }
  }
  .leftFixedMenu {
    position: fixed;
    width: 100%;
    height: calc(100% - 50px);
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    top: 50px;
    left: 0;
    z-index: 99998;
    .menuList {
      width: 0;
      height: 100%;
      background: #fff;
      overflow: hidden;
      color: #333;
      box-shadow: inset 0 3px 4px 0 rgba(160, 162, 162, 0.14);
      li {
        white-space: nowrap;
        height: 48px;
        border-bottom: 1px solid #DEDEDE;
        cursor: pointer;
        background-color: #fff;
        transition: background-color 0.3s;
        >a {
          >i {
            vertical-align: middle;
            margin-right: 16px;
          }
          >span {
            display: inline-block;
            vertical-align: middle;
          }
          width: 100%;
          padding: 15px;
          color: #333;
          display: inline-block;
        }
      }
      li:hover {
        background-color: rgba(0, 0, 0, 0.05);
        transition: background-color 0.3s;
        >i {
          color: #FD6442;
        }
      }
    }
    .menuList.act {
      left: 0;
      width: 240px;
      transition: width 0.3s linear;
    }
  }
  .leftFixedMenu.act {
    opacity: 1;
    transition: opacity 0.1s linear;
  }
</style>
