<template>
    <div id="seeBoard">
        <div class="boardTipTitle">
            <p>标题一行 未知数</p>
        </div>
        <div class="tableContent">
            <div class="leftData colMain">
                <div class="sendingData">
                    <p class="colTitle">
                        <span>发送数据量</span>
                        <span>获取数据时间: 11:29:03</span>
                    </p>
                    <div class="colContent infoContent">
                        <div class="toolbox">
                            <label>
                                <Select v-model="whichMsg" size="large" style="width:200px">
                                    <Option value="0">国内消息</Option>
                                    <Option value="1">国外/港澳台短信</Option>
                                </Select>
                            </label>
                            <div class="changeParam">
                                <div v-for="(item, index) in changeParamList" :class="custom === index ? 'act' : ''" @click="custom = index">{{ item }}</div>
                                <div v-if="custom === 2">
                                    <DatePicker v-model="customDate" confirm type="daterange" :start-date="new Date()"
                                                :options="dateLimit" placement="bottom-end" placeholder="起始时间"
                                                @on-ok="confirmCustomDate">
                                    </DatePicker>
                                </div>
                            </div>
                        </div>
                        <div ref="chart" style="width:100%;height:250px;"></div>
                    </div>
                </div>
                <div class="domesticData">
                    <p class="colTitle">
                        <span>国内套餐数据</span>
                    </p>
                    <div class="colContent infoContent">
                        <img src="../../assets/image/gift.png" alt="">
                        <div class="packNotify">
                            <p>国内通用短信套餐包上线啦！</p>
                            <ul>
                                <li>第一条提示是吃葡萄不吐葡萄皮</li>
                                <li>第一条提示是是吐皮</li>
                            </ul>
                        </div>
                        <!--<Button type="info">Info</Button>-->
                        <button class="button-buy">立即购买</button>
                    </div>
                </div>
                <div class="internationalHMT">
                    <p class="colTitle">
                        <span>国际/港澳台服务消息</span>
                    </p>
                    <div class="colContent infoContent">
                        <div class="leftHMT">
                            <img src="../../assets/image/gift.png" alt="">
                            <div class="flex-buy">
                                <p>国际/港澳台套餐包</p>
                                <button class="button-buy">立即购买</button>
                            </div>
                        </div>
                        <div class="rightGlobal">
                            <img src="../../assets/image/gift.png" alt="">
                            <div class="flex-buy">
                                <p>全球套餐包，全球通用</p>
                                <button class="button-buy">立即购买</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightInfo colMain">
                <div class="userMonitor">
                    <p class="colTitle">
                        <span>用户监控信息</span>
                    </p>
                    <div class="colContent infoContent">
                        <p>
                            暂无预警信息，
                            <span class="jump-span">前往设置</span>
                        </p>
                    </div>
                </div>
                <div class="quickOperation">
                    <p class="colTitle">
                        <span>快捷操作入口</span>
                    </p>
                    <div class="colContent infoContent">
                        <div class="topDomestic">
                            <p>国内消息</p>
                            <ul class="list">
                                <li v-for="(item, index) in computedMsgList(1)">
                                    <span>{{ item.text }}<span class="jump-span">{{ item.num }}</span>个</span>
                                    <span class="jump-span" @click="quickJump(1, index)">{{ item.urlName }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="bottomInternational">
                            <p>国际/港澳台消息</p>
                            <ul class="list">
                                <li v-for="(item, index) in computedMsgList(2)">
                                    <span>{{ item.text }}<span class="jump-span">{{ item.num }}</span>个</span>
                                    <span class="jump-span" @click="quickJump(2, index)">{{ item.urlName }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'SeeBoard',
    data() {
      return {
        dateLimit: {
          disabledDate(date) {
            return date && date.valueOf() > Date.now();
          }
        },
        customDate: '',
        changeParamList: ['上月', '本月', '自定义'],
        custom: 0,
        whichMsg: '0',
        domesticConfig: {
          signature: 999,
          template: 888,
          assistant: 777,
        },
        internationalConfig: {
          signature: 111,
          template: 222,
          assistant: 333,
        },
        msgList: [
          {
            text: '已有短信签名',
            num: '',
            urlName: '添加签名'
          },
          {
            text: '已有短信模板',
            num: '',
            urlName: '添加模板'
          },
          {
            text: '已有群发助手任务',
            num: '',
            urlName: '提交发送任务'
          },
        ]
      };
    },
    components: {
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
      this.getEchartData();
    },
    methods: {
      confirmCustomDate() {
        console.log(this.customDate);
      },
      getEchartData() {
        const chart = this.$refs.chart;
        let myChart;
        if (chart) {
          // eslint-disable-next-line no-undef
          myChart = echarts.init(chart);
          const option = {

            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#f1f1f1'
                }
              }
            },
            yAxis: {
              type: 'value',
              // boundaryGap: [0.1, 0.2],
              splitNumber: 3,
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#f1f1f1'
                }
              }
            },
            series: [
              {
                name: '邮件营销',
                type: 'line',
                symbolSize: 0,
                lineStyle: {
                  color: '#5DB059'
                },
                data: [120, 132, 101, 134, 90, 230, 210]
              },
              {
                name: '联盟广告',
                type: 'line',
                symbolSize: 0,
                lineStyle: {
                  color: '#E3AB4C'
                },
                data: [120, 182, 191, 234, 290, 330, 310]
              },
            ]
          };

          myChart.setOption(option);
          window.addEventListener('resize', () => {
            myChart.resize();
          });
        }
        this.$on('hook:destroyed', () => {
          window.removeEventListener('resize', () => {
            // eslint-disable-next-line no-undef
            myChart.resize();
          });
        });
      },
      quickJump(type, index) {
        // eslint-disable-next-line no-console
        console.log(`${(type === 1 ? '国内短信:第' : '国际短信:第') + (index + 1)}行`);
      }
    },
    watch: {
    },
    computed: {
      computedMsgList() {
        let newList = JSON.parse(JSON.stringify(this.msgList));
        return (type) => {
          if (type === 1) {
            // 国内短信
            newList[0].num = this.domesticConfig.signature;
            newList[1].num = this.domesticConfig.template;
            newList[2].num = this.domesticConfig.assistant;
          } else if (type === 2) {
            // 国际短信
            newList[0].num = this.internationalConfig.signature;
            newList[1].num = this.internationalConfig.template;
            newList[2].num = this.internationalConfig.assistant;
          }
          return newList;
        };
      },
    },
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    #seeBoard {
        padding-top: 20px;
        color: #888;
        .boardTipTitle {
            padding: 0 16px;
            margin-bottom: 20px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            >p {
                font-size: 18px;
                color: #fff;
                background: #55BFDB;
            }
        }
        .tableContent {
            display: flex;
            .leftData {
                width: 66.67%;
                padding: 0 16px;
                .sendingData {
                    .infoContent {
                        .toolbox {
                            display: flex;
                            .changeParam {
                                margin-left: 20px;
                                display: flex;
                                align-items: center;
                                border: 1px solid #E6E6E7;
                                >div {
                                    padding: 0 16px;
                                    border-right: 1px solid #E6E6E7;
                                    height: 34px;
                                    line-height: 34px;
                                    cursor: pointer;
                                }
                                >div:last-child {
                                    border-right: none;
                                }
                                >div.act {
                                   background: #55BFDB;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
                .domesticData {
                    .infoContent {
                        display: flex;
                        justify-content: center;
                        /*>img {*/
                        /*    width: 60px;*/
                        /*    height: 60px;*/
                        /*}*/
                        .packNotify {
                            padding:  5px 30px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            >p {
                                font-size: 18px;
                                font-weight: bold;
                                color: #333;
                            }
                            >ul {
                                padding-left: 15px;
                                li {
                                    list-style: disc;
                                }
                            }
                        }
                        >button {
                            align-self: center;
                        }
                    }
                }
                .internationalHMT {
                    .infoContent {
                        padding: 0;
                        display: flex;
                        >div {
                            flex: 1;
                            padding: 20px;
                            display: flex;
                            align-items: stretch;
                        }
                        .leftHMT {
                            border-right: 1px solid #E9EDF0;
                        }
                        .rightGlobal {

                        }
                        .flex-buy {
                            margin-left: 25px;
                            padding-top: 10px;
                            padding-bottom: 5px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            >p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .rightInfo {
                width: 33.33%;
                padding: 0 16px;
                .userMonitor {
                    .infoContent {

                    }
                }
                .quickOperation {
                    .infoContent {
                        >div>p {
                            margin-bottom: 8px;
                        }
                        >div>.list li {
                            padding: 6px 0 6px 10px;
                            display: flex;
                            justify-content: space-between;
                        }
                        >.topDomestic {
                            padding-bottom: 15px;
                            border-bottom: 2px dotted #E9EDF0;
                        }
                        >.bottomInternational {
                            padding-top: 15px;
                        }
                    }
                }
            }
        }

        .colMain>div {
            border: 1px solid #E9EDF0;
            margin-bottom: 20px;
        }
        .colTitle {
            padding: 12px 16px;
            background: #F5F6FA;
            color: #666;
            border-bottom: 2px solid #E7EBEF;
            display: flex;
            justify-content: space-between;
        }
        .colContent {
            padding: 20px 16px;
        }
        .button-buy {
            width: 110px;
            height: 35px;
            background: #55BFDB;
            font-size: 14px;
            color: #fff;
            border: none;
            cursor: pointer;
        }
        .jump-span {
            color:#5DC2DD;
            cursor: pointer;
        }
    }
</style>
