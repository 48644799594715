var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "invCloud" } }, [
    _c("div", { staticClass: "invCloudBanner" }, [
      _c("div", { staticClass: "introduce" }, [
        _c(
          "div",
          { staticClass: "info-title" },
          [
            _c("p", { staticClass: "main-title" }, [_vm._v("发票服务")]),
            _vm._v(" "),
            _c("p", { staticClass: "sub-title" }, [
              _vm._v(
                "网页开票、手机开票、批量开票、扫码开票、审核开票等适配多"
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "sub-title" }, [
              _vm._v(
                "场景的开票方式，提供接口，支持企业打通自有业务系统进行开票及发票管理"
              ),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "theme-main-btn-big",
                attrs: { to: "/login", tag: "span" },
              },
              [_vm._v("免费接入")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "superiorityContent" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "superiorityList unifiedList" },
        _vm._l(_vm.superiorityList, function (item) {
          return _c("li", { staticClass: "unifiedLi" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/home/exhibition-inv/" +
                  item.img +
                  ".png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "normalTitle" }, [
              _vm._v(_vm._s(item.normalTitle)),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "invDifficulty" }, [
      _vm._m(1),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "unifiedList" },
        _vm._l(_vm.difficultyList, function (item) {
          return _c("li", { staticClass: "unifiedLi" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/home/exhibition-inv/" +
                  item.img +
                  ".png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "mainTitle" }, [
              _vm._v(_vm._s(item.mainTitle)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(item.subTitle)),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "invSolve" }, [
      _vm._m(2),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "superiorityList unifiedList" },
        _vm._l(_vm.industrySolutionsList, function (item) {
          return _c("li", { staticClass: "unifiedLi" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/home/exhibition-inv/" +
                  item.img +
                  ".png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "mainTitle" }, [
              _vm._v(_vm._s(item.mainTitle)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(item.subTitle)),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "channelContent" }, [
      _vm._m(3),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "invoiceList unifiedList" },
        _vm._l(_vm.invoiceList, function (item) {
          return _c("li", { staticClass: "unifiedLi" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/home/exhibition-inv/" +
                  item.img +
                  ".png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "normalTitle" }, [
              _vm._v(_vm._s(item.normalTitle)),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedHeadline-blue" }, [
      _c("span", { staticClass: "midShadow left" }),
      _vm._v(" "),
      _c("span", { staticClass: "midText" }, [_vm._v("产品优势")]),
      _vm._v(" "),
      _c("span", { staticClass: "midShadow right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedCloudLine" }, [
      _c("div", { staticClass: "midShadow left" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "midText" }, [_vm._v("企业接入支付的难点")]),
      _vm._v(" "),
      _c("div", { staticClass: "midShadow right" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedCloudLine" }, [
      _c("div", { staticClass: "midShadow left" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "midText" }, [_vm._v("行业解决方案")]),
      _vm._v(" "),
      _c("div", { staticClass: "midShadow right" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedHeadline-blue" }, [
      _c("span", { staticClass: "midShadow left" }),
      _vm._v(" "),
      _c("span", { staticClass: "midText" }, [_vm._v("发票渠道")]),
      _vm._v(" "),
      _c("span", { staticClass: "midShadow right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }