var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "header" } }, [
    _c(
      "div",
      { staticClass: "website-title" },
      [
        _c(
          "router-link",
          { staticClass: "logo", attrs: { to: "/cloudContent", tag: "div" } },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/home/exhibition/logo.png"),
                alt: "",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _vm.$route.path != "/login"
          ? _c(
              "div",
              { staticClass: "menu-login" },
              [
                _c(
                  "div",
                  { staticClass: "headMenu" },
                  _vm._l(_vm.headDropList, function (item, index) {
                    return _c(
                      "Dropdown",
                      {
                        key: index,
                        staticClass: "dropDownList",
                        on: {
                          "on-click": (nameValue) => {
                            _vm.dropItemClick(item, nameValue)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "dropName" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.title) +
                                "\n            "
                            ),
                            _c("Icon", { attrs: { type: "ios-arrow-down" } }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "DropdownMenu",
                          { attrs: { slot: "list" }, slot: "list" },
                          _vm._l(item.list, function (child, childIndex) {
                            return _c(
                              "DropdownItem",
                              { key: childIndex, attrs: { name: childIndex } },
                              [_vm._v(_vm._s(child.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                !_vm.loginInThis
                  ? _c(
                      "div",
                      { staticClass: "operations" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "loginBtn theme-sub-btn",
                            attrs: { to: "/login", tag: "span" },
                          },
                          [_vm._v("登录")]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "theme-main-btn",
                            attrs: { to: "/Register", tag: "span" },
                          },
                          [_vm._v("新公司注册")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loginInThis
                  ? _c(
                      "span",
                      {
                        staticClass: "ename",
                        staticStyle: { "margin-right": "20px" },
                        on: { click: _vm.jumpToMain },
                      },
                      [_vm._v("控制台")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loginInThis
                  ? _c(
                      "Dropdown",
                      {
                        staticClass: "userDrop",
                        on: { "on-click": _vm.uesrDrop },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "ename",
                            on: { click: _vm.jumpToMain },
                          },
                          [_vm._v(_vm._s(_vm.ename))]
                        ),
                        _vm._v(" "),
                        _c(
                          "DropdownMenu",
                          { attrs: { slot: "list" }, slot: "list" },
                          [
                            _c(
                              "DropdownItem",
                              { attrs: { name: "changePass" } },
                              [_vm._v("修改密码")]
                            ),
                            _vm._v(" "),
                            _c(
                              "DropdownItem",
                              { attrs: { name: "changePhone" } },
                              [_vm._v("修改手机号")]
                            ),
                            _vm._v(" "),
                            _c("DropdownItem", { attrs: { name: "out" } }, [
                              _vm._v("退出登录"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }