var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "smsCloud" } }, [
    _c("div", { staticClass: "smsCloudBanner" }, [
      _c("div", { staticClass: "introduce" }, [
        _c(
          "div",
          { staticClass: "info-title" },
          [
            _c("p", { staticClass: "main-title" }, [_vm._v("短信服务")]),
            _vm._v(" "),
            _c("p", { staticClass: "sub-title" }, [
              _vm._v(
                "消息&短信 （Message&SMS）是伯俊携手全球多家优质运营商和渠道为企业用户提供的通信服务。\n                    企业调用API或使用群发助手即可使用验证码、通知短信服务\n                "
              ),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "theme-main-btn-big",
                attrs: { to: "/login", tag: "span" },
              },
              [_vm._v("免费接入")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "smsSuperiorityContent" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "smsSuperiorityList" },
        _vm._l(_vm.superiorityList, function (item) {
          return _c("li", [
            _c("div", { staticClass: "imgCont" }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/home/exhibition-sms/" +
                    item.img +
                    ".png"),
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "textContent" }, [
              _c("p", { staticClass: "main-title" }, [
                _vm._v(_vm._s(item.mainTitle)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "sub-title" }, [
                _vm._v(_vm._s(item.subTitle)),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "smsInfo" }, [
      _vm._m(1),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "unifiedList" },
        _vm._l(_vm.smsInfoList, function (item) {
          return _c("li", { staticClass: "unifiedLi" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/home/exhibition-sms/" +
                  item.img +
                  ".png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "subTitle" }, [
              _vm._v(_vm._s(item.subTitle)),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "smsApplication" }, [
      _vm._m(2),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "applicationList" },
        _vm._l(_vm.applicationList, function (item) {
          return _c("li", { staticClass: "appItem" }, [
            _c("p", { staticClass: "appItemTitle" }, [
              _vm._v(_vm._s(item.title)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "appItemContent" }, [
              _c("div", { staticClass: "itemImgCont" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/home/exhibition-sms/" +
                      item.img +
                      ".png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "purposeList" },
                _vm._l(item.list, function (child) {
                  return _c("li", [
                    _c("span"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(child))]),
                  ])
                }),
                0
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedHeadline-blue" }, [
      _c("span", { staticClass: "midShadow left" }),
      _vm._v(" "),
      _c("span", { staticClass: "midText" }, [_vm._v("产品优势")]),
      _vm._v(" "),
      _c("span", { staticClass: "midShadow right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedCloudLine" }, [
      _c("div", { staticClass: "midShadow left" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "midText" }, [_vm._v("短信 SMS 简介")]),
      _vm._v(" "),
      _c("div", { staticClass: "midShadow right" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unifiedCloudLine" }, [
      _c("div", { staticClass: "midShadow left" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "midText" }, [_vm._v("应用场景")]),
      _vm._v(" "),
      _c("div", { staticClass: "midShadow right" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }