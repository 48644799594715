<template>
  <div class="registerContent">
    <div class="titleContent">
      <div class="title">
        <div
          class="logo"
          @click="logoClick"
        >
          <img src="../assets/home/exhibition/logo.png">
        </div>
        <div class="text">
          <span>已有账户，</span>
          <span
            class="toLogin"
            @click="toLogin"
          >直接登录</span>
          <i
            class="iconfont iconbj_right"
            @click="toLogin"
          />
        </div>
      </div>
    </div>
    <div class="registerCompanyInfo">
      <div class="registerCompanyTitle">
        <span class="companyTitle act">公司注册</span>
        <b class="sj"></b>
      </div>
      <div class="registerForm">
        <Form
          ref="formValidate"
          class="contentForm"
          label-position="left"
          :model="formValidate"
          :rules="ruleValidate"
        >
          <FormItem label="公司名称:" prop="companyName">
            <div class="formRightFlex">
              <Input v-model="formValidate.companyName" clearable placeholder="请输入名称" />
            </div>
          </FormItem>
          <FormItem label="登录用户名:" prop="userName">
            <div class="formRightFlex">
              <Input v-model="formValidate.userName" clearable placeholder="请输入用户名"/>
            </div>
          </FormItem>
          <FormItem label="登录密码:" prop="password">
            <div class="password">
              <Input autocomplete="new-password" v-model="formValidate.password"
                :type="isPasswordShow ? 'text': 'password'"
                placeholder="请输入密码"/>
              <i v-show="!isPasswordShow" class="iconfont iconbj_see" @click="editPasswordSee"/>
              <i v-show="isPasswordShow" class="iconfont iconbj_nosee" @click="editPasswordSee"
              />
            </div>
          </FormItem>
          <FormItem label="真实姓名:" prop="realName">
            <div class="formRightFlex">
              <Input v-model="formValidate.realName" clearable placeholder="请输入真实姓名"/>
            </div>
          </FormItem>
          <FormItem label="联系手机:" prop="phone">
            <div class="formRightFlex">
              <Input v-model="formValidate.phone" clearable placeholder="请输入手机号"/>
            </div>
          </FormItem>
          <!--<FormItem class="inputCodeCont" label="验证码:" prop="imgCode">-->
          <!--  <div class="formRightFlex codeFormRight">-->
          <!--    <Input placeholder="请输入验证码" v-model="formValidate.imgCode"/>-->
          <!--    <img v-if="codeUrl" @click="getCode()" :src="'data:image/png;base64,'+codeUrl" alt="">-->
          <!--    <img v-else src="static/img/defeated.png" @click="getCode()" alt="">-->
          <!--  </div>-->
          <!--</FormItem>-->
          <FormItem class="noteCode" label="短信验证:" prop="inputCode">
            <div class="formRightFlex noteCodeRight">
              <Input placeholder="请输入短信验证码" v-model="formValidate.inputCode"/>
              <div class="changeText">
                <button type="button" v-if="!loadingCode" class="verification" @click="getVerifyCode">
                  点击获取
                </button>
                <span v-if="loadingCode">{{ countDown }}秒重新获取</span>
              </div>
            </div>
          </FormItem>
          <FormItem label="邮箱:" prop="email">
            <div class="formRightFlex" style="display: flex">
              <Input v-model="formValidate.email" clearable placeholder="请输入邮箱"/>
              <!-- <Input v-model="formValidate.email" style="flex: auto; margin-right: 10px;" clearable placeholder="请输入邮箱"/> -->
              <!-- <Select v-if="!selfEmail" v-model="emailAfter" style="width: 100px" size="large">
                <Option v-for="item in email" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
              <label for="selfEmail" class="cursorEmail">
                <input :checked="selfEmail" @change="changeEmailMethod" id="selfEmail" type="checkbox">
                <span style="margin-left: 3px">手动</span>
              </label> -->
            </div>
          </FormItem>
          <FormItem label="短信签名:" prop="signName">
            <div class="formRightFlex">
              <Input v-model="formValidate.signName" clearable wordLimit placeholder="请输入短信签名" :maxlength="20"/>
              
            </div>
          </FormItem>
          <FormItem label="" prop="" style="margin-bottom: 16px;margin-top: -6px;">
            <div class="formRightFlex" style="width: 350px;">
              <div class="FormItem-tip">
                <ul>
                   <li>1、建议使用简短品牌名，不支持带“测试”字样的签名；</li>
                   <li>2、无须添加【】、0、·符号，签名发送会自带【】符号</li>
                   <!-- <li>无须添加【】、0、·符号，签名发送会自带【】符号，避免重复</li>
                   <li>不支持申请带“测试”字样的签名</li> -->
                </ul>

              </div>
              
            </div>
          </FormItem>

         
        </Form>
        <Button type="primary" class="bottomButton" @click="nextStep">用户注册</Button>
      </div>
    </div>
    <div class="modalVerify" v-if="showModel">
      <div class="modalContent">
        <div class="hearTitle">输入验证码</div>
        <div class="verifyCodeVontent">
          <span class="name">验证码</span>
          <Input class="Arkinput" placeholder="请输入验证码" v-model="imgCode"/>
          <!-- <img v-if="codeUrl" @click="getCode()" :src="'data:image/png;base64,'+codeUrl" alt=""> -->
          <!-- <img v-else src="static/img/defeated.png" @click="getCode()" alt=""> -->
        </div>
        <div class="footBtn">
          <span class="theme-sub-btn" @click="cancelCheck">取消</span>
          <span class="theme-main-btn" @click="checkCode">确认</span>
          <!--<Button type="text" @click="cancelCheck">取消</Button>-->
          <!--<Button type="primary" @click="checkCode" >确认</Button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import R3 from '@syman/burgeon-r3';
  import axios from 'axios';

  const { network } = R3;

  export default {
    data() {
      const validatePassCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('确认密码不能为空，请输入！'));
        } else if (value !== this.formValidate.password) {
          callback(new Error('两次输入的密码不一样，请重新输入！'));
        } else {
          callback();
        }
      };
      const validatePhoneCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('联系手机不能为空，请输入！'));
        } else if (!/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号！'));
        } else {
          callback();
        }
      };
      const validateEmailCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('邮箱不能为空，请输入！'));
          // eslint-disable-next-line no-useless-escape
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/.test(value)) {
          callback(new Error('请输入正确的邮箱！'));
        } else {
          callback();
        }
      };
      const validateUserNameCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('用户名不能为空，请输入！'));
        } else if (/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value) === false) {
          callback(new Error('用户名只能输入汉字、字母和数字，请重新输入！'));
        } else {
          callback();
        }
      };
      const validateRealNameCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('真实姓名不能为空，请输入！'));
        } else {
          callback();
        }
      };
      const validateCompanyNameCheck = async (rule, value, callback) => {
        if (value === '') {
          callback(new Error('公司名称不能为空，请输入！'));
        } else {
          const res = await network.post(window.sessionStorage.getItem('serviceId')+'/p/c/verify/bcp_customer', { companyName: value })
          if (res.data.code === 0) {
            callback()
          } else {
            callback(new Error(res.data.message || '请输入正确公司名称'));
          }
        }
      };
      const validatePasswordCheck = (rule, value, callback) => {
        // let pwdRegex = /^[a-zA-Z0-9_@.+/\-*]{0,}$/;
        let pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}');
        if (value === '') {
          callback(new Error('密码不能为空，请输入！'));
        } else if (!pwdRegex.test(value)) {
          // callback(new Error('密码长度不小于6！'));
          callback(new Error('必须包含字母、数字、特殊字符且不小于8位！'));
        } else {
          callback();
        }
        // else if (!pwdRegex.test(value)) {
        //   callback(new Error('请输入正确格式密码，请勿携带特殊字符！'));
        // }
      };
      // const validateImgCodeCheck = (rule, value, callback) => {
      //   if (value === '') {
      //     callback(new Error('验证码不能为空，请输入！'));
      //   } else {
      //     callback();
      //   }
      // };
      const validateCodeCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('短信验证码不能为空，请输入！'));
        } else {
          callback();
        }
      };
      const validateSignNameNameCheck = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('短信签名不能为空，请输入！'));
        } else {
          callback();
        }
      };
      return {
        serviceId: window.sessionStorage.getItem('serviceId'),
        showModel: false,
        isVerificationShow: false,
        imgCode: '',
        selfEmail: true,
        formValidate: {
          companyName: '',
          userName: '',
          realName: '',
          password: '',
          confirmPassword: '',
          phone: '',
          email: '',
          signName:'',
          // imgCode: '',
          inputCode: '',

        },
        ruleValidate: {
          companyName: [
            { required: true, validator: validateCompanyNameCheck, trigger: 'blur' }
          ],
          userName: [
            { required: true, validator: validateUserNameCheck, trigger: 'blur' }
          ],
          realName: [
            { required: true, validator: validateRealNameCheck, trigger: 'blur' }
          ],
          password: [
            { required: true, validator: validatePasswordCheck, trigger: 'blur' }
          ],
          confirmPassword: [
            { required: true, validator: validatePassCheck, trigger: 'blur' }
          ],
          phone: [
            { required: true, validator: validatePhoneCheck, trigger: 'blur' }
          ],
          email: [
            { required: true, validator: validateEmailCheck, trigger: 'blur' }
          ],
          inputCode: [
            { required: true, validator: validateCodeCheck, trigger: 'blur' }
          ],
          signName:[
            { required: true, validator: validateSignNameNameCheck, trigger: 'blur' }
          ]
          // imgCode: [
          //   { required: true, validator: validateImgCodeCheck, trigger: 'blur' }
          // ]
        },
        emailAfter: '@qq.com',
        email: [
          {
            value: '@qq.com',
            label: '@qq.com'
          },
          {
            value: '@163.com',
            label: '@163.com'
          },
          {
            value: '@126.com',
            label: '@126.com'
          },
          {
            value: '@139.com',
            label: '@139.com'
          },
          {
            value: '@sohu.com',
            label: '@sohu.com'
          },
          {
            value: '@gmail.com',
            label: '@gmail.com'
          },

          {
            value: '@hotmail.com',
            label: '@hotmail.com'
          },
        ],
        isPasswordShow: false, // 是否显示密码
        codeUrl: '', // 二维码base64位
        codeImageName: '', // 二维码名称
        loadingCode: false,
        countDown: 120,
      };
    },
    name: 'Register',
    components: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
      this.serviceId = window.sessionStorage.getItem('serviceId');
    },
    watch: {
      isVerificationShow() {
        if (!this.isVerificationShow) {
          this.showModel = false;
          this.$refs.puzzle.refreshImg();
        }
      }
    },
    computed: {},
    methods: {
      handleSuccess() {
        this.showModel = false;
        this.isVerificationShow = false;
        this.openPicVerify();
      }, // 拼图验证
      editPasswordSee() {
        this.isPasswordShow = !this.isPasswordShow;
      }, // 切换密码
      toLogin() {
        this.$router.push({ path: '/login' });
      }, // 跳转到登录界面
      logoClick() {
        this.$router.push({ path: '/cloudContent' });
      }, // logo点击触发
      changeEmailMethod() {
        this.selfEmail = !this.selfEmail;
        let newChecked;
        if (this.selfEmail) {
          newChecked = (rule, value, callback) => {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/.test(value)) {
              callback(new Error('请输入正确的邮箱格式！'));
            } else {
              callback();
            }
          };
        } else {
          newChecked = (rule, value, callback) => {
            if (value === '') {
              callback(new Error('邮箱不能为空，请输入！'));
            } else {
              callback();
            }
          };
        }
        this.ruleValidate.email[0] = { required: true, validator: newChecked, trigger: 'blur' };
      },
      nextStep() {
        const vm = this;
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            const obj = {
              company: this.formValidate.companyName,
              name: this.formValidate.userName,
              trueName: this.formValidate.realName,
              password: this.formValidate.password,
              phone: this.formValidate.phone,
              email: this.selfEmail ? this.formValidate.email : this.formValidate.email + this.emailAfter,
              // codeImageName: this.codeImageName,
              verifyCode: this.formValidate.inputCode,
              signName:this.formValidate.signName
            };
            network.post(this.serviceId + '/p/c/user/register', obj).then((res) => {
              if (res.data.code === 0) {
                this.$router.push({ path: '/login' });
              } else if (res.data.code === -1) {
                this.$Modal.fcWarning({
                  title: '提示',
                  mask: true,
                  content: res.data.message,
                  onOk: () => {
                  }
                });
                vm.getCode();
              }
            }).catch((err) => {
              throw err;
            });
          }
        });
      },
      getCode() {
        // 获取二维码
        // const vm = this;
        // network.post(this.serviceId + '/p/c/code_image').then((res) => {
        //   vm.codeUrl = res.data.codeImage;
        //   vm.codeImageName = res.data.codeImageName;
        // });
      },
      setIntervalCode() { // 验证码定时器
        this.loadingCode = true;
        this.countDownInterval = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            this.clearSetIntervalCode();
          }
        }, 1000);
      },
      clearSetIntervalCode() { // 验证码失败清除定时器
        this.loadingCode = false;
        this.countDown = 120;
        clearInterval(this.countDownInterval);
      },
      getVerifyCode() {
        // if (this.formValidate.imgCode && /^1(3|4|5|7|8)\d{9}$/.test(this.formValidate.phone)) {
        if (/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(this.formValidate.phone)) {
          this.getCode();
          // 校验
          this.imgCode = '';
          this.checkCode()
          // this.showModel = true;
          // this.$nextTick(() => {
          //   this.isVerificationShow = true;
          // });
        } else {
          this.$refs.formValidate.validateField('phone', () => {});
          // this.$refs.formValidate.validateField('imgCode', () => {});
        }
      },
      cancelCheck() {
        this.showModel = false;
      },
      checkCode() {
        this.openPicVerify(() => {
          this.showModel = false;
        });
      },
      openPicVerify(callBack) {
        let params = {
          phone: this.formValidate.phone,
          codeImageName: this.codeImageName,
          verifyCode: this.formValidate.imgCode,
          // verifyCode: this.imgCode
        };
        let one = axios.create({});
        one.post(this.serviceId + '/p/c/send_sms_byphone', params).then((res) => {
          if (res.data.code === 0) {
            this.setIntervalCode();
            callBack();
            this.$Message.info({
              content: res.data.message,
              duration: 5
            });
          } else if (res.data.code === -1) {
            this.getCode();
            this.clearSetIntervalCode();
            this.$Message.error(res.data.message);
            // this.$Modal.fcError({
            //   title: '提示',
            //   mask: true,
            //   content: res.data.message,
            //   onOk: () => {
            //   }
            // });
          }
        }).catch((err) => {
          this.getCode();
          this.clearSetIntervalCode();
          throw err;
        });
      }
    }
  };
</script>

<style lang="less">
    .registerContent {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        .titleContent {
            height: 60px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            .title {
                width: 67%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .logo {
                    /*height: 38px;*/
                    cursor: pointer;
                    img {
                        // width: 242px;
                        // height: 38px;
                        margin-left: 25px;
                        height: 28px;
                        align-items: center;
                        vertical-align: middle;
                    }
                }
                .text {
                    font-size:14px;
                    font-weight:400;
                    color:rgba(85,85,85,1);
                    line-height:20px;
                    display: flex;
                    align-items: center;
                    .toLogin {
                        color: #7A9EFF;
                        cursor: pointer;
                    }
                    i {
                        color: #7A9EFF;
                        cursor: pointer;
                    }
                }
            }
        }
        .registerCompanyInfo {
            height: calc(100% - 60px);
            padding-bottom: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background:rgba(243,245,248,1);
            .registerCompanyTitle {
              width: 420px;
              height: 40px;
              line-height: 40px;
              // background: #eee;
              position: relative;
              font-size: 0;

                text-align: center;
              span {
                width: 50%;
                display: inline-block;
                padding: 0 50px;
                font-size: 18px;
                color: #999;
                text-align: center;
                cursor: pointer;
                letter-spacing: 2px;
              }
              span:first-child {
                padding: 0 35px 0 35px;
              }
              .act {
                font-weight: bold;
                color: #333;
                background: #fff;
                position: relative;
              }
              .sj{
                position: absolute;
                right: 75px;
                width: 0;
                height: 0;
                border-left: 30px solid transparent;
                border-right: 30px solid transparent;
                border-bottom: 40px solid #fff;
              }
            }
            .registerCompanyTitle::before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-top: 40px solid #f3f5f8;
              border-right: 30px solid transparent;
              background: #fff;
              left: 75px;
              z-index: 1;
            }
            .registerCompanyTitle::after {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-top: 40px solid #f3f5f8;
              border-left: 30px solid transparent;
              /*background: #f3f5f8;*/
              right: 0;
            }
            .registerForm {
                /*overflow-y: auto;*/
                background:rgba(255,255,255,1);
                box-shadow:0 10px 10px 0 rgba(172,172,172,0.1);
                /*width: 500px;*/
                padding: 40px 80px 30px;
              border-radius: 5px;
                .contentForm input {
                    height: 32px;
                }
                .ark-input-icon {
                    line-height: 32px;
                }
                /*.ark-form-item-content{*/
                  /*display: flex;*/
                  /*justify-content: flex-end;*/
                  /*>img{*/
                    /*width: 80px;*/
                    /*cursor: pointer;*/
                    /*margin-left: 11px;*/
                  /*}*/
                /*}*/
                .contentForm {
                  .password {
                        position: relative;
                        width: 400px;
                        i {
                            height: 31px;
                            position: absolute;
                            right: 10px;
                            cursor: pointer;
                        }
                    }
                  .formRightFlex{
                    width: 400px;
                  }
                  .codeFormRight>.ark-input-wrapper, .noteCodeRight>.ark-input-wrapper {
                    width: 300px;
                  }
                  .codeFormRight>img {
                    vertical-align: middle;
                    width: 80px;
                    cursor: pointer;
                    margin-left: 11px;
                  }
                  .noteCode {
                    .noteCodeRight {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      .changeText {
                        height: 35px;
                        .verification {
                          background: #F7F7F7;
                          border: 1px solid #DEDEDE;
                          width: 82px;
                          height: 35px;
                          cursor: pointer;
                        }
                        span {
                          width: 82px;
                          margin-left: 13px;
                        }
                      }

                    }

                  }

                  .ark-select-large.ark-select-single .ark-select-selection,
                  .ark-select-large.ark-select-single .ark-select-selection .ark-select-placeholder,
                  .ark-select-large.ark-select-single .ark-select-selection .ark-select-selected-value{
                    height: 32px;
                    line-height: 30px;
                  }

                  .cursorEmail {
                    margin-left: 10px;
                    font-size: 12px;
                    color: #666;
                    display: flex;
                    cursor: pointer;
                    flex: none;
                  }
                }
                .bottomButton {
                    width: 100%;
                    background:linear-gradient(310deg,#ff6a00 0%,#ff6a00 100%);
                    border-radius:4px;
                }

            }
        }
      .ark-form-label-left .ark-form-item-label {
        width: 100px;
      }
      .ark-form-item {
        display: flex;
        justify-content: space-between;
      }
      .ark-btn-primary{
        border-color:#FFFFFF;
      }
    }
    .modalVerify {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.3);
      z-index: 99;
      .modalContent {
        width: 500px;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        .hearTitle {
          text-align: center;
        }
        .footBtn {
          text-align: right;
        }
      }
    }
    .verifyCodeVontent {
      padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .name {
        display: inline-block;
        width: 60px;
        margin-right: 10px;
      }
      .Arkinput {
        input {
          height: 32px;
        }
      }
      >img {
        width: 80px;
        margin-left: 10px;
      }
    }
    .registerForm{
      .ark-input-innerWrap{
        line-height: 32px;
      }

    }
    
    .FormItem-tip{
      padding-left: 38px;
      width: 120%;
      li{
         text-decoration: dotted;
         font-size: 10px;
         line-height: 20px;
         color: #999;
         list-style-type: disc;
        vertical-align: text-bottom;

      }
    }
</style>
