<template>
    <div>
         <AnchorLink v-for="(item, index) in list" :key="index" :href="item.href" :title="item.title">
            <LoopAnchorlink v-if="item.children && item.children.length > 0" :list="item.children">
            </LoopAnchorlink>
        </AnchorLink>
    </div>
</template>

<script>
  export default {
    name: 'LoopAnchorlink',
    props: {
      list: {
        type: Array,
        default: () => ([])
      }
    },
    data() {
      return {};
    },
    components: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {},
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style scoped lang="less" type="text/less">

</style>
