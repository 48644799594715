var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "allowAgreement" } }, [
    _c("div", { staticClass: "allow-content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "cont-line" }, [
        _c("div", [_vm._v("开通说明：")]),
        _vm._v(" "),
        _c("div", { staticClass: "opening" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("p", { staticClass: "check-open" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.agree,
                    expression: "agree",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.agree)
                    ? _vm._i(_vm.agree, null) > -1
                    : _vm.agree,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.agree,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.agree = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.agree = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.agree = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { class: _vm.agree ? "agree" : "" }, [
                _vm._v("我已阅读并同意"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "btn-group" }, [
            _c(
              "span",
              {
                class: _vm.agree ? "theme-main-btn" : "disableAgree",
                on: { click: _vm.openService },
              },
              [_vm._v("立即开通")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont-line" }, [
      _c("div", [_vm._v("产品名称：")]),
      _vm._v(" "),
      _c("div", { staticClass: "opening" }, [_vm._v("短信服务")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("产品免费开通，按量实际计费。"),
      _c("span", { staticClass: "lookInfo" }, [_vm._v("查看计费说明")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }