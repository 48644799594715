<template>
    <div id="pictureVerCode">
        <div class="headTop">
            <img class="logoYq" src="https://img02.mockplus.cn/idoc/sketch/2020-12-22/9d07ccca-de6b-4a14-a7e0-487e3e6028c7.png" alt="">
            <div class="languageTools">
                <div @click="showChangeLang = true" style="cursor: pointer">{{ langList[langIndex] }}</div>
                <ul class="langList" :class="showChangeLang ? '' : 'noneLangList'">
                    <li v-for="(item, index) in langList" @click="changeLang(index)" :class="langIndex === index ? 'act' : ''">{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="contentBgBox">
            <div class="imgContent">
                <img class="imgYq" src="https://img02.mockplus.cn/idoc/sketch/2020-12-22/c81be4ff-fe34-40db-85df-a2093c7b857c.png!thumb-p-25" alt="">
            </div>
            <div class="loginTo">
                <img class="loginToLogo" src="https://img02.mockplus.cn/idoc/sketch/2020-12-22/8bd9e7f8-f090-463f-a7fa-eaf98285ca01.png" alt="">
                <div class="loginAccount">
                    <div class="userName">
                        <img src="https://img02.mockplus.cn/idoc/sketch/2020-12-22/f4bdb165-6e0d-4a53-baea-3d9f41f3ba7c.png" alt="">
                        <input type="text" :placeholder="langObj.username">
                    </div>
                    <div class="password">
                        <img src="https://img02.mockplus.cn/idoc/sketch/2020-12-22/f1a0b034-8087-44c4-a761-d6a313e91b43.png" alt="">
                        <input type="password" :placeholder="langObj.password">
                    </div>
                    <div class="loginBtn">{{ langObj.login }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'PictureVerCode',
    data() {
      return {
        showChangeLang: false,
        langIndex: 0,
        langList: ['简体中文', 'English'],
        langObj: {
          username: '用户名',
          password: '密码',
          login: '登录',
        }
      };
    },
    components: {},
    beforeCreate() {
    },
    created() {
      this.langIndex = Number(localStorage.getItem('langIndex')) || 0;
      this.setLangWord();
    },
    beforeMount() {
    },
    mounted() {
      // document.addEventListener('click', (e) => {
      //   if (e) {
      //     this.showChangeLang = false;
      //   }
      // });
    },
    methods: {
      setLangWord() {
        if (this.langIndex) {
          this.langObj = {
            username: 'userName',
            password: 'password',
            login: 'login',
          };
        } else {
          this.langObj = {
            username: '用户名',
            password: '密码',
            login: '登录',
          };
        }
      },
      changeLang(index) {
        this.langIndex = index;
        this.showChangeLang = false;
        localStorage.setItem('langIndex', index);
        this.setLangWord();
      },
    },
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    #pictureVerCode {
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg,#54b8ff, #3e82ff);
        display: flex;
        flex-direction: column;
        .headTop {
            height: 95px;
            padding: 30px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 9;
            .logoYq {
                width: 144px;
            }
            .languageTools {
                width: 120px;
                height: 42px;
                border: 1px solid #D3E3FF;
                border-radius: 5px;
                background: rgba(255,255,255,.25);
                color: #fff;
                text-align: center;
                line-height: 42px;
                .langList {
                    width: 120px;
                    margin-top: 10px;
                    font-size: 14px;
                    background: #fff;
                    border-radius: 5px;
                    color: #99A6AD;
                    opacity: 1;
                    transition: opacity 0.5s;
                    >li {
                        padding: 5px 0;
                        cursor: pointer;
                    }
                    >li.act {
                        color: #4E95FF;
                    }
                }
                .noneLangList {
                    /*display: none;*/
                    opacity: 0;
                }
            }
        }
        .contentBgBox {
            display: flex;
            flex: 1;
            align-items: center;
            margin-top: -200px;
            .imgContent {
                flex: 1;
                text-align: center;
                .imgYq {
                    width: 80%;
                    min-width: 320px;
                }
            }
            .loginTo {
                padding-left: 50px;
                flex: 1;
                font-size: 14px;
                .loginToLogo {
                    width: 70%;
                    min-width: 320px;
                    margin-bottom: 26px;
                }
                .loginAccount {
                    width: 320px;
                    .password, .userName {
                        display: flex;
                        align-items: center;
                        height: 56px;
                        border-radius: 30px;
                        border: 2px solid #fff;
                        padding: 0 30px;
                        margin-bottom: 30px;
                        >img {
                            width: 17px;
                            height: 17px;
                            margin-right: 8px;
                        }
                        input {
                            border: none;
                            background: transparent;
                            color: #fff;
                            height: 100%;
                        }
                        input::-webkit-input-placeholder {
                            color: #fff;
                        }
                    }
                    .loginBtn {
                        height: 56px;
                        line-height: 56px;
                        text-align: center;
                        border-radius: 30px;
                        background: #fff;
                        font-size: 16px;
                        color: #4E95FF;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>
