var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "interfaceDoc" },
    _vm._l(_vm.dataList, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "data" },
        [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "tab" }),
            _vm._v(" "),
            _c("div", { staticClass: "visitText" }, [
              _vm._v(_vm._s(item.title)),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.dataList[index].list, function (i, cindex) {
            return _c(
              "div",
              {
                key: cindex,
                staticClass: "block",
                on: {
                  click: function ($event) {
                    return _vm.tolink(i.type)
                  },
                },
              },
              [
                _c("div", { staticClass: "dateTitle" }, [
                  _vm._v(_vm._s(i.text)),
                ]),
              ]
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }