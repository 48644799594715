<template>
    <div id="smsCloud">
        <div class="smsCloudBanner">
            <div class="introduce">
                <div class="info-title">
                    <p class="main-title">短信服务</p>
                    <p class="sub-title">消息&短信 （Message&SMS）是伯俊携手全球多家优质运营商和渠道为企业用户提供的通信服务。
                        企业调用API或使用群发助手即可使用验证码、通知短信服务
                    </p>
                    <!--<router-link class="theme-main-btn-big" to="/cloudContent/smsCloudInfo" tag="span">了解详情</router-link>-->
                    <!--<span class="theme-main-btn">免费接入</span>-->
                    <router-link class="theme-main-btn-big" to="/login" tag="span">免费接入</router-link>
                </div>
            </div>
        </div>
        <div class="smsSuperiorityContent">
            <div class="unifiedHeadline-blue">
                <span class="midShadow left"></span>
                <span class="midText">产品优势</span>
                <span class="midShadow right"></span>
            </div>
            <ul class="smsSuperiorityList">
                <li v-for="item in superiorityList">
                    <div class="imgCont">
                        <img :src="require('../../assets/home/exhibition-sms/'+item.img+'.png')" alt="">
                    </div>
                    <div class="textContent">
                        <p class="main-title">{{ item.mainTitle }}</p>
                        <p class="sub-title">{{ item.subTitle }}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="smsInfo">
            <div class="unifiedCloudLine">
                <div class="midShadow left">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="midText">短信 SMS 简介</div>
                <div class="midShadow right">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <ul class="unifiedList">
                <li v-for="item in smsInfoList" class="unifiedLi">
                    <img :src="require('../../assets/home/exhibition-sms/'+ item.img +'.png')" alt="">
                    <p class="subTitle">{{ item.subTitle }}</p>
                </li>
            </ul>
        </div>
        <div class="smsApplication">
            <div class="unifiedCloudLine">
                <div class="midShadow left">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="midText">应用场景</div>
                <div class="midShadow right">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <ul class="applicationList">
                <li v-for="item in applicationList" class="appItem">
                    <p class="appItemTitle">{{ item.title }}</p>
                    <div class="appItemContent">
                        <div class="itemImgCont">
                            <img :src="require('../../assets/home/exhibition-sms/' + item.img + '.png')" alt="">
                        </div>
                        <ul class="purposeList">
                            <li v-for="child in item.list">
                                <span></span>
                                <span>{{ child }}</span>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'SmsCloud',
    data() {
      return {
        superiorityList: [
          {
            img: 'superiorityListOne',
            mainTitle: '强大的高并发处理',
            subTitle: '设置多个发送队列，可确保短信发送请求被实时接收及处理。',
          },
          {
            img: 'superiorityListTwo',
            mainTitle: 'API高安全调用',
            subTitle: '基于伯俊账户及RAM授权体系，对发送API进行鉴权，确保只有您，才可调用自己的API。',
          },
          {
            img: 'superiorityListThree',
            mainTitle: '免运维简单集成',
            subTitle: '无需自行搭建消息服务，免运维。支持API对接，5种编程语言，如Java、PHP、Python、Node.js及C#。',
          },
          {
            img: 'superiorityListFour',
            mainTitle: '设阈值可控发送量',
            subTitle: '可设置每日发送量阈值，防止意外损耗大量资源。又可设置单客户每日发送量阈值，少打扰。',
          },

        ],
        smsInfoList: [
          {
            img: 'smsInfoOne',
            subTitle: '支持发送验证码通知类短信和营销短信',
          },
          {
            img: 'smsInfoTwo',
            subTitle: '支持通过 SDK/API和控制台群发短信',
          },
          {
            img: 'smsInfoThree',
            subTitle: '支持查看多维度短信发送详情和可视化数据分析',
          },
        ],
        applicationList: [
          {
            title: '验证码',
            img: 'applicationOne',
            list: ['用户注册', '安全登录', '找回密码', '手机绑定', '身份验证', '支付认证']
          },
          {
            title: '短信通知',
            img: 'applicationTwo',
            list: ['系统升级', '服务开通', '账户登录', '价格调整', '物流动态', '支付通知']
          },
          {
            title: '推广短信',
            img: 'applicationThree',
            list: ['生日祝福', '节日问候', '活动邀请', '新品上线', '折扣信息', '返利等']
          },
        ]
      };
    },
    components: {},
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {},
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    /*@import "../../../static/theme/theme";*/
    #smsCloud {
        .smsCloudBanner {
            width: 100%;
            height: 450px;
            background: #fff;
            .introduce {
                max-width: 1100px;
                height: 100%;
                margin: auto;
                background: url("../../assets/home/exhibition-sms/banner1.png") center top no-repeat;
                background-size: cover;
                display: flex;
                align-items: center;
                >.info-title {
                    text-align: left;
                    .sub-title {
                        max-width: 430px;
                    }
                }
            }
        }
        .smsSuperiorityContent {
            padding: 80px 50px;
            background: #F0F2F5;
            .smsSuperiorityList {
                width: 860px;
                margin: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                background: url("../../assets/home/exhibition-sms/smsInfoListBg.png");
                >li {
                    width: 430px;
                    display: flex;
                    align-items: center;
                    padding: 35px 40px;
                    border: 1px solid transparent;
                    .imgCont {
                        margin-right: 15px;
                        >img {
                            width: 80px;
                        }
                    }
                    .textContent {
                        max-width: 250px;
                        .main-title {
                            font-size: 18px;
                            color: #1D1D1E;
                        }
                        .sub-title {
                            font-size: 14px;
                            color: #686A6A;
                        }
                    }
                }
                >li:hover {
                    transform: scale(1.1);
                    transition: transform 0.3s;
                    background: #FFFFFF;
                    box-shadow: 7px 3px 7px 0 rgba(150, 195, 198, 0.15);
                    border-image: linear-gradient(180deg, rgba(206, 217, 255, 1), rgba(159, 178, 255, 1)) 2 2;
                }
            }
        }
        .smsInfo {
            padding: 80px 0;
            background: #F6F8FB;
            .unifiedList {
                >li.unifiedLi {
                    padding: 0 80px;
                    >img {
                        width: 140px;
                        /*height: 155px;*/
                    }
                    >.subTitle {
                        color: #313B6B;
                        max-width: 160px;
                    }
                }
            }
        }
        .smsApplication {
            padding: 80px 0;
            box-shadow: 3px 4px 7px 12px rgba(150, 195, 198, 0.06);
            position: relative;
            background: #FFF;
            .applicationList {
                display: flex;
                justify-content: center;
                .appItem {
                    padding: 0 25px;
                    .appItemTitle {
                        padding: 0 20px;
                        font-size: 16px;
                        color: #1D1D1E;
                    }
                    .appItemContent {
                        display: flex;
                        .itemImgCont {
                            >img {
                                width: 160px;
                            }
                        }
                        .purposeList {
                            font-size: 14px;
                            color: #888888;
                            >li {
                                margin-bottom: 5px;
                                >span {
                                    vertical-align: middle;
                                }
                                >span:first-child {
                                    display: inline-block;
                                    margin: 0 8px;
                                    width: 8px;
                                    height: 2px;
                                    background: #FDCABB;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
