<template>
  <div class="loginContent">
    <div class="titleContent">
      <headerComponent></headerComponent>
    </div>
    <div class="loginBG">
      <div class="loginMiddle">
        <div class="imgCont">
          <img src="http://ark-static.oss-cn-hangzhou.aliyuncs.com/pay-platform/image/authenticationCenter/loginBanner.png">
        </div>
        <div class="containerNew">
          <div class="imgTitle">
            <img src="../assets/image/logo.png" alt="logo" class="logo">
          </div>
          <p class="titleTOP">登录</p>
          <div class="inputInfo">
            <img src="../assets/image/account.png" class="icon">
            <input v-model="uname" ref="username" type="text" value="" class="username" placeholder="请输入用户名" autofocus>
          </div>
          <div class="inputInfo">
            <img src="../assets/image/password.png" class="icon">
            <input v-model="pwd" ref="password" type="password" value="" class="pwd" placeholder="请输入密码" @keydown="passwordKeydown">
          </div>
          <div class="btnNew" @click="login()">
            登录
          </div>
          <div class="forgetPasswordNew">
            <span @click="forgetPassword(1)">忘记密码?</span>
            <span @click="forgetPassword(3)">忘记用户名?</span>
            <span @click="register">新公司注册</span>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import headerComponent from './home/header';
  // 登录
  import R3 from '@syman/burgeon-r3';
  import axios from 'axios';

  const enableGateWay = true;
  const { network, urlSearchParams } = R3;

  export default {
    data() {
      return {
        tipsDialog: {
          title: '错误提示',
          action: '',
          show: false,
          errorList: [],
          type: '',
          name: '',
          hiddenButton: false,
          backBtn: false, // 是否有返回按钮
        }, // 报错参数
        uname: '', // 账户名
        pwd: '', // 密码
        rememberMe: false, // 记住密码则为true
        // arrow: false,
        captcha: '', // 校验码
        x: 0,
        y: 0,
        z: 0,
        cancelMove: false, // 满足条件时取消这个move事件
      };
    },
    mounted() {
    },
    components: {
      headerComponent
    },
    methods: {
      logoClick() {
        this.$router.push({ path: '/HomeContent' });
      }, // logo点击触发
      passwordKeydown(e) {
        if (e.keyCode === 13) {
          this.login();
        }
      },
      // 登录
      login() {
        let message = {};
        if (this.$refs.username.value === '') {
          message = {
            title: '错误',
            content: '请输入用户名'
          };
          this.$Modal.fcError(message);
        } else if (this.$refs.password.value === '') {
          message = {
            title: '错误',
            content: '请输入密码'
          };
          this.$Modal.fcError(message);
        } else if (this.$refs.username.value !== '' && this.$refs.password.value !== '') {
          console.log(this.uname);
          const globalServiceId = window.sessionStorage.getItem('serviceId');
          axios.post(globalServiceId + '/p/c/getCaptcha').then((res) => {
            axios.post(globalServiceId + '/p/c/login', urlSearchParams({
              username: this.uname,
              password: this.pwd,
              captcha: res.data.captcha,
            })).then((r) => {
              if (r.status === 200 && r.data.code === 0) {
                window.sessionStorage.setItem('loginTime', `${Date.now()}`);
                window.location.href = window.location.origin;
              }
            });
          });
        }
      },
      forgetPassword(type) {
        if (type == 1) {
          this.$router.push({ path: '/ForgetPassword', query: { type: 1 } });
        } else if (type == 3) {
          this.$router.push({ path: '/ForgetPassword', query: { type: 3 } });
        }
      },
      // 注册
      register() {
        this.$router.push({
          path: '/Register'
        });
      },
    },
    directives: {},
  };
</script>

<style lang="less" scoped>
  .loginContent {
    width: 100%;
    height: 100%;
    min-width: 1211px;
    /*.titleContent {*/
    /*  height: 60px;*/
    /*  background-color: #fff;*/
    /*  display: flex;*/
    /*  align-items: center;*/
    /*  justify-content: center;*/
    /*  .title {*/
    /*    width: 67%;*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    justify-content: flex-start;*/
    /*    .logo {*/
    /*      height: 38px;*/
    /*      cursor: pointer;*/
    /*      img {*/
    /*        width: 242px;*/
    /*        height: 38px;*/
    /*      }*/
    /*    }*/
    /*  }*/
    /*}*/
    .loginBG {
      background:#fff url(http://ark-static.oss-cn-hangzhou.aliyuncs.com/pay-platform/image/authenticationCenter/loginBg.jpg) no-repeat;
      background-size: cover;
      width: 100%;
      height: calc(100% - 60px);
      position: relative;
      min-width: 660px;
      display: flex;
      justify-content: center;

      /*头部区域*/
      .titleTOP {
        margin: 40px 0;
        font-size: 20px;
        font-weight: bold;
        color: #111419;
        letter-spacing: 2px;
        text-align: center;
      }

      /*帐号和密码*/
      .username, .pwd {
        width: 340px;
        height: 38px;
        border-radius: 4px;
        margin-bottom: 18px;
        box-sizing: border-box;
        padding: 0 20px 0 40px;
        border: 1px solid #dadada;
        background: #f9f9f9;
        color: #575757;
        font-size: 14px;
      }
      .icon {
        position: absolute;
        width: 18px;
        height: 18px;
        background-size: cover;
        left: 13px;
        top: 10px;
        color: #575757;
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color: #dadada;
        font-size: 14px;

      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        font-size: 14px;
        color: #dadada;
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        font-size: 14px;
        color: #dadada;
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        font-size: 14px;
        color: #dadada;
      }
    }

    .loginMiddle {
      width: 1100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -5% auto 0;
      .imgCont {
        img {
          width: 450px;
          border-radius: 10px;
        }
      }
      .containerNew {
        width: 460px;
        /*height: 289px;*/
        padding-bottom: 25px;
        background-color: #fafafa;
        box-shadow:0 0 10px 0 rgba(172,172,172,0.3);
        >div.imgTitle {
          margin-top: -50px;
          text-align: center;
          img {
            width: 40px;
          }
        }
        .inputInfo {
          position: relative;
          width: 340px;
          margin: auto;
          margin-bottom: 25px;
        }
        .forgetPasswordNew {
          width: 340px;
          text-align: right;
          margin: 15px auto 10px;
          color: #9b9ea0;
          span {
            margin-right: 8px;
            cursor: pointer;
          }
        }
        .btnNew {
          cursor: pointer;
          width: 340px;
          height: 38px;
          line-height: 38px;
          margin: 0 auto;
          color: #fff;
          font-size: 14px;
          text-align: center;
          margin-bottom: 10px;
          border-radius: 4px;
          background: #ff6a00;
        }
      }
    }
  }
</style>
