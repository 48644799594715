import HelloWorld from '../component/HelloWorld.vue';
import ForgetPassword from '../component/ForgetPassword.vue'; // 忘记密码
import Register from '../component/Register.vue'; // 注册界面
import SeeBoard from '../component/customizedPage/seeBoard.vue';
import TextMsg from '../component/customizedPage/textMsg.vue';
import cloudContent from '../component/home/cloudContent.vue';
import burgeonCloud from '../component/home/burgeonCloud.vue';
import payCloud from '../component/home/payCloud.vue';
// import payCloudInfo from '../component/home/payCloudInfo.vue';
import smsCloud from '../component/home/smsCloud.vue';
import invCloud from '../component/home/invCloud.vue';
// import smsCloudInfo from '../component/home/smsCloudInfo.vue';
import allowAgreement from '../component/customizedPage/allowAgreement.vue';
import apiDocHome from '../component/developDoc/apiDoc/ApiDocHome.vue'; // API 文档首页
import smsApiDocHome from '../component/developDoc/apiDoc/SmsApiDocHome.vue'; // 短信api文档
import invApiDocHome from '../component/developDoc/apiDoc/InvApiDocHome.vue'; // 发票api文档
import sdkDownload from '../component/developDoc/SdkDownload.vue'; // API 文档首页
import interfaceDoc from '../component/home/interfaceDoc.vue'; 
import paymentAccess from '../component/home/paymentAccess.vue';
import smsPrice from '../component/home/smsPrice.vue';
import headBar from '../component/contentFlie/HeadBar.vue';
import pictureVerCode from '../component/customPlug/pictureVerCode.vue';

export default [
  {
    path: '/HelloWorld',
    component: HelloWorld
  },
  {
    path: '/Register',
    component: Register
  },
  {
    path: '/SeeBoard',
    component: SeeBoard
  },
  {
    path: '/TextMsg',
    component: TextMsg
  },
  {
    path: '/cloudContent',
    component: cloudContent,
    redirect: '/cloudContent/burgeonCloud',
    children: [
      {
        path: '/cloudContent/burgeonCloud',
        component: burgeonCloud
      },
      {
        path: '/cloudContent/payCloud',
        component: payCloud
      },
      // {
      //   path: '/cloudContent/payCloudInfo',
      //   component: payCloudInfo
      // },
      {
        path: '/cloudContent/smsCloud',
        component: smsCloud
      },
      // {
      //   path: '/cloudContent/smsCloudInfo',
      //   component: smsCloudInfo
      // },
      {
        path: '/cloudContent/invCloud',
        component: invCloud
      },
      // {
      //   path: '/cloudContent/apiDocHome',
      //   component: apiDocHome
      // },
      // {
      //   path: '/cloudContent/smsApiDocHome',
      //   component: smsApiDocHome
      // },
      // {
      //   path: '/cloudContent/invApiDocHome',
      //   component: invApiDocHome
      // },
      // {
      //   path: '/cloudContent/sdkDownload',
      //   component: sdkDownload
      // },
      {
        path: '/cloudContent/interfaceDoc',
        component: interfaceDoc
      },
      {
        path: '/cloudContent/paymentAccess',
        component: paymentAccess
      },
      {
        path: '/cloudContent/smsPrice',
        component: smsPrice
      },
    ]
  },
  {
    path: '/ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/allowAgreement',
    component: allowAgreement
  },
  {
    path: '/headBar',
    component: headBar
  },
  {
    path: '/pictureVerCode',
    component: pictureVerCode
  },
];
