var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "cloudContent" } },
    [
      _c("headerComponent", { attrs: { isShowDoc: _vm.isShowDoc } }),
      _vm._v(" "),
      _c("router-view", { staticClass: "routeViewClass" }),
      _vm._v(" "),
      _c(
        "footerComponent",
        { attrs: { isShowDoc: _vm.isShowDoc } },
        [
          _vm.$route.path === "/cloudContent/invCloud"
            ? _c("template", { slot: "imgSlot" }, [
                _c("div", { staticClass: "noFooter" }),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }