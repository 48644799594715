var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "AnchorLink",
        { key: index, attrs: { href: item.href, title: item.title } },
        [
          item.children && item.children.length > 0
            ? _c("LoopAnchorlink", { attrs: { list: item.children } })
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }