var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "pictureVerCode" } }, [
    _c("div", { staticClass: "headTop" }, [
      _c("img", {
        staticClass: "logoYq",
        attrs: {
          src: "https://img02.mockplus.cn/idoc/sketch/2020-12-22/9d07ccca-de6b-4a14-a7e0-487e3e6028c7.png",
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "languageTools" }, [
        _c(
          "div",
          {
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                _vm.showChangeLang = true
              },
            },
          },
          [_vm._v(_vm._s(_vm.langList[_vm.langIndex]))]
        ),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "langList",
            class: _vm.showChangeLang ? "" : "noneLangList",
          },
          _vm._l(_vm.langList, function (item, index) {
            return _c(
              "li",
              {
                class: _vm.langIndex === index ? "act" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeLang(index)
                  },
                },
              },
              [_vm._v(_vm._s(item))]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "contentBgBox" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "loginTo" }, [
        _c("img", {
          staticClass: "loginToLogo",
          attrs: {
            src: "https://img02.mockplus.cn/idoc/sketch/2020-12-22/8bd9e7f8-f090-463f-a7fa-eaf98285ca01.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "loginAccount" }, [
          _c("div", { staticClass: "userName" }, [
            _c("img", {
              attrs: {
                src: "https://img02.mockplus.cn/idoc/sketch/2020-12-22/f4bdb165-6e0d-4a53-baea-3d9f41f3ba7c.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "text", placeholder: _vm.langObj.username },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "password" }, [
            _c("img", {
              attrs: {
                src: "https://img02.mockplus.cn/idoc/sketch/2020-12-22/f1a0b034-8087-44c4-a761-d6a313e91b43.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "password", placeholder: _vm.langObj.password },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "loginBtn" }, [
            _vm._v(_vm._s(_vm.langObj.login)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgContent" }, [
      _c("img", {
        staticClass: "imgYq",
        attrs: {
          src: "https://img02.mockplus.cn/idoc/sketch/2020-12-22/c81be4ff-fe34-40db-85df-a2093c7b857c.png!thumb-p-25",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }