var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "seeBoard" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "tableContent" }, [
      _c("div", { staticClass: "leftData colMain" }, [
        _c("div", { staticClass: "sendingData" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "colContent infoContent" }, [
            _c("div", { staticClass: "toolbox" }, [
              _c(
                "label",
                [
                  _c(
                    "Select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { size: "large" },
                      model: {
                        value: _vm.whichMsg,
                        callback: function ($$v) {
                          _vm.whichMsg = $$v
                        },
                        expression: "whichMsg",
                      },
                    },
                    [
                      _c("Option", { attrs: { value: "0" } }, [
                        _vm._v("国内消息"),
                      ]),
                      _vm._v(" "),
                      _c("Option", { attrs: { value: "1" } }, [
                        _vm._v("国外/港澳台短信"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "changeParam" },
                [
                  _vm._l(_vm.changeParamList, function (item, index) {
                    return _c(
                      "div",
                      {
                        class: _vm.custom === index ? "act" : "",
                        on: {
                          click: function ($event) {
                            _vm.custom = index
                          },
                        },
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  _vm._v(" "),
                  _vm.custom === 2
                    ? _c(
                        "div",
                        [
                          _c("DatePicker", {
                            attrs: {
                              confirm: "",
                              type: "daterange",
                              "start-date": new Date(),
                              options: _vm.dateLimit,
                              placement: "bottom-end",
                              placeholder: "起始时间",
                            },
                            on: { "on-ok": _vm.confirmCustomDate },
                            model: {
                              value: _vm.customDate,
                              callback: function ($$v) {
                                _vm.customDate = $$v
                              },
                              expression: "customDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", {
              ref: "chart",
              staticStyle: { width: "100%", height: "250px" },
            }),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "rightInfo colMain" }, [
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "quickOperation" }, [
          _vm._m(5),
          _vm._v(" "),
          _c("div", { staticClass: "colContent infoContent" }, [
            _c("div", { staticClass: "topDomestic" }, [
              _c("p", [_vm._v("国内消息")]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list" },
                _vm._l(_vm.computedMsgList(1), function (item, index) {
                  return _c("li", [
                    _c("span", [
                      _vm._v(_vm._s(item.text)),
                      _c("span", { staticClass: "jump-span" }, [
                        _vm._v(_vm._s(item.num)),
                      ]),
                      _vm._v("个"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "jump-span",
                        on: {
                          click: function ($event) {
                            return _vm.quickJump(1, index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.urlName))]
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottomInternational" }, [
              _c("p", [_vm._v("国际/港澳台消息")]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "list" },
                _vm._l(_vm.computedMsgList(2), function (item, index) {
                  return _c("li", [
                    _c("span", [
                      _vm._v(_vm._s(item.text)),
                      _c("span", { staticClass: "jump-span" }, [
                        _vm._v(_vm._s(item.num)),
                      ]),
                      _vm._v("个"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "jump-span",
                        on: {
                          click: function ($event) {
                            return _vm.quickJump(2, index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.urlName))]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "boardTipTitle" }, [
      _c("p", [_vm._v("标题一行 未知数")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "colTitle" }, [
      _c("span", [_vm._v("发送数据量")]),
      _vm._v(" "),
      _c("span", [_vm._v("获取数据时间: 11:29:03")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "domesticData" }, [
      _c("p", { staticClass: "colTitle" }, [
        _c("span", [_vm._v("国内套餐数据")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "colContent infoContent" }, [
        _c("img", {
          attrs: { src: require("../../assets/image/gift.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "packNotify" }, [
          _c("p", [_vm._v("国内通用短信套餐包上线啦！")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("第一条提示是吃葡萄不吐葡萄皮")]),
            _vm._v(" "),
            _c("li", [_vm._v("第一条提示是是吐皮")]),
          ]),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "button-buy" }, [_vm._v("立即购买")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "internationalHMT" }, [
      _c("p", { staticClass: "colTitle" }, [
        _c("span", [_vm._v("国际/港澳台服务消息")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "colContent infoContent" }, [
        _c("div", { staticClass: "leftHMT" }, [
          _c("img", {
            attrs: { src: require("../../assets/image/gift.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex-buy" }, [
            _c("p", [_vm._v("国际/港澳台套餐包")]),
            _vm._v(" "),
            _c("button", { staticClass: "button-buy" }, [_vm._v("立即购买")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "rightGlobal" }, [
          _c("img", {
            attrs: { src: require("../../assets/image/gift.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex-buy" }, [
            _c("p", [_vm._v("全球套餐包，全球通用")]),
            _vm._v(" "),
            _c("button", { staticClass: "button-buy" }, [_vm._v("立即购买")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "userMonitor" }, [
      _c("p", { staticClass: "colTitle" }, [
        _c("span", [_vm._v("用户监控信息")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "colContent infoContent" }, [
        _c("p", [
          _vm._v(
            "\n                        暂无预警信息，\n                        "
          ),
          _c("span", { staticClass: "jump-span" }, [_vm._v("前往设置")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "colTitle" }, [
      _c("span", [_vm._v("快捷操作入口")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }