<template>
  <div id="header">
    <div class="website-title">
      <router-link class="logo" to="/cloudContent" tag="div">
        <img src="../../assets/home/exhibition/logo.png" alt="" />
      </router-link>
      <!--<div class="logo">-->
      <!--    <img src="../../assets/home/exhibition/logo.png" alt="">-->
      <!--</div>-->
      <div v-if="$route.path != '/login'" class="menu-login">
        <div class="headMenu">
          <Dropdown
            v-for="(item, index) in headDropList"
            :key="index"
            class="dropDownList"
            @on-click="
              (nameValue) => {
                dropItemClick(item, nameValue);
              }
            "
          >
            <span class="dropName">
              {{ item.title }}
              <Icon type="ios-arrow-down"></Icon>
            </span>
            <DropdownMenu slot="list">
              <DropdownItem
                v-for="(child, childIndex) in item.list"
                :key="childIndex"
                :name="childIndex"
                >{{ child.name }}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div v-if="!loginInThis" class="operations">
          <!--<span class="loginBtn theme-sub-btn">登录</span>-->
          <router-link class="loginBtn theme-sub-btn" to="/login" tag="span">登录</router-link>
          <!--<span class="theme-main-btn">新公司注册</span>-->
          <router-link class="theme-main-btn" to="/Register" tag="span">新公司注册</router-link>
        </div>
        <span
          v-if="loginInThis"
          class="ename"
          style="margin-right: 20px"
          @click="jumpToMain"
          >控制台</span>
        <Dropdown v-if="loginInThis" class="userDrop" @on-click="uesrDrop">
          <span class="ename" @click="jumpToMain">{{ ename }}</span>
          <DropdownMenu slot="list">
            <DropdownItem name="changePass">修改密码</DropdownItem>
            <DropdownItem name="changePhone">修改手机号</DropdownItem>
            <DropdownItem name="out">退出登录</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import R3 from '@syman/burgeon-r3';

  const { network } = R3;

  export default {
    name: 'Header',
    data() {
      return {
        SELF_ENV: process.env.SELF_ENV,
        ename: '', // 登录时用户名
        loginInThis: false, // 当前已有登录信息
        headDropList: [{
          title: '产品',
          list: [
            {
              name: '支付服务',
              router: '/cloudContent/payCloud',
            },
            {
              name: '短信服务',
              router: '/cloudContent/smsCloud',
            },
            {
              name: '发票服务',
              router: '/cloudContent/invCloud',
            },
          ],
        }]
      };
    },
    props: {
      isShowDoc: {
        type: Boolean,
        default: false
      }
    },
    components: {},
    beforeCreate() {},
    created() {
      let loginStatus = sessionStorage.getItem('loginStatus') === 'true' || localStorage.getItem('loginStatus') === 'true';
      let userInfo = localStorage.getItem('userInfo')
        && JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo) {
        this.ename = userInfo.ename ? userInfo.ename : '';
        if (userInfo && Object.keys(userInfo).length > 0 && loginStatus) {
          this.loginInThis = true;
        }
      }
    },
    beforeMount() {},
    mounted() {},
    methods: {
      dropItemClick(item, nameValue) {
        let clickChild = item.list[nameValue];
        if (clickChild.clickMethod) {
          clickChild.clickMethod();
        } else if (clickChild.router) {
          this.$router.push(clickChild.router);
        }
      },
      uesrDrop(name) {
        if (name === 'changePass' || name === 'changePhone') {
          let userInfo = localStorage.getItem('userInfo');
          let userName = JSON.parse(userInfo).name;
          if (this.SELF_ENV === 'pro') {
            // eslint-disable-next-line no-nested-ternary
            window.open(`https://burgeonyun.com/ForgetPassword?type=${name === 'changePass' ? '1' : name === 'changePhone' ? `2&name=${userName}` : '1'}`);
          } else {
            // eslint-disable-next-line no-nested-ternary
            window.open(`http://console.account.oneretail.cn/ForgetPassword?type=${name === 'changePass' ? '1' : name === 'changePhone' ? `2&name=${userName}` : '1'}`);
          }
        } else if (name === 'out') {
          const globalServiceId = window.sessionStorage.getItem('serviceId');
          let baseUrl = window.ProjectConfig.enableGateWay ? `/${globalServiceId}` : '';
          network.post(`${baseUrl}/p/cs/logout`).then(() => {
            sessionStorage.setItem('loginStatus', 'false');
            localStorage.setItem('loginStatus', 'false');
            localStorage.setItem('userInfo', '');
            this.loginInThis = false;
            // window.location.href = window.location.origin;
          }).catch(() => {
            // window.location.href = window.location.origin;
          });
        }
      },
      jumpToMain() {
        // this.$router.push('/');
        window.location.href = window.location.origin;
      },
    },
    watch: {
      isShowDoc: function(val) {
        if (val) {
          this.headDropList = [{
            title: '产品',
            list: [
              {
                name: '支付服务',
                router: '/cloudContent/payCloud',
              },
              {
                name: '短信服务',
                router: '/cloudContent/smsCloud',
              },
              {
                name: '发票服务',
                router: '/cloudContent/invCloud',
              },
            ],
          },{
            title: '开发文档',
            list: [
              {
                name: '开发文档',
                router: '/cloudContent/interfaceDoc',
              }
            ]
          }]
        } else {
          this.headDropList = [{
            title: '产品',
            list: [
              {
                name: '支付服务',
                router: '/cloudContent/payCloud',
              },
              {
                name: '短信服务',
                router: '/cloudContent/smsCloud',
              },
              {
                name: '发票服务',
                router: '/cloudContent/invCloud',
              },
            ],
          }]
        }
      }
    },
    computed: {},
    beforeDestroy() {},
    destroyed() {},
  };
</script>

<style lang="less" type="text/less">
@import "../../../static/theme/theme";
#header {
  /*position: relative;*/
  z-index: 10;
  position: sticky;
  top: 0;
  box-shadow: 3px 6px 14px 2px rgba(119, 179, 183, 0.16);
  background: #fff;
}
@media screen and (min-width: 768px) {
  .website-title {
    width: 70%;
    min-width: 1100px;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: @theme-word-main-color;
    background: #fff;
    .logo {
      cursor: pointer;
      > img {
        height: 32px;
        vertical-align: middle;
      }
    }
    .menu-login {
      display: flex;
      align-items: center;
      .headMenu {
        .dropDownList {
          margin-right: 45px;
          .ark-select-dropdown {
            width: 120px;
          }
          .dropName:hover {
            color: @theme-color;
            cursor: pointer;
          }
          .ark-dropdown-item:hover {
            color: @theme-color;
            text-align: center;
          }
        }
      }
      .operations {
        .loginBtn {
          margin: 0 40px 0 0;
        }
      }
      .userDrop {
        .ark-dropdown-item:hover {
          color: @theme-color;
          text-align: center;
        }
      }
      .ename {
        cursor: pointer;
        color: #fd6442;
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .website-title {
    width: auto;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: @theme-word-main-color;
    background: #fff;
    .logo {
      > img {
        height: 32px;
        vertical-align: middle;
      }
    }
    .menu-login {
      display: none;
      .headMenu {
        .dropDownList {
          margin-right: 45px;
          .ark-select-dropdown {
            width: 120px;
          }
          .dropName:hover {
            color: @theme-color;
            cursor: pointer;
          }
          .ark-dropdown-item:hover {
            color: @theme-color;
            text-align: center;
          }
        }
      }
      .operations {
        .loginBtn {
          margin: 0 40px 0 0;
        }
      }
    }
  }
}
</style>
