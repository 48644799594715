<template>
    <div id="cloudContent">
        <headerComponent :isShowDoc="isShowDoc"></headerComponent>
        <router-view class="routeViewClass"></router-view>
        <footerComponent :isShowDoc="isShowDoc">
            <template v-if="$route.path === '/cloudContent/invCloud'" slot="imgSlot">
                <div class="noFooter"></div>
            </template>
        </footerComponent>
    </div>
</template>

<script>
  import R3 from '@syman/burgeon-r3';
  const { network } = R3;

  import headerComponent from './header.vue';
  import footerComponent from './footer.vue';

  export default {
    name: 'CloudContent',
    data() {
      return {
        isShowDoc: false
      };
    },
    components: {
      headerComponent, footerComponent
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
      network.post('/auth-app-admin/p/c/homepage/isShow').then(res => {
        if (res.data.code === 0 && res.data.data) {
          this.isShowDoc = res.data.data === 'Y' ? true : false
        }
      })
    },
    methods: {},
    watch: {
      $route() {
        document.getElementById('cloudContent').scrollTop = 0;
      }
    },
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    #cloudContent {
        width: 100%;
        height: 100%;
        overflow: auto;

    }
    @media screen and (min-width: 768px) {
        .routeViewClass {
            min-width: 1100px;
        }
    }
    @media screen and (max-width: 767px) {
        .routeViewClass {
            min-width: auto;
        }
    }
</style>
