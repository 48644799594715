var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-color", attrs: { id: "burgeonCloud" } },
    [
      _c(
        "div",
        {
          staticClass: "website-banner",
          on: {
            click: function ($event) {
              return _vm.getRoute($event)
            },
            mouseenter: _vm.bannerMouseenter,
            mouseleave: _vm.bannerMouseleave,
          },
        },
        [
          _c(
            "Carousel",
            {
              staticClass: "auto-adv",
              attrs: {
                autoplay: _vm.autoplay,
                "autoplay-speed": 4000,
                loop: "",
                arrow: "never",
              },
              model: {
                value: _vm.carIndex,
                callback: function ($$v) {
                  _vm.carIndex = $$v
                },
                expression: "carIndex",
              },
            },
            [
              _c("CarouselItem", [
                _c("div", { staticClass: "demo-carousel" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/home/exhibition/payBanner.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "proIntroduce" }, [
                    _c("div", { staticClass: "titleAdv" }, [
                      _vm._v("让支付变得更简单"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "infoAdv" }, [
                      _c("p", [_vm._v("支付是每一家企业内部的虚拟支付团队")]),
                      _vm._v(" "),
                      _c("p", [_vm._v("帮助企业快速高效的搭建自己的支付系统")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "freeUse" }, [
                      _c("span", { staticClass: "theme-main-btn-big" }, [
                        _vm._v("了解详情"),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("CarouselItem", [
                _c("div", { staticClass: "demo-carousel" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/home/exhibition/smsBanner.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "proIntroduce" }, [
                    _c("div", { staticClass: "titleAdv" }, [
                      _vm._v("安全调用  秒级触达"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "infoAdv" }, [
                      _c("p", [
                        _vm._v(
                          "调用API或用群发助手，即可发送验证码、通知类和营销短信"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "国际/港澳台短信覆盖200多个国家和地区，安全稳定，广受出海企业选用。"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "freeUse" }, [
                      _c("span", { staticClass: "theme-main-btn-big" }, [
                        _vm._v("了解详情"),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("CarouselItem", [
                _c("div", { staticClass: "demo-carousel" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/home/exhibition/invBanner.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "proIntroduce" }, [
                    _c("div", { staticClass: "titleAdv" }, [
                      _vm._v("领先的多场景发票服务"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "infoAdv" }, [
                      _c("p", [
                        _vm._v(
                          "多场景发票服务系统，让销方企业开票更便捷，让购方企业管理发票更高效"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("支持企业打通自有业务系统进行开票及发票管理"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "freeUse" }, [
                      _c("span", { staticClass: "theme-main-btn-big" }, [
                        _vm._v("了解详情"),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "produce-adv" },
        _vm._l(_vm.advList, function (item, index) {
          return _c("div", { key: index, staticClass: "adv-content pay-adv" }, [
            _c("div", { staticClass: "adv-title" }, [
              _c("div", { staticClass: "nominate" }, [
                _c("p", { staticClass: "main" }, [_vm._v(_vm._s(item.title))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "line" }),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "adv-list" },
              _vm._l(item.product, function (child, childIndex) {
                return _c(
                  "li",
                  {
                    key: childIndex,
                    staticClass: "item",
                    class: "list" + item.product.length,
                    on: {
                      mouseover: function ($event) {
                        return _vm.bgImgAct(child)
                      },
                      mouseout: function ($event) {
                        return _vm.bgImgRemove(child)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "item-img",
                      attrs: {
                        src: require("../../assets/home/exhibition/" +
                          child.img +
                          ".png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-introduce" }, [
                      _c("p", { staticClass: "main-introduce" }, [
                        _vm._v(_vm._s(child.mainInfo)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "sub-introduce" }, [
                        _vm._v(_vm._s(child.subInfo)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }