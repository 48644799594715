<template>
    <table id="customTable">
        <thead>
            <tr>
                <td v-for="item in tabHead">
                    {{ item.title }}
                </td>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in tabBody">
                <td v-for="cur in tabHead">
                    <Cell v-if="cur.render"
                          :column="cur"
                          :row="item"
                          :index="index"
                          :render="cur.render"></Cell>
                    <div v-else>{{ item[cur.key] }}</div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
  import Cell from './cell';

  export default {
    name: 'CustomTable',
    data() {
      return {};
    },
    props: {
      tabHead: {
        type: Array,
        default() {
          return [];
        }
      },
      tabBody: {
        type: Array,
        default() {
          return [];
        }
      },
    },
    components: {
      Cell
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {},
    watch: {},
    computed: {},
    beforeDestroy() {
    },
    destroyed() {
    }
  };
</script>

<style lang="less" type="text/less">
    #customTable {
        width: 100%;
        tr {
            height: 48px;
            line-height: 48px;
            border-bottom: 1px solid #ECEDED;
        }
        td {
            padding: 0 16px;
            input {
                cursor: pointer;
            }
        }

        thead {
            background: #F5F5F6;
        }
        tbody {
            background: #FFFFFF;
            .operation {
                span {
                    margin-right: 8px;
                    cursor: pointer;
                    color: #62C4DE;
                }
            }
        }
    }
</style>
