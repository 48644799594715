var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apiDocContainer" },
    [
      _c("menuCont", {
        attrs: {
          "men-list": _vm.menList,
          "active-name": _vm.activeName,
          "open-names": _vm.openNames,
        },
        on: { sideMenuSelect: _vm.sideMenuSelect },
      }),
      _vm._v(" "),
      _vm.isSDK
        ? [_c("paymentAccess", { attrs: { sdkTitle: _vm.sdkTitle } })]
        : [
            _c(
              "div",
              { staticClass: "markdownContainer", attrs: { id: "markdown" } },
              [
                _vm.markdownShow
                  ? _c("vue-markdown", [_vm._v(_vm._s(_vm.msg))])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "anchorContainer" },
              [
                _vm.anchorShow
                  ? _c(
                      "Anchor",
                      { attrs: { "show-ink": "", container: _vm.scrollCon } },
                      [
                        _c("LoopAnchorlink", {
                          attrs: { list: _vm.anchorLinkList },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }