// eslint-disable-next-line import/prefer-default-export
export function timeToDate(timestamp, type = 's') {
  if (timestamp) {
    let time = new Date(timestamp);
    let y = time.getFullYear(); // getFullYear方法以四位数字返回年份
    let M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
    let d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
    let h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
    let m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
    let s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)
    if (type === 'd') {
      return `${y}-${M >= 10 ? M : `0${M}`}-${d >= 10 ? d : `0${d}`}`;
    }
    if (type === 'm') {
      return `${y}-${M >= 10 ? M : `0${M}`}-${d >= 10 ? d : `0${d}`} ${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`}`;
    }
    if (type === 's') {
      return `${y}-${M >= 10 ? M : `0${M}`}-${d >= 10 ? d : `0${d}`} ${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`}:${s >= 10 ? s : `0${s}`}`;
    }
  }
  return '';
}
