var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "head-bar" }, [
    _c("div", { staticClass: "logo-operation" }, [
      _c(
        "div",
        {
          ref: "titleDrop",
          staticClass: "show-leftMenu",
          on: { mouseenter: _vm.changeLeftMenu },
        },
        [_c("Icon", { attrs: { type: "md-menu" } })],
        1
      ),
      _vm._v(" "),
      _c("img", {
        staticClass: "title-logo",
        attrs: { src: require("../../assets/image/titleLogo.png"), alt: "" },
        on: { click: _vm.jumpToCloud },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "noOpen", attrs: { id: "noOpenAnimation" } }, [
        _vm._v("你还没有开通服呦，赶快去开通吧！"),
      ]),
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "title-rightList" }, [
      _c("li", [
        _vm.SELF_ENV === "pro"
          ? _c(
              "a",
              {
                attrs: {
                  href: "https://burgeonyun.com/cloudContent/burgeonCloud",
                },
              },
              [_vm._v("官网")]
            )
          : _c(
              "a",
              {
                attrs: {
                  href: "http://console.account.oneretail.cn/cloudContent/burgeonCloud",
                },
              },
              [_vm._v("官网")]
            ),
      ]),
      _vm._v(" "),
      _c(
        "li",
        { staticClass: "userContent" },
        [
          _c(
            "Dropdown",
            { on: { "on-click": _vm.uesrDrop } },
            [
              _c("span", { staticClass: "ename" }, [_vm._v(_vm._s(_vm.ename))]),
              _vm._v(" "),
              _c(
                "DropdownMenu",
                { attrs: { slot: "list" }, slot: "list" },
                [
                  _c("DropdownItem", { attrs: { name: "changePass" } }, [
                    _vm._v("修改密码"),
                  ]),
                  _vm._v(" "),
                  _c("DropdownItem", { attrs: { name: "changePhone" } }, [
                    _vm._v("修改手机号"),
                  ]),
                  _vm._v(" "),
                  _c("DropdownItem", { attrs: { name: "out" } }, [
                    _vm._v("退出登录"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.leftMenuShow,
            expression: "leftMenuShow",
          },
        ],
        staticClass: "leftFixedMenu",
        class: _vm.leftMenuShowWidth ? "act" : "",
        on: {
          click: function ($event) {
            ;(_vm.leftMenuShow = false), (_vm.leftMenuShowWidth = false)
          },
        },
      },
      [
        _c(
          "ul",
          {
            staticClass: "menuList",
            class: _vm.leftMenuShowWidth ? "act" : "",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          _vm._l(_vm.burgeonCloudList, function (item) {
            return _c("li", [
              _c(
                "a",
                { attrs: { href: item.url, target: "_blank" } },
                [
                  _c("Icon", { attrs: { type: "ios-apps" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n            " + _vm._s(item.title) + "\n          "
                    ),
                  ]),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }