var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.menList.length > 0
    ? _c(
        "Menu",
        {
          staticStyle: { width: "260px", overflow: "auto" },
          attrs: {
            accordion: "",
            "active-name": _vm.activeName,
            "open-names": _vm.openNames,
          },
          on: { "on-select": _vm.sideMenuSelect },
        },
        [
          _vm._l(_vm.menList, function (item, index) {
            return [
              item.children
                ? _c(
                    "Submenu",
                    { key: index, attrs: { name: item.name } },
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _vm._v(" "),
                      _vm._l(item.children, function (item1, index1) {
                        return [
                          item1.children
                            ? _c(
                                "Submenu",
                                { key: index1, attrs: { name: item1.name } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(_vm._s(item1.title)),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    item1.children,
                                    function (item2, index2) {
                                      return [
                                        item2.children
                                          ? _c(
                                              "Submenu",
                                              {
                                                key: index2,
                                                attrs: { name: item2.name },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [_vm._v(_vm._s(item2.title))]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  item2.children,
                                                  function (item3, index3) {
                                                    return _c(
                                                      "MenuItem",
                                                      {
                                                        key: index3,
                                                        attrs: {
                                                          name: item3.name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item3.title)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _c(
                                              "MenuItem",
                                              {
                                                key: index2,
                                                attrs: { name: item2.name },
                                              },
                                              [_vm._v(_vm._s(item2.title))]
                                            ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _c(
                                "MenuItem",
                                { key: index1, attrs: { name: item1.name } },
                                [_vm._v(_vm._s(item1.title))]
                              ),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("MenuItem", { key: index, attrs: { name: item.name } }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }